import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getStructure } from "../../../../../tools/tpl";
import { unsuidize } from "../../../../../service/suid";

const AddToolDuplicate = ({ id, structure, updateStructure, selectTpl, idObj }) => {




  const selectTool = () => {
    hoverEnd()
    var parts = idObj.split(".");
    var index = parseInt(parts.pop());
    var idParent = parts.join(".");
    var _structure = getStructure(structure, idParent);

    if (!_structure.children) _structure.children = [];
    var newid = idParent + "." + (index + 1);
    _structure.children = [
      ..._structure.children.filter((_, i) => i <= index),
    unsuidize ( getStructure(structure, idObj),{sub:"children"},true),
      ..._structure.children.filter((_, i) => i > index),
    ];
    updateStructure(_structure, idParent,newid);

  };

  const hoverStart = () => {
    hoverEnd(idObj)
    var obj = document.getElementById(idObj);
if(obj){
  var   newdiv = document.createElement("div");
  newdiv.idObj=idObj
    newdiv.id = "newObj-_AddToolCopy";
    newdiv.className= " m-0 p-0 text-white opacity-0 transition-all";
    newdiv.style.fontSize="0px"
    newdiv.innerHTML =obj.innerHTML
   
setTimeout(()=>{
  if(newdiv) {
    newdiv.style.fontSize=obj.style.fontSize
    newdiv.className=obj.className + "  opacity-100 ";}},10)
    obj.parentNode.insertBefore(newdiv,obj.nextSibling)
}
  };

  const hoverEnd = (idObj) => {
    var newdiv = document.getElementById( "newObj-_AddToolCopy");

    if(newdiv &&(!idObj || newdiv.idObj!==idObj)) newdiv.parentNode.removeChild(newdiv)


  };

  if (idObj === "-root") return <></>;

  return (
    <div
      className="appbutton  flex-row  p-2 gap-1 inline-flex"
      onClick={() => selectTool()}
      onMouseOver={() => hoverStart()}
      onMouseOut={() => hoverEnd()}
    >
      <div className=" border-2 border-white bg-white p-2"></div>
      <FontAwesomeIcon
        icon={fa.faPlus}
        className=" bg-white text-black w-3 h-3 p-1 rounded-sm"
      />
    </div>
  );
};
export default AddToolDuplicate;
