import React, { useEffect, useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";
import VarContent from "../../../../../components/VarContent";
import InputColor from "../../../../../components/inputs/InputColor";
import { retrieveProject } from "../../../../../service/mftc-api";
import { getAppColors } from "../../../../../tools/colors";

const ContextToolColor = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app,updateApp,prefixe_filter}) => {
  

const prefixes =  ["bg","text","border"];

const categories=  [{
  name: "Background",
  pre :"bg",
  pref: "bg-",
  classes: []},
  {
    name: "Texte",
    pre:"text",
    pref: "text-",
    classes: []},
    {
      name: "Bordure",
      pre:"border",
      pref: "border-",
      classes: []},

]

const [newColor,setNewColor]=useState()
const [addColor,setAddColor]=useState()
const [appColors,setAppColors]=useState([])


useEffect(()=>{

  setNewColor()

},[addColor])

useEffect(()=>{




  setAppColors(  getAppColors(app)  )

},[app?.profile])
const allColors = useMemo(()=>{

return [...appColors, ...getAppColors(null,true)]

},[appColors])

const addColorToApp=async ()=>{

  setAddColor(false)
  var _app = JSON.parse(JSON.stringify(app))
  if(! _app.profile.appColor) _app.profile.appColor=[]
  _app.profile.appColor.push(newColor   )
  
 await  retrieveProject().api("/set_app_profile", { body: {app_id:_app.app_id,profile:{ appColor: _app.profile.appColor  }} });
 if(updateApp) await updateApp({..._app})
  setAppColors([...appColors,  "app-color-"+(_app.profile.appColor.length+1) ] )



  }
  
const toggleClasse =(pref,color)=>{
  var className=""
  var parts = (structure.className+"").split(" ")


if( parts.includes(pref+"-"+color ) )
{
   parts = parts.filter(c=>c!==  pref+"-"+color  )
}
else
{
parts =   parts.filter(c=>  !allColors.find(_color=> c=== pref+"-"+_color)  )
parts.push( pref+"-"+color)

}
className =parts.join(" ")
updateTemplateStructure( {...structure,className},selected)
}
  /* 
    console.log("ShowTemplateStructure 1 "  )
    const [_structure,setStructure] = useState(getStructure(template.structure,selected))
    
    const update=async (structure)=>{
      setStructure(structure)
       await updateTemplateStructure(structure,selected)
    }
    */


    const [activeCategory, setActiveCategory] = useState(    categories.find(c=>c.pre=== prefixe_filter)?.name ?? categories[0].name);

  if (!selected) return <></>;
  return (
    <div
      id={"ShowTemplateStructure"}
      className="bg-zinc-900 text-white p-2 rounded-xl m-2 flex flex-col   border-white shadow"
    >
               {/* Onglets */}
               <div className="flex gap-2 mb-4">
      {categories.map((category) => (
        <div
          key={category.name}
          onClick={() => setActiveCategory(category.name)}
          className={(category.subname ? 'px-2 ': 'px-4 '     )+ `  cursor-pointer  py-2 rounded ${
            activeCategory === category.name
              ? "bg-green-700 text-white "
              : (category.subname ? "bg-zinc-800 text-gray-300":"bg-zinc-700 text-gray-300" )
          }`}
        >
          {category.subname??  category.name}
        </div>
      ))}
    </div>
 { addColor && 
 <div className="w-full bg-zinc-50 p-2 flex flex-col items-center gap-2"   ><InputColor value={newColor} className={"bg-zinc-50 w-full"}  label={"Nouvelle Couleur"} onChange={setNewColor}  />
 
 <div className="w-full flex justify-end gap-2" >  
  <div   className="bg-red-500 p-2 text-white rounded"  onClick={()=>setAddColor()} >Annuler</div>
  {newColor &&<div   className="bg-green-500 p-2 text-white rounded" onClick={addColorToApp}   >Ajouter</div>}
    </div>
 </div>   }     
{  prefixes.filter(p=>    categories.find(c=>c.name=== activeCategory)?.pre===p).map( pref=><div  key={pref}
className="w-full flex-row flex p-1 border-t border-zinc-700 gap-1 items-center"
>

{allColors.map(color=><div key={color}   onClick={()=>toggleClasse(pref,color)}  className={"rounded-full w-8 h-8 p-1" + (  (structure.className+"").includes(pref+"-"+color )  ?" border border-white":"")} > <div className={ "w-full h-full rounded-full border-gray-500 border" + " bg-"+ color }></div>    </div>)}

{ app?.profile && <div   onClick={()=>setAddColor(true)} className={"rounded-full w-6 h-6 p-1 bg-gray-500 text-black text-xl flex items-center justify-center"} >  + </div>}


</div>)  }
      
<div className="w-full flex flex-wrap p-2 bg-zinc-800 rounded-lg text-xs mb-2">

{categories.flatMap(category => allColors.filter(cls => (structure.className || "").split(" ").includes( category.pref+  cls)).map(cls=>({classe:category.pref+  cls,pref:category.name})) )
  .map(activeClass => (
    <span 
    onClick={()=>toggleClasse(activeClass.pref, activeClass.classe)} 
      key={activeClass.classe}
      className="bg-green-700 text-white rounded-full px-2 py-1 mr-2 mb-1"
    >
      {activeClass.classe}
    </span>
  ))}
</div>
    </div>
  );
};

export default ContextToolColor;
