import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";

const ContextToolAdd = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app}) => {
  
  const [ComponentAddTools, setComponentAddTools] = useState({});


  const loadTools = async () => {
    const addtoolfiles = require.context("../addTools", false, /\.js?$/); // Récupère tous les fichiers .js ou .jsx du répertoire addTools
    const addcomponents = {};

    addtoolfiles.keys().forEach((key) => {
      const componentName = key.replace(/^.+\/([^/]+)\.js?$/, "$1");
      addcomponents[componentName] = addtoolfiles(key).default;
    });

    setComponentAddTools(addcomponents);

  };

  useEffect(() => {
    loadTools();
  }, []);


  if (!selected) return <></>;
  if(!selected ) return <></>  
  return (
    <div id={"ShowComponentAddTools"} className="flex   items-center bg-zinc-900 p-2  m-2 flex-nowrap text-center  border border-white rounded-xl shadow">
{Object.values(ComponentAddTools).map((ComponentAddTool, i) => (
        <ComponentAddTool
          structure={template.structure}
          updateStructure={updateTemplateStructure}
          selectTpl={selectTpl}
          idObj={selected}
          key={i}
        />
      ))}{" "}
    </div>
  );
};

export default ContextToolAdd;
