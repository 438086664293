import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useMemo, useState } from "react";
import { getStructure } from "../../../../../tools/tpl";
import InputData from "../../../../../components/inputs/InputData";
import InputClassName from "../../../../../components/inputs/InputClassName";
import InputSelectPropSchema from "../../../../../components/inputs/InputSelectPropSchema";

const availableClasses = [

];
availableClasses.sort((a, b) => (a < b ? -1 : 1));

const ToolBackgroundImage = ({ updateStructure, structure, idObj, selectTpl,key_schemas,context,globalSchema,schemas}) => {

  const [show, setShow] = useState();



  const backgroundImage = useMemo(() => {
 
    var _structure = getStructure(structure, idObj);

   return  _structure.backgroundImage ?? null

  }, [structure, idObj]);

  const backgroundImageDefault = useMemo(() => {
 
    var _structure = getStructure(structure, idObj);

   return  _structure.backgroundImageDefault ?? null

  }, [structure, idObj]);
  useEffect(()=>{

    if(!show && backgroundImage) setShow(true)
  },[backgroundImage,show])

const setBackgroundImage= (backgroundImage)=>{
  var _structure = getStructure(structure, idObj);
  var {cssAttributes} =  _structure
  if(!cssAttributes)cssAttributes={}
  if(!backgroundImage && !_structure.backgroundImageDefault)
  {
delete cssAttributes.backgroundSize
delete cssAttributes.backgroundPosition
  }else
{  if(!cssAttributes.backgroundSize)  cssAttributes.backgroundSize = "cover";
if(!cssAttributes.backgroundPosition)  cssAttributes.backgroundPosition = "center";
  }
  updateStructure({ backgroundImage,cssAttributes}, idObj);
}
const setBackgroundImageDefault= (backgroundImageDefault)=>{
  var _structure = getStructure(structure, idObj);
  var {cssAttributes} =  _structure
  if(!cssAttributes)cssAttributes={}
  if(!backgroundImageDefault && !_structure.backgroundImage)
  {
delete cssAttributes.backgroundSize
delete cssAttributes.backgroundPosition
  }else
{  if(!cssAttributes.backgroundSize)  cssAttributes.backgroundSize = "cover";
if(!cssAttributes.backgroundPosition)  cssAttributes.backgroundPosition = "center";
  }
    updateStructure({ backgroundImageDefault}, idObj);
}



  return (
    <>
      {show && (
        <div
          className={
            "bg-white w-full h-full justify-between flex flex-col relative  mr-14"
          }
        >
                    <InputSelectPropSchema
                    context={context}
                      label="Propriété Label"
                      className="text-black w-full my-1"
                      value={structure.backgroundImage}
                      onChange={(schema_prop, schema_id,isList) => {
                        setBackgroundImage(schema_prop)
                      }}
                      globalSchema={globalSchema}
                      criteria_schema={(c) =>  c.schema_id === 50
                      }
                      schemas={schemas}
                    
                      placeholder={"Choisir la propriété"}
                      options={[]}
                    />
                    {
    <InputData
                    id={"default"}
                  className={"w-full"}
                  prop={{ name: "background", schema_id:50, define: true,define_open:true  }}
                  schema={{ content_id: 50, content:{code:"image"} }}
                  value={backgroundImageDefault }
                  onChange={setBackgroundImageDefault}
                  context={{key_schemas}}
                />
                    }
        </div>
      )}

      <div
        className={
          "   appbutton border-2  border-white  p-2  m-2 text-xs" +
          (show
            ? " bg-white text-black bg-opacity-80 "
            : " bg-black text-white bg-opacity-30 ")
        }
        onClick={() => setShow(!show)}
      >
       Background Image
      </div>
    </>
  );
};
export default ToolBackgroundImage;
