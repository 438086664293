import React from "react";
import {useParams } from "react-router-dom";


import ComponentLoader from "../../../../components/ComponentLoader";

function BuiltPage({  }) {
  const { app_id, slug,page_id } = useParams();
  const params = useParams();



  return (
    <>
    <ComponentLoader params={{slug,page_id,app_id,params}}      />
    </>
  );
}

export default BuiltPage;
