import React, { useEffect, useMemo, useState, useContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputText from '../../../../components/inputs/InputText';
import InputImage from '../../../../components/inputs/InputImage';
import InputColor from '../../../../components/inputs/InputColor';
import InputSelect from '../../../../components/inputs/InputSelect';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import InputButton from '../../../../components/inputs/InputButton';


import '../../../../App.css';
import { MfctContext } from "../../../../context";
import { data } from '../../../../service/data';
import VarContent from '../../../../components/VarContent';
import InputData from '../../../../components/inputs/InputData';
import LoaderSmall from '../../../../components/LoaderSmall';

function AppActions({ app, getApps }) {


  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  const [mods, setMods] = useState(app);

  const [isInstalled,setIsInstalled]=useState()
  const [urlEnv,setUrlEnv]=useState()
  const [urlBuild,setUrlBuild]=useState()
  const [roles,setRoles]= useState([])


useEffect(()=>{
  const statusApp = async ()=>{

    var result = await project.api("/gen_env_status",{body:{environment:"app_" + app.app_id}})
  
  setUrlEnv(result?.url)
  setUrlBuild(result?.urlBuild)
  setIsInstalled(result?.isInstalled)
  }

  
statusApp()

},[])

  const saveApp = async (e) => {
    e.preventDefault();

    var newapp = await project.api("/set_app", { body: mods });
    toast("Successfully saved '" + app.name);
    console.log(newapp)
    await getApps();
  }



  return (

    <div className='w-full flex flex-col gap-1 py-4 bg-white text-black px-4 rounded-xl' >
 

       <div className='w-full text-black p-2 font-bold border-b border-gray-200 my-4 text-lg'> Actions</div>
        <InputData
                                id={"_event_"}
                  className={"w-full"}
                  prop={{ name: "Event Action", type_nombre: "I", define: true,define_open:true  }}
                  schema={{ content_id: 446 }}
                  value={mods.profile.actions ?? []}
                  onChange={(actions) =>
                    setMods((m) => ({
                      ...m, profile: { ...m.profile, actions }, 
                    }))
                  }
                />


{
        true
        &&
        <div className='flex flex-col w-full p-2'><InputButton label={"Enregistrer"} onClick={saveApp} />
       
        </div>}


      
     
    </div>
  );
}

export default AppActions;
