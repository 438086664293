import axios from "axios";
import { customLongHash } from "../tools/tpl";


var _project;
const cache = {};
export const retrieveProject = () => {
  return _project??    { api:(...props)=>{    console.log("Erreur Retrieving project None ", props);
    return {}
      }};
};

export const setToken = (token, authUser) => {
  if (token) {
    localStorage.setItem("token", token);
    localStorage.setItem("authUser", JSON.stringify(authUser));
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("authUser");
  }
};

export const updateAuthuser = (authUser) => {

var _formerAuthUser = getAuthUser()

  localStorage.setItem("authUser", JSON.stringify(authUser));

  return JSON.stringify(authUser)!== JSON.stringify(_formerAuthUser)
};

export const getAuthUser = () => {
  var result = localStorage.getItem("authUser");
  if (result) {
    try {
      return JSON.parse(result);
    } catch (err) {
      //console.log(err)
    }
  } else return null;
};

export const getApp = () => {
  var parts = window.location.href.split(":30");

  if (parts.length > 1) {
    // Localhost
    var parts1 = window.location.search.split("?app=");
    const app = parts1.length > 1 ? parts1[1] : process.env.REACT_APP_MFTC_APP;
    if (app) sessionStorage.setItem("app", app);
    return sessionStorage.getItem("app") ?? process.env.REACT_APP_MFTC_APP;
  }

  var parthost = window.location.hostname.split(".appisyou.");
  if (parthost.length > 1) {
    if (parthost[0] === "www" || parthost[0] === "pro") return "pro";
    if (parthost[0] === "admin") return "admin";
    if (parthost[0] === "dev") return "dev";
    if (parthost[0] === "designer") return "designer";
  }

  return "client";
};

export const api = async (endpoint, params) => {
  if (!_project) {
    await projectInit(
      process.env.REACT_APP_MFTC_SERVER_URL,
      process.env.REACT_APP_PROJECT_NAME
    );
  }

  return await _project.api(endpoint, params);
};

export const projectInit = async (
  url_server,
  project_name,
  create = false,
  domain = null
) => {
  console.log("URL SERVER  BEFORE :: ", url_server);
  var headers = {};
  var token = localStorage.getItem("token");
  if (token) headers["Authorization"] = "Bearer " + token;
  headers["Mftc-Application"] = getApp();

  if (process.env.REACT_APP_DOMAIN)
    headers["Appisyou-Domain"] = process.env.REACT_APP_DOMAIN;

  var parts = window.location.href.split(":30");
  if (parts.length > 1) {
    if (domain) localStorage.setItem("domain", domain);
    else domain = localStorage.getItem("domain") ?? null;

    var _parts = url_server.split(":30");
    if (_parts.length > 1) {
      _parts[0] = parts[0];
      url_server = _parts.join(":30");
    } else {
      /// Test serveur local 3030 + build local
      url_server =
        window.location.href.split(":3030")[0] + ":3030" + url_server;
    }
  } else {
    domain = null;
  }

  //process.env.REACT_APP_MFTC_SERVER_URL
  console.log("URL SERVER   :: ", url_server);
  try {
    const response = await fetch(
      url_server +
        `${project_name}/init/` +
        (create ? "1" : "0") +
        (domain ? `?domain=${domain}` : ""),
      {
        method: "get",
        headers: { ...headers },
      }
    );
    const project = await response.json();
    if (project.updatedUser) {
      setToken(project.updatedUser.token, project.updatedUser.user);
    }

    if (project) {
      project.isDev = window.location.href.split(":30").length > 1;
      project.api = async (endpoint, params) => {
        if (params?.log) console.log("API ", endpoint, params);

        if (params?.cached) {
          var keycache = customLongHash(JSON.stringify({ params, endpoint }),endpoint);
          console.log(keycache, cache[keycache]);
          if (cache[keycache]) return cache[keycache];
        }
        if (params?.cache) {
          const cache = localStorage.getItem(params.cache);
          if (cache) return JSON.parse(cache);
        }

        if (!project.endpoints[endpoint]) {
          console.log("No endpoint like this', endpoint: " + endpoint);
          return;
        }
        var url =
          (params?.url_server ?? url_server) +
          (params?.project ?? project_name) +
          project.endpoints[endpoint].url +
          "/";
        // console.log("url",url,params.args)
        if (params && params.args) {
          Object.keys(params.args).forEach((key) => {
            url = url.split(":" + key + "/").join(params.args[key] + "/");
          });
        }

        url = url.substring(0, url.length - 1);
        // console.log("url",url,params.args)
        const domain = localStorage.getItem("domain");
        if (domain) url += `?domain=${domain}`;

        var response;
        var headers = {};
        var token = localStorage.getItem("token");
        if (token) headers["Authorization"] = "Bearer " + token;

        if (process.env.REACT_APP_MFTC_APP)
          headers["Mftc-Application"] = getApp();
        if (process.env.REACT_APP_DOMAIN)
          headers["Appisyou-Domain"] = process.env.REACT_APP_DOMAIN;

        switch (project.endpoints[endpoint].method) {
          case "get":
            response = await fetch(url, {
              method: "GET",
              headers,
            });

            break;
          case "post":
            if (params?.log) console.log("POST request");
            response = await fetch(url, {
              method: "POST",
              headers: { ...headers, "Content-Type": "application/json" },
              body: JSON.stringify(params.body),
            });
            if (params?.log) console.log("POST reponse", response);

            //      contentType: 'text/event-stream',

            break;

          case "postform":
            var body = params.body;
            if (!body) {
              const formData = new FormData();
              if (params.form) {
                Object.keys(params.form).forEach((key) => {
                  formData.append(key, params.form[key]);
                });
                body = formData;
              }
            }

            response = await axios.post(url, body, {
              onUploadProgress: params.onUploadProgress,
              headers: {
                ...headers,
                //     "Content-Type": "multipart/form-data"
              },
            });
            break;
          case "postform_old":
            var body = params.body;
            if (!body) {
              const formData = new FormData();
              if (params.form) {
                Object.keys(params.form).forEach((key) => {
                  formData.append(key, params.form[key]);
                });
                body = formData;
              }
            }

            response = await fetch(url, {
              method: "POST",
              body,
              headers: {
                ...headers,
                //     "Content-Type": "multipart/form-data"
              },
            });

            break;
          case "put":
            response = await fetch(url, {
              method: "PUT",
              headers: {
                ...headers,
                "Content-Type": "application/json",
              },
              body: JSON.stringify(params.body),
            });
            break;
          case "delete":
            response = await fetch(url, {
              method: "DELETE",

              headers: {
                ...headers,
                //     "Content-Type": "multipart/form-data"
              },
            });
            break;
          default:
        }

        if (response) {
          try {
            if (
              project.endpoints[endpoint].contentType === "text/event-stream"
            ) {
              const reader = response.body.getReader();
              const decoder = new TextDecoder();

              const readStream = () => {
                reader
                  .read()
                  .then(({ done, value }) => {
                    if (done) {
                      console.log("Fin de la lecture");
                      return;
                    }
                    const decodedValue = decoder.decode(value, {
                      stream: true,
                    });
                    console.log("Données reçues :", decodedValue);
                    if (params.eventStreamListener)
                      params.eventStreamListener(decodedValue);

                    readStream();
                  })
                  .catch((error) => {
                    console.error("Erreur lors de la lecture du flux :", error);
                  });
              };

              readStream();
            } else {
              const result = await response.json();

              if (params?.cached) {
                console.log("setting cache", keycache,cache[keycache])
                 cache[keycache] = result;
              }
              if (params?.cache) {
                localStorage.setItem(params.cache, JSON.stringify(result));
              }
              return result;
            }
          } catch (err) {
            console.log("JSON ERROR", err, response);

            return null;
          }
        }
        return {};
      };
      project.uploadFile = async (
        files,
        onUploadProgress,
        dir = null,
        noRename = false
      ) => {
        var headers = {};
        var token = localStorage.getItem("token");
        if (token) headers["Authorization"] = "Bearer " + token;

        if (process.env.REACT_APP_MFTC_APP)
          headers["Mftc-Application"] = getApp();

        var namefiles = [];
        const formData = new FormData();

        for (var i = 0; i < files.length; i++) {
          var file = files[i];
          var parts = file.name.split(".");
          var ext = parts.pop();
          var fname =
            parts.join(".") + (noRename ? "" : Date.now()) + "." + ext;
          namefiles.push(fname);
          formData.append(fname, file, fname);
        }

        if (dir) formData.append("dir", dir);
        formData.append("files", JSON.stringify(namefiles));
        var body = formData;
        var response = await axios.post(
          process.env.REACT_APP_MFTC_HOST_FILES + "upload",
          body,
          {
            onUploadProgress: onUploadProgress,
            headers: {
              ...headers,
              //     "Content-Type": "multipart/form-data"
            },
          }
        );

        console.log("Upload Response", response);
        var results = response.data;
        return results;
      };
      project.getFiles = async (dir,type) => {
        var headers = {};
        var token = localStorage.getItem("token");
        if (token) headers["Authorization"] = "Bearer " + token;

        if (process.env.REACT_APP_MFTC_APP)
          headers["Mftc-Application"] = getApp();

        var response = await axios.post(
          process.env.REACT_APP_MFTC_HOST_FILES + "dir",
          { dir, recursive: true ,type},
          {
            headers: {
              ...headers,
            },
          }
        );

        var results = response.data;
        return results;
      };

      project.urlImage = (urlImage) => {
        if (!urlImage) return null;
        if (urlImage.substring(0, 4) !== "img_") return urlImage;
        //console.log(   url_server.split("/api")[0]   +"/user_img/"+ urlImage);
        return url_server.split("/api")[0] + "/user_img/" + urlImage;
      };
    }

    console.log("projectInit", project);

    // project.Objects= {};
    // project.getObject = (args)=>{      return getObject( {...args,project})

    // }

    project.getAuthUser = getAuthUser;
    project.setToken = setToken;

    _project = project;

    return project;
  } catch (e) {
    console.log("error project connection");

    const project = {
      api: () => {
        return;
      },
    };
    return project;
  }
};
