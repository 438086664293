
import React, { useState, useEffect, Suspense } from 'react';
import { liveComponent, loadComponent } from '../tools/gencomponent';
import LoaderSmall from './LoaderSmall';





const ComponentLoader = (dynamicProps ) => {

  var {params} = dynamicProps 
  const LiveComponent = liveComponent(params)
  const [Component, setComponent] = useState();

  useEffect(() => {
    const getComponent = async (params) => {
    
        const compiledComponent = await loadComponent(params);
        setComponent(() => compiledComponent);
     
    };
if(!LiveComponent)    getComponent(params);
  }, [params,Component,LiveComponent]);


if(LiveComponent){
return   <LiveComponent {...dynamicProps}  />
}

  if (!Component) {
    return     <LoaderSmall/>//   <div>Chargement du composant...{JSON.stringify(params)}</div>;
  }

  return (
    <Suspense fallback={<div>  <LoaderSmall/>Chargement en cours...{JSON.stringify(params)}</div>}>
      <Component {...dynamicProps}  />
    </Suspense>
  );
};

export default ComponentLoader;
