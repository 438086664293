import React, { useState, useEffect } from "react";
import { Editor, EditorState, ContentState, Modifier, CompositeDecorator } from "draft-js";
import "draft-js/dist/Draft.css";
import { convertFromHTML } from "draft-js";
import { stateToHTML } from "draft-js-export-html";

// Fonction pour convertir le HTML en EditorState
const convertHTMLToEditorState = (html) => {
  const blocksFromHTML = convertFromHTML(html);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );
  return EditorState.createWithContent(contentState);
};

// Fonction pour appliquer une classe à la sélection
const applyCustomClass = (editorState, className) => {
  const contentState = editorState.getCurrentContent();
  const selectionState = editorState.getSelection();

  // Créer une entité pour stocker la classe personnalisée
  const contentStateWithEntity = contentState.createEntity(
    "CUSTOM_CLASS", // Type d'entité
    "MUTABLE", // Entité mutable
    { className }
  );

  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  
  // Appliquer l'entité à la sélection
  const newContentState = Modifier.applyEntity(
    contentStateWithEntity,
    selectionState,
    entityKey
  );

  return EditorState.push(editorState, newContentState, "apply-entity");
};

// Décorateur pour appliquer la classe personnalisée
const CustomClassDecorator = (props) => {
  const { className } = props.contentState
    .getEntity(props.entityKey)
    .getData();
  return <span className={className}>{props.children}</span>;
};

// Décorateur composite pour appliquer la classe personnalisée au texte sélectionné
const decorator = new CompositeDecorator([
  {
    strategy: (contentBlock, callback, contentState) => {
      contentBlock.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          return entityKey !== null && contentState.getEntity(entityKey).getType() === "CUSTOM_CLASS";
        },
        callback
      );
    },
    component: CustomClassDecorator,
  },
]);

const InputHtml = ({
  id,
  label,
  onChange,
  value,
  className,
  bg = "bg-white",
  infos,
  disabled,
}) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty(decorator));

  // Initialiser l'éditeur avec le HTML de départ (value)
  useEffect(() => {
    if (value) {
      const initialEditorState = convertHTMLToEditorState(value);
      setEditorState(initialEditorState);
    }
  }, [value]);

  // Fonction de mise à jour de l'état de l'éditeur
  const handleEditorChange = (newEditorState) => {
    setEditorState(newEditorState);

    // Convertir le contenu de l'éditeur en HTML et passer à onChange
    const html = stateToHTML(newEditorState.getCurrentContent());
    if (onChange) {
      onChange(html);
    }
  };

  // Appliquer une classe personnalisée
  const applyClass = (className) => {
    const newEditorState = applyCustomClass(editorState, className);
    setEditorState(newEditorState);
  };

  return (
    <div
      id={id}
      className={`${className} ${bg} draft-editor`}
      style={{ border: "1px solid #ddd", padding: "10px", minHeight: "200px" }}
    >
      {label && <label htmlFor={id}>{label}</label>}

      {/* Barre d'outils */}
      <div className="toolbar" style={{ marginBottom: "10px" }}>
        <select
          onChange={(e) => applyClass(e.target.value)}
          defaultValue=""
          style={{ marginRight: "10px" }}
        >
          <option value="" disabled>
            Appliquer une classe personnalisée
          </option>
          <option value="font-bold">Classe Personnalisée 1</option>
          <option value="italic">Classe Personnalisée 2</option>
          <option value="bg-red-500">Classe Personnalisée 3</option>
        </select>
      </div>

      {/* Zone d'édition */}
      <div
        style={{
          border: "1px solid #ccc",
          minHeight: "150px",
          padding: "10px",
        }}
      >
        <Editor
          editorState={editorState}
          onChange={handleEditorChange}
          readOnly={disabled}
        />
      </div>

      {infos && <small className="text-muted">{infos}</small>}
    </div>
  );
};

export default InputHtml;
