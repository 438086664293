import { Await } from "react-router-dom";
import { showModalError, showModalOK } from "../components/modal";
import { retrieveProject } from "../service/mftc-api";

const installDeployment = async (deployment, withPull,blnUpdate,{setDeployment,setDeployments}) => {
    const project= retrieveProject()
    if (
      !window.confirm(
        `Are you sure you want to ${ blnUpdate ?  "update": "deploy" } app on ${deployment.server_account}?`
      )
    ) {
      return;
    }
    // e.preventDefault();

    const ReceiveMessage = (strMsgs) => {
      const sep = "@*_*@";
      var msgsLines = [];
      var parts = strMsgs.split(sep);
      parts.shift();
      var newmsgs = parts.map((strMsg) => {
        try {
          return JSON.parse(strMsg);
        } catch (error) {
          return { text: strMsg + JSON.stringify(error) };
        }
      });
      newmsgs.forEach((message) => {
        var lines = message.text.split("Line ");
        if (lines.length === 1) {
          if (message.text === "Build terminé" || message.text === "FIN") {
            //    setMods(mods=>({...mods,profile:{...mods.profile,installed_server:mods.profile.deployment_server }}))

            console.log("Fini");
            project
              .api("/get_deployment/:deployment_id", {
                args: { deployment_id: deployment.deployment_id },
              })
              .then((deployment) => {
                setDeployments((deployments) =>
                  deployments.map((a) =>
                    a.deployment_id === deployment.deployment_id
                      ? { ...a, ...deployment, isLoading: null }
                      : a
                  )
                );
                showModalOK();
              });
          }
          msgsLines.push(message);
        } else
          msgsLines = [
            ...msgsLines,
            ...lines.map((line) => {
              return { text: line, type: message.type };
            }),
          ];
      });

      setDeployments((deployments) =>
        deployments.map((a) =>
          a.deployment_id === deployment.deployment_id
            ? { ...a, Result: [...a.Result, ...msgsLines], isLoading: true }
            : a
        )
      );

      setTimeout(() => {
        var obj = document.getElementById(
          "Result_" + deployment.deployment_id + "infos_last"
        );
        if (obj)
          obj.scrollIntoView({
            behavior: "smooth",
            block: "nearest",
            inline: "nearest",
          });
      }, 100);
    };
if(setDeployment)
    setDeployment(
      deployment,
      { showInfos: true, Result: [], isLoading: true },
      true
    );
    else 
    setDeployments((deployments) =>
        deployments.map((a) =>
          a.deployment_id === deployment.deployment_id ? {...deployment,  showInfos: true, Result: [], isLoading: true } : a
        ))
    // TODO : sauvegarder app.profile.deployment_server ; si app.profile.deployment_server existe on le migre vers le nouveau

    try {
      await project.api(    blnUpdate ?"/update_deployment" :  "/install_deployment", {
        body: { deployment_id: deployment.deployment_id, withPull },

        eventStreamListener: ReceiveMessage,
        // project:mods.profile.deployment_server,
        url_server:
          deployment.server_account === "LOCALHOST"
            ? `https://localhost:3030/api/v1/`
            : `https://${deployment.server_account}.appisyou.fr/api/v1/`,
      });
    } catch (err) {
      showModalError(err);
    }
  };


  const uninstallDeployment = async (deployment,{setDeployment,setDeployments}) => {
    const project= retrieveProject()
    if (
      !window.confirm(
        `Are you sure you want to remove the  app of ${deployment.server_account}?`
      )
    ) {
      return;
    }
if(setDeployment)
    setDeployment(deployment, { isLoading: true }, true);
else 
setDeployments((deployments) =>
    deployments.map((a) =>
      a.deployment_id === deployment.deployment_id ? {...deployment,isLoading: true } : a
    ))
    try {
      await project.api("/uninstall_deployment", {
        body: { deployment_id: deployment.deployment_id },

        // project:mods.profile.deployment_server,
        url_server:
          deployment.server_account === "LOCALHOST"
            ? `https://localhost:3030/api/v1/`
            : `https://${deployment.server_account}.appisyou.fr/api/v1/`,
        // url_server: `https://localhost:3030/api/v1/`,
      });
    } catch (err) {
      showModalError(err);
    }

    deployment = await project.api("/get_deployment/:deployment_id", {
      args: { deployment_id: deployment.deployment_id },
    });
    setDeployments((deployments) =>
      deployments.map((a) =>
        a.deployment_id === deployment.deployment_id ? deployment : a
      )
    );

    showModalOK();
  };

  const createDeployment = async (deployment={},{setDeployments},install) => {
    const project= retrieveProject()
    deployment = await project.api("/set_deployment", {
      body: { ...deployment, deployment_id: null },
    });
    setDeployments((deployments) => [...deployments, deployment]);
    if(install) await installDeployment(deployment,true,false,{setDeployments})
    return deployment;
  };



  export{ installDeployment,uninstallDeployment,createDeployment}