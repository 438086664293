import * as fa from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useMemo, useRef, useState } from "react"
import { getStructure } from "../../../../../tools/tpl"
import { useFetcher } from "react-router-dom"
import { unsuidize } from "../../../../../service/suid"


const ToolCopyPaste = ({updateStructure,structure,idObj,selectTpl}) => {




    const maRef = useRef()
const [show,setShow]=useState()

const [clipBoard,setClipBoard]=useState( localStorage.getItem("TemplateClipBoard") )


const CopyTemplate= ()=>{

var obj =document.getElementById(idObj)
if(obj)
var _clipBoard= JSON.stringify({structure: unsuidize( getStructure(structure,idObj),{sub:"children"},true),innerHTML:  obj.innerHTML  } )
else 
{
    window.alert( idObj +" n'existe pas")
return }

localStorage.setItem("TemplateClipBoard",_clipBoard)
setClipBoard(_clipBoard)

}

const PastTemplate= ()=>{

if(clipBoard)
{
      updateStructure(JSON.parse(clipBoard).structure,idObj)
}
    
    }
    const ResetClipBoard= ()=>{

        if(clipBoard)
        {
            localStorage.removeItem("TemplateClipBoard")
            setClipBoard()
        }
            
            }

const hoverStart =(classe)=>{

    var obj = document.getElementById(idObj)

}

const hoverEnd =(classe)=>{
    var obj = document.getElementById(idObj)

}
useEffect(()=>{

if(clipBoard   && maRef){
    maRef.current.innerHTML =clipBoard.innerHTML
}




},[clipBoard])


return (
    <div className="flex flex-col w-full items-center  justify-center  gap-1 p-2">
  <div className="flex flex-row w-full items-center  justify-center">
<div className={" inline-flex appbutton border-2  border-white  p-2 ml-2 text-xs " 
    + (show ? " bg-white text-black bg-opacity-80 " :" bg-black text-white bg-opacity-30 " )    +( clipBoard ? "   rounded-l-full " :" rounded-full"  ) }
 

    onClick={()=>CopyTemplate()}  
   
onMouseOver={()=>hoverStart()}
onMouseOut={()=>hoverEnd()}
    >Copy
        </div> 
     { clipBoard &&   <><div className={" text-xs inline-flex appbutton border-2  border-white p-2 " 
    + (show ? " bg-white text-black bg-opacity-80 " :" bg-black text-white bg-opacity-30 " ) }
 

    onClick={()=>PastTemplate()}  
   
onMouseOver={()=>hoverStart()}
onMouseOut={()=>hoverEnd()}
    >Paste
        </div>
        
        <div className={" text-xs inline-flex appbutton border-2  border-white  p-2  rounded-r-full" 
    + (show ? " bg-white text-black bg-opacity-80 " :" bg-black text-white bg-opacity-30 " ) }
 

    onClick={()=>ResetClipBoard()}  
   
onMouseOver={()=>hoverStart()}
onMouseOut={()=>hoverEnd()}
    >Reset
        </div>
        
        
        </>} 
</div> 
{ clipBoard &&   <div ref={maRef}  >


</div>  

}



</div>
)
}
export default ToolCopyPaste