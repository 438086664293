const dynamicStyles = {
    styleSheets: {}, // Stocke les feuilles de style par ID
    rules: {}, // Stocke les règles pour chaque combinaison className + mediaCondition
  };
  function camelToKebab(property) {
    return property.replace(/([A-Z])/g, "-$1").toLowerCase();
  }
  function kebabToCamel(property) {
    return property.replace(/-([a-z])/g, (_, letter) => letter.toUpperCase());
  }
// Tableau de priorités des media queries
const mediaPriority = ["_", "sm", "md", "lg", "xl", "2xl"];

// Ajoutez une fonction pour comparer deux media queries
function compareMedia(a, b) {
  const indexA = mediaPriority.indexOf(a) === -1 ? mediaPriority.length : mediaPriority.indexOf(a);
  const indexB = mediaPriority.indexOf(b) === -1 ? mediaPriority.length : mediaPriority.indexOf(b);
  return indexA - indexB;
}


  const jokerizable = (text) => {
    if (!text) return text;
    const regex = /\{([^}]+)\}/gu;
    return text!== text.replace(regex, (match, p1) => {
      return "";
    });
  };



  // Tableau de correspondance des media queries
  const media_query = {
    sm: "(min-width: 640px)",
    md: "(min-width: 768px)",
    lg: "(min-width: 1024px)",
    xl: "(min-width: 1280px)",
    "2xl": "(min-width: 1536px)",
  };
  
  // Initialiser ou récupérer une feuille de style par ID
  function initStyleSheetById(styleId) {
    if (dynamicStyles.styleSheets[styleId]) {
      return dynamicStyles.styleSheets[styleId];
    }
  
    const style = document.createElement("style");
    style.id = styleId;
    document.head.appendChild(style);
  
    const styleSheet = style.sheet;
    dynamicStyles.styleSheets[styleId] = styleSheet;
    return styleSheet;
  }
  function reorderRules(styleId) {
    const styleSheet = dynamicStyles.styleSheets[styleId];
    if (!styleSheet) {
      console.warn(`La feuille de style "${styleId}" n'existe pas.`);
      return;
    }
    var rules = Object.values( dynamicStyles.rules).filter(rule=> rule.styleId===styleId)
  if(rules.length<2) return;
    // Récupérer toutes les règles

    rules.sort((a, b) => {
        const mediaA = a.mediaCondition || "_";
        const mediaB = b.mediaCondition || "_";
        return compareMedia(mediaA, mediaB);
      });
  
    const sortedRules = rules.map( rule=> styleSheet.cssRules[rule.index]  )

    rules.forEach((rule,index)=>  {  dynamicStyles.rules[rule.key].index =index })
    console.log(`Reorder ${styleId }setClassCss nb= ${styleSheet.cssRules.length}`);
    // Supprimer toutes les règles et les réinsérer dans le bon ordre
    while (styleSheet.cssRules.length > 0) {
      styleSheet.deleteRule(0);
    }

    sortedRules.forEach((rule,i) => {
        styleSheet.insertRule(rule.cssText);
        console.log(`setClassCss ${i} : ${ rule.cssText  }`);
    });
    console.log(`setClassCss Les règles de la feuille "${styleId}" ont été réorganisées.`);
  }
  
  function addClass(styleId, className, styles, mediaCondition = null , index=null) {
    const styleSheet = initStyleSheetById(styleId);
  var mediaConditionRule
    // Utiliser la correspondance si la mediaCondition est un alias (sm, md, etc.)
    if (media_query[mediaCondition]) {
        mediaConditionRule = media_query[mediaCondition];
    }
  
    // Identifier la clé unique (className + mediaCondition)
    const key = mediaCondition ? `${className}@${mediaCondition}` : className;
  
    // Vérifier si la règle existe déjà
    if (dynamicStyles.rules[key]) {
      console.log(`setClassCss La règle "${key}" existe déjà. Utilisez updateClass() pour la mettre à jour.`);
      return;
    }
  
    const rule = mediaCondition
      ? `@media ${mediaConditionRule} { ${className} { ${styles} } }`
      : `${className} { ${styles} }`;
  
      if(typeof index!=="number"){

    var rules = Object.values( dynamicStyles.rules).filter(rule=> rule.styleId===styleId)
       rules.sort((r1,r2)=>r2.index -  r1.index)

    // Trouver la bonne position d'insertion basée sur la priorité
    const insertIndex = rules.findIndex((r) => {
      const existingMedia = r.mediaCondition || "_";
      return compareMedia(existingMedia, mediaCondition || "_") > 0;
    });
  
    // Si aucune position n'est trouvée, insérer à la fin
     index = insertIndex === -1 ? rules.length : insertIndex;

     //On renumerote les suivants car ça decale l'index de +1
     rules.forEach(rule=>  {
        if(index<=dynamicStyles.rules[rule.key].index)  dynamicStyles.rules[rule.key].index +=1  })

}
    styleSheet.insertRule(rule, index);
    dynamicStyles.rules[key] = { key, index, styleId, className, mediaCondition, styles };
  
    console.log(` ${key} setClassCss ajouté à la feuille "${styleId}": ${rule} à l'index ${index}`);

  }
  

    // Mettre à jour une classe (globale ou avec media query)
    function setClass(styleId, className, newStyles, mediaCondition = null) {
        // Utiliser la correspondance si la mediaCondition est un alias (sm, md, etc.)
       
if(!className )
{
    console.log("setClassCss Non reconnu" , { mediaCondition,styleId,newStyles} )
    return
}
const key = mediaCondition ? `${className}@${mediaCondition}` : className;
console.log(`setClassCss ${key} reconnue `, { mediaCondition,styleId,newStyles} )

      

// Vérifier si la classe existe déjà
const existingRule = dynamicStyles.rules[key];

// Vérifier si la règle est identique à celle existante
const isSameRule =
existingRule &&
  existingRule.styles === newStyles &&
  existingRule.mediaCondition === mediaCondition;

if (isSameRule) {
  console.log(`La règle pour "${key}" est déjà à jour.`);
  return;
}
var index
  if (existingRule) {
          // Supprimer l'ancienne règle
   index=        removeClass(styleId, className, mediaCondition);   
 }
 
        // Ajouter la nouvelle règle
        addClass(styleId, className, newStyles, mediaCondition,index);
        console.log(`Mise à jour: .${className} (${mediaCondition || "global"}) dans la feuille "${styleId}"`);
      }
      
    // Mettre à jour une classe (globale ou avec media query)
    function updateClass(styleId, className, newStyles, mediaCondition = null) {
        // Utiliser la correspondance si la mediaCondition est un alias (sm, md, etc.)
     
      
        const key = mediaCondition ? `${className}@${mediaCondition}` : className;
      
        if (!dynamicStyles.rules[key]) {
          console.warn(`La règle "${key}" n'existe pas. Utilisez addClass() pour la créer.`);
          return;
        }
      
        // Supprimer l'ancienne règle
     var index=    removeClass(styleId, className, mediaCondition);
      
        // Ajouter la nouvelle règle
        addClass(styleId, className, newStyles, mediaCondition,index);
        console.log(`Mise à jour: .${className} (${mediaCondition || "global"}) dans la feuille "${styleId}"`);
      }
      
  
  // Supprimer une classe (globale ou avec media query)
  function removeClass(styleId, className, mediaCondition = null,remove=null) {
    // Utiliser la correspondance si la mediaCondition est un alias (sm, md, etc.)
   
  
    const key = mediaCondition ? `${className}@${mediaCondition}` : className;
  
    if (dynamicStyles.rules[key]) {
      const { index } = dynamicStyles.rules[key];
      const styleSheet = dynamicStyles.styleSheets[styleId];
  
      styleSheet.deleteRule(index);
      delete dynamicStyles.rules[key];
      console.log(`Supprimé: .${className} (${mediaCondition || "global"}) de la feuille "${styleId}"`);

if(remove){
    var rules = Object.values( dynamicStyles.rules).filter(rule=> rule.styleId===styleId)
    rules.sort((r1,r2)=>r2.index -  r1.index)
    rules.forEach((rule,index)=>  {     dynamicStyles.rules[rule.key].index =index })

}


      return index
    } else {
      console.warn(`La règle "${key}" n'existe pas.`);
    }


  }
  

  // Supprimer une feuille de style entière
  function removeStyleSheet(styleId) {
    const styleSheet = dynamicStyles.styleSheets[styleId];
    if (styleSheet) {
      const styleElement = document.getElementById(styleId);
      if (styleElement) {
        styleElement.parentNode.removeChild(styleElement);
      }
  
      // Supprimer toutes les règles liées à cette feuille
      for (const key in dynamicStyles.rules) {
        if (dynamicStyles.rules[key].styleId === styleId) {
          delete dynamicStyles.rules[key];
        }
      }
  
      delete dynamicStyles.styleSheets[styleId];
      console.log(`Feuille de style "${styleId}" supprimée.`);
    } else {
      console.warn(`La feuille de style "${styleId}" n'existe pas.`);
    }
  }

  const setStructureCss = (structure,template_id,reorder,jokerize,cleanResponsiveClassName)=>{
    

if(template_id)  structure.tplClass = "tpl-"+ template_id
if(!structure.tplClass) return structure
var className ="."+ structure.tplClass+"-" + structure.suid 
var groups= {}

var ExistingClasses = Object.keys(dynamicStyles.rules).filter( keys=>  keys.startsWith(className)   )
console.log({  "MediacLASSES ":"", strcl: structure.className,className, MD:structure.mediaClasses} )  ;
  if( structure.tplClass)
    { 
      
  ( structure.className+"").split(" ").forEach(mc=>{
if(!mc) return false;
if(!mc.startsWith("(") )return;

       var p= mc.split(")")
       var state = p.shift().substr(1)
     
       mc = p.join(")") 
      
       var parts = mc.split(":")
       let mediaCondition="_."+state
       if(  parts[0].trim() in media_query )
       {
           mediaCondition = parts.shift().trim() +"."+state
       }
       if(parts.length===1) return
       var styles=    parts.join(":").trim()
       var partstyles =styles.split(";")
        partstyles.forEach(s=>  { var l = s.split(":"); 
        var prop =    l.shift().trim()  


        var value = l.join(":").trim()
        if(jokerizable(value))
        {
if(jokerize) value =jokerize(value)
    else return 
        } 
        
            if(!groups[mediaCondition]) groups[mediaCondition]={}
            if(value[value.length-1]!==";") value +=";" 
             groups[mediaCondition][prop]=  value 
            
            }   )
        

       })
      }
       if(structure.mediaClasses &&  structure.tplClass)
        { 
          



       structure.mediaClasses.forEach(mc=>{
mc= mc.trim()
//On enleve eventuellement les etats et conditions
if(mc.startsWith("("))
    {
       var p= mc.split(")")
       var state = p.shift().substr(1)
  
       mc = p.join(")") 
    } 

        var parts = mc.split(":")
        let mediaCondition="_" + (state? "."+state:"")
        if(  parts[0].trim() in media_query )
        {
            mediaCondition = parts.shift().trim() + (state? "."+state:"")
        }
        var styles= parts.join(":").trim()
       

        var partstyles =styles.split(";")
        partstyles.forEach(s=>  { var l = s.split(":"); 
        var prop =    l.shift().trim()  


        var value = l.join(":").trim()
        if(jokerizable(value))
        {
if(jokerize) value =jokerize(value)
    else return 
        } 
        
            if(!groups[mediaCondition]) groups[mediaCondition]={}
            if(value[value.length-1]!==";") value +=";" 
             groups[mediaCondition][prop]=  value 
            
            }   )
        

       })
    }

    if( structure.cssAttributes) {
          let mediaCondition="_"
          if(!groups[mediaCondition]) groups[mediaCondition]={}
       Object.keys(structure.cssAttributes).filter(k=>jokerize || !jokerizable(structure.cssAttributes[k]) ).forEach(k=>  groups[mediaCondition][k] =( jokerize ? jokerize(structure.cssAttributes[k]) : structure.cssAttributes[k])+";"  )
         
      }
    
     for( let media_state of  Object.keys( groups))
     {

        var parts = media_state.split(".")
        var media = parts[0]
        var state = parts[1]

        var key = media!=="_" ? `${className + (state? "."+state:"")   }@${media}` : className + (state? "."+state:"");
        ExistingClasses = ExistingClasses.filter(cls=>cls!==key)

         var styles = Object.keys(  groups[media_state]).map( attr=>  camelToKebab(attr) + ":"  +    groups[media_state][attr] ).join("\n")
      if(cleanResponsiveClassName && cleanResponsiveClassName(media+":test")!==media+":test")
        {
        console.log("setClassCss aborted media query unsimulated "+structure.tplClass + "  " + media_state)
         styles=null
        } 
        console.log("MediacLASSES STYLE" ,structure.tplClass +"   "+ className + (state? "."+state:""), {className, nd:structure.mediaClasses} )  
      if(styles)  setClass( structure.tplClass, className + (state? "."+state:"") , styles,media ==="_"? null:media );
        else    removeClass( structure.tplClass, className+ (state? "."+state:"") ,media,true);
    }
    //On supprime les classes non présentes à nouveau
     ExistingClasses.forEach(cls=>{
        console.log("setClassCss la classe n'existe plus  "+cls + " "  + className )
        removeClass(  structure.tplClass, className,cls.split("@")[1],true);
     } )

    
    if(structure.children)
    {

        structure.children =  structure.children.map(s=> setStructureCss  (s,template_id,false,null,cleanResponsiveClassName) ) 
    }
    if(reorder )  reorderRules("tpl-"+ template_id)
return  structure
  } 

const setTemplateCss = (template,refresh )=>{
    console.log("setClassCss setTemplateCss START " +template.template_id)
    if(!refresh  &&  dynamicStyles.styleSheets["tpl-"+ template.template_id] )
         {
        return 
      } 
      initStyleSheetById("tpl-"+ template.template_id)
      if (template?.structure)
      {
        console.log("setTemplateCss  media template " , template)
        template.structure =  setStructureCss(template.structure,template.template_id)
        reorderRules("tpl-"+ template.template_id)
      }
      console.log("setClassCss setTemplateCss END " +template.template_id)
} 

const  initCssGlobalTemplate= (template,templates)=>{
    console.log("setClassCss initCssGlobalTemplate Start")
    for(let _template_id of  Object.keys(template.props_from_templates))
        {
             var tpl  = templates.find(tp=>tp.template_id+""===""+_template_id)
            if(tpl)   setTemplateCss(tpl, _template_id+"" ===""+  template.template_id)
           }  
           console.log("setClassCss initCssGlobalTemplate End")
}
  
  // Export des fonctions et des constantes
  export { 
    reorderRules,
    addClass, 
    removeClass, 
    updateClass, 
    removeStyleSheet, 
    setTemplateCss,
    setStructureCss,
    initCssGlobalTemplate,
    media_query 
  };
  