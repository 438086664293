import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";

const ContextToolText = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app,prefixe_filter}) => {
  

  var categories = [
    { 
      name: "Police", 
      classes: ["font-sans", "font-serif", "font-mono", "font-app-1", "font-app-2"] 
    },
    { 
      name: "Taille", 
      pref: "text-", 
      classes: ["text-xs", "text-sm", "text-base", "text-lg", "text-xl", "text-2xl", "text-3xl", "text-4xl", "text-5xl", "text-6xl", "text-7xl", "text-8xl", "text-9xl"] 
    },
    { 
      name: "Interlignage", 
      pref: "leading-", 
      classes: ["leading-none", "leading-tight", "leading-snug", "leading-normal", "leading-relaxed", "leading-loose", "leading-3", "leading-4", "leading-5", "leading-6", "leading-7", "leading-8", "leading-9", "leading-10"] 
    },
    { 
      name: "Gras ", 
      pref: "font-", 
      classes: ["font-thin", "font-extralight", "font-light", "font-normal", "font-medium", "font-semibold", "font-bold", "font-extrabold", "font-black"] 
    },
    { 
      name: "Style", 
      notExclusive: true, 
      classes: ["italic", "not-italic", "underline", "no-underline", "line-through", "uppercase", "lowercase", "capitalize", "normal-case"] 
    },
 
    { 
      name: "Opacité", 
      pref: "text-opacity-", 
      classes: ["text-opacity-0", "text-opacity-25", "text-opacity-50", "text-opacity-75", "text-opacity-100"] 
    },
    { 
      name: "Alignement", 
      pref: "text-", 
      classes: ["text-left", "text-center", "text-right", "text-justify"] 
    },
    { 
      name: "Multiligne", 
      pref: "whitespace-", 

      classes: ["whitespace-normal", "whitespace-nowrap", "whitespace-pre", "whitespace-pre-line", "whitespace-pre-wrap"] 
    },
    { 
      name: "Divers", 
      notExclusive: true, 
      classes: [ "break-normal", "break-words", "break-all", "truncate"] 
    },
    { 
      name: "Espacement", 
      pref: "tracking-", 
      classes: ["tracking-tighter", "tracking-tight", "tracking-normal", "tracking-wide", "tracking-wider", "tracking-widest"] 
    },

  ];


 
    
 
  const toggleClasse =(pref,classe)=>{
    var className=""
    var parts = (structure.className+"").split(" ")
  
  
  if( parts.includes(classe ) )
  {
     parts = parts.filter(c=>c!==  classe  )
  }
  else 
  {

if((!categories.find(cat=>cat.name===pref).notExlusive))         parts =   parts.filter(c=>  !categories.find(cat=>cat.name===pref).classes.find(_c=> c=== _c)  )
  parts.push( classe)
  
  }
  className =parts.join(" ")
  updateTemplateStructure( {...structure,className},selected)
  }

      const [activeCategory, setActiveCategory] = useState(categories[0].name);
    if (!selected) return <></>;
    return (
      <div
        id={"ShowTemplateStructure"}
        className="bg-zinc-900 text-white p-2 rounded-xl m-2 flex-inline flex-col items-center justify-center  border-white shadow text-xs  overflow-y-auto"
      >
         {/* Onglets */}
         <div className="flex gap-2 mb-4">
      {categories.map((category) => (
        <div
          key={category.name}
          onClick={() => setActiveCategory(category.name)}
          className={(category.subname ? 'px-2 ': 'px-2 '     )+ `  cursor-pointer  py-2 rounded ${
            activeCategory === category.name
              ? "bg-green-700 text-white "
              : (category.subname ? "bg-zinc-800 text-gray-300":"bg-zinc-700 text-gray-300" )
          }`}
        >
          {category.subname??  category.name}
        </div>
      ))}
    </div>
  {  categories.filter((category) => category.name === activeCategory).map( pref=><div  key={pref.name}
  className="w-full flex-row flex p-1 border-t border-zinc-700 gap-1 items-center"
  >
  
  {pref.classes.map(classe=><div key={classe}   onClick={()=>toggleClasse(pref.name,classe)}  className={"rounded-full  h-8 p-1 px-2 flex items-center " + (  (structure.className+"").split(" ").includes(classe )  ?"bg-green-700 text-white  border border-white":"bg-zinc-600 text-black")} > {   pref.pref ? classe.replace(pref.pref,""): classe} </div>)}
  

  
  
  </div>)  }
        
  <div className="w-full flex flex-wrap p-2 bg-zinc-800 rounded-lg text-xs mb-2">

{categories.flatMap(category => category.classes.filter(cls => (structure.className || "").split(" ").includes(cls)).map(cls=>({classe:cls,pref:category.name})) )
  .map(activeClass => (
    <span 
    onClick={()=>toggleClasse(activeClass.pref, activeClass.classe)} 
      key={activeClass.classe}
      className="bg-green-700 text-white rounded-full px-2 py-1 mr-2 mb-1"
    >
      {activeClass.classe}
    </span>
  ))}
</div>
      </div>
      );
    };
    

export default ContextToolText;
