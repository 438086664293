import React, { useEffect, useMemo, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import Content from "../../../../components/Content";
import { getFilterRecord } from "../../../../service/localdata";
import VarContent from "../../../../components/VarContent";
import { addWorkingStone } from "../../../../service/milestone";
import { data } from "../../../../service/data";
import ContentTemplate from "../../../../components/display/ContentTemplate";
import { title } from "process";
import SelectDropDown from "../../../../components/inputs/SelectDropDown";
import InputData from "../../../../components/inputs/InputData";

function AppContents({ app, searchValue, sub_what }) {
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const { project, getProject, setAuthUser, authUser, getGlobalData } =
    useContext(MfctContext);
  const [schema, setSchema] = useState();
  const [allSchemas, setAllSchemas] = useState([]);
  const [schemas, setSchemas] = useState([]);
  const [publicContents, setPublicContents] = useState([]);
  const [showPublic, setShowPublic] = useState();

  const [propSchema, setPropSchema] = useState([]);
  const [showTable, setShowTable] = useState(false);

  const [filters, setFilters] = useState({});
const [comparators,setComparators] = useState([])
  const [sorts, setSorts] = useState({});

  const cmpSingle = (content_id)=>{
    return comparators?.find(c=>c.content_id+""===""+content_id)?.content.Single
  }
  const cmpFunc = (content_id)=>{
    return comparators?.find(c=>c.content_id+""===""+content_id)?.func
  }

  const getContents = async () => {
    await getGlobalData();
    var _allSchemas = await getFilterRecord(
      (content) => content.is_schema,
      "content"
    );

    var _schemas = await getFilterRecord(
      (content) =>
        (!content.app_id ||
          parseInt(content.app_id) === parseInt(app.app_id) ||
          content.is_public) &&
        content.is_schema,
      "content"
    );

    //  project.api("/get_schemas/:app_id",{args:{app_id:app.app_id} })

    var _contents = await getFilterRecord(
      (content) =>
        parseInt(content.app_id) === parseInt(app.app_id) && !content.is_schema,
      "content"
    );
    // await project.api("/app/:app_id/get_contents", { args:{app_id:app.app_id} })
    

    _schemas = _schemas
      .filter((t) => t.content.is_addable)
      .map((s) => ({
        ...s,
        nb:
          (s.app_id === app.app_id ? 1 : 0) +
          _contents.filter((c) => c.schema_id === s.content_id).length,
      }));
    _schemas.sort((a, b) => {
      if (a.nb !== b.nb) return a.nb > b.nb ? -1 : 1;

      return a.title < b.title ? -1 : 1;
    });

const _cmp = await getFilterRecord(  (content) =>   content.schema_id===974, "content");
setComparators(_cmp.map(cmp=>{
cmp.func = new Function("a","b",cmp.content.Code)

  return cmp


}))


    const tout = { title: "Tout", content_id: 0 };
    const backOffice = {
      title: "Template Datas",
      content_id: 0,
      template_id: "*",
    };
    _schemas.unshift(tout);
    _schemas.unshift(backOffice);
    setAllSchemas(_allSchemas);
    setSchemas(_schemas);
    setSchema(tout);
    setContents(_contents);
  };
  const filteredContent = useMemo(() => {

    let filtered = contents.filter((c) => {
      var pre =
        (!schema?.content_id || c.schema_id === schema.content_id) &&
        (!schema?.template_id || c.template_id) &&
        (!searchValue ||
          searchValue === "" ||
          c.title?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1);
      if (!pre) return false;

      for (var key of Object.keys(filters ?? {})) {

        var f = filters[key];
        if(!f || (!cmpSingle( f.cmp)  && !f.value))  continue 
        if (typeof c.content[key] === "object") {
          if (c.content[key]?.content_id + "" !== "" + f.value?.content_id) return false;
        } else {
          console.log(c.content[key] , f.value)
          if(!c.content[key] ) return false 
var func = cmpFunc(f.cmp)
         if(func && !func(c.content[key],f.value)) return false; 
     
    }}
    return true;
  });


    // Appliquer le tri
    if (Object.keys(sorts).length > 0) {
      filtered.sort((a, b) => {
        for (const [key, direction] of Object.entries(sorts)) {
          let valueA = key === "title" ? a[key] : a.content?.[key];
          let valueB = key === "title" ? b[key] : b.content?.[key];

          if (valueA === valueB) continue;

          if (valueA === null || valueA === undefined) return 1;
          if (valueB === null || valueB === undefined) return -1;

          if (direction === "asc") {
            return valueA < valueB ? -1 : 1;
          } else {
            return valueA > valueB ? -1 : 1;
          }
        }
        return 0;
      });
    }

    return filtered;
  }, [contents, schema?.content_id, schema?.template_id, searchValue, sorts,filters]);

  useEffect(() => {

    const initSchemes = async()=>{
    var s;
    if (sub_what) {
      s = schemas.find((t) => t.content_id + "" === sub_what);
      setSchema(s);
    }
var _sorts= []
var _s=(s && localStorage.getItem("sorts_"+s.content_id) )
if(_s)
{
  console.log("sorts",_s)

_s  = JSON.parse( _s  )
_sorts =_s
}
var _filters = {}
var _f= (s &&  localStorage.getItem("filters_"+s.content_id) )
if(_f){
  console.log("filters",_f)
  _f  = JSON.parse( _f  )
  for (var key of Object.keys(_f ?? {})) {

    var f = _f[key];
 //   if(f.cmp &&  f.cmp.content_id )  _f[key].cmp = comparators.find(cmp=>cmp.content_id===  _f[key].cmp.content_id)
}
_filters = _f
}
    setSorts(_sorts)
    setFilters(_filters)
    addWorkingStone(app, s?.title ?? "Contenus", "app");
  }
initSchemes()

  }, [sub_what, schemas, app,comparators]);

  useEffect(() => {
    const getProps = async () => {
      if (schema?.content_id) {
        var s = await project.api("/get_props/:schema_id", {
          args: { schema_id: schema.content_id },
        });
        for (let i in s) {
          s[i].schema = await data.getSchema(
            s[i].is_prop ? s[i].prop_schema_id : s[i].porp_schema_id
          );
        }
        setPropSchema(s);
      }
    };

    getProps();
  }, [schema, project]);

  const createContent = async (content) => {
    var _content = await data.save_content(
      { ...content, content_id: null, app_id: app.app_id, is_public: false },
      true
    );
    if (_content) navigate(`/content/${_content.content_id}`);
  };

  const createFromContent = async (content) => {
    var _content = await project.api("/set_content", {
      body: {
        ...content,
        content_id: null,
        app_id: app.app_id,
        is_public: false,
      },
    });
    if (_content) await getContents();
  };

  const duplicateContent = async (content) => {
    var _content = await project.api("/duplicate_content/:content_id", {
      args: { content_id: content.content_id },
    });
    if (_content) navigate(`/content/${_content.content_id}`);
  };

  const deleteContent = async (content) => {
    if (
      !window.confirm("Are you sure you want to delete '" + content.name + "'?")
    )
      return;
    await project.api("/delete_content/:content_id", {
      args: { content_id: content.content_id },
    });
    await getContents();
  };



  const FilterComponent = ({ field, schema_id, schema }) => {
    schema =
      schema ?? allSchemas.find((s) => s.content_id + "" === "" + schema_id);
      var cmps = comparators.filter(c=> c.content.Schema.find(s=>s.content_id+""===""+schema.content_id) )

      if(filters[field].cmp!=="content_id" && cmps.length===0) return <></>
    return (
      <div className="w-full my-1 flex-row flex bg-orange-100 text-xs gap-1 text-gray-500 items-center p-1 rounded">
      { 
cmps.length>1 &&
      <SelectDropDown
          className={"w-full"}
          mainClassName={ cmpSingle (filters[field].cmp) ? "w-full":"w-10"}
          options={cmps }
          fieldId={"content_id"}
          fieldSearch="title"
          value={comparators.find(cmp=>cmp.content_id===filters[field].cmp)   }
          onChange={(cmp) =>
            _setFilters((prev) => ({
              ...prev,
              [field]: { ...prev[field], cmp:cmp.content_id?? cmp },
            }))
          }
        />
  }{(filters[field].cmp==="content_id" ||  !cmpSingle (filters[field].cmp)) &&
        <InputData
          schema={schema}
          className={"grow"}
          prop={{ name: field, schema_id: schema.content_id }}
          context={{ minimized: true }}
          value={filters[field].value}
          onChange={(value) =>
            _setFilters((prev) => ({
              ...prev,
              [field]: { ...prev[field], value: value },
            }))
          }
        />
  }
      </div>
    );
  };
const _setSorts=(s)=>{

  if(typeof s ==="function") s = s(sorts)
  if(schema) {
   if(s.length>0) 
    localStorage.setItem("sorts_"+schema.content_id,JSON.stringify(s))
  else 
  localStorage.removeItem("sorts_"+schema.content_id)
  }
  setSorts(s)
} 
const _setFilters=(f)=>{

  if(typeof f ==="function") f = f(filters)
if(schema) { 
  if(Object.keys(f).length>0)
  localStorage.setItem("filters_"+schema.content_id,JSON.stringify(f))
else   
localStorage.removeItem("filters_"+schema.content_id)}
  setFilters(f)
} 

  useEffect(() => {
    const getPublicContents = async () => {

      var _contents = await getFilterRecord(
        (content) =>
          parseInt(content.schema_id) === parseInt(schema.content_id) &&
          parseInt(content.app_id) !== parseInt(app.app_id),
        "content"
      );
      // await project.api("/app/:app_id/get_contents", { args:{app_id:app.app_id} })
      console.log(_contents);
      setPublicContents(_contents);
    };

    if (showPublic && schema?.content_id) {
      getPublicContents();
    } else {
      setPublicContents(null);
    }
  }, [showPublic, schema, app]);
  useEffect(() => {
    if (project) getContents();
  }, [project]);
  return (
    <div className="App w-full">
      <div className="block w-full text-black justify-start overflow-x-auto overflow-y-hidden">
        <div className="inline-flex  justify-start relative ">
          {" "}
          {schemas.map((t, i) => (
            <div
              key={i}
              onClick={() => {
                navigate(`/apps/${app.app_id}/Contents/${t.content_id}`);
                //setSchema(t)
              }}
              className={
                "m-2 h-16 w-24  shadow-lg border border-zinc-300 rounded-lg p-2 text-xs font-bold flex flex-col items-center justify-center " +
                (schema?.content_id === t.content_id ? "" : " opacity-50 ") +
                (t.nb > 0
                  ? "bg-orange-500 text-white"
                  : schema?.content_id === t.content_id
                  ? "bg-zinc-300 sticky left-0 right-0 "
                  : "bg-white")
              }
            >
              <FontAwesomeIcon
                icon={
                  t.content?.icone ? fa[t.content.icone] : fa.faQuestionCircle
                }
              />{" "}
              {t.title}
              {t.content_id > 0 && "s"}
            </div>
          ))}
        </div>
      </div>
      <div className="w-full flex justify-end mt-2">
        {schema?.content_id > 0 && (
          <>
            <div
              onClick={() =>
                createContent({
                  app_id: app.app_id,
                  schema_id: schema?.content_id,
                  title: (schema?.title ?? "Content") + " " + Date.now(),
                })
              }
              className=" text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6 appbutton"
            >
              <FontAwesomeIcon icon={fa.faPlusCircle} /> Créer{" "}
              {schema?.content_id > 0 ? schema.title : " contenu"}
            </div>
            <div
              onClick={() => setShowPublic(true)}
              className=" text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6 appbutton"
            >
              <FontAwesomeIcon icon={fa.faPlusCircle} /> Créer{" "}
              {schema?.content_id > 0 ? schema.title : " contenu"} depuis...
            </div>{" "}
          </>
        )}
        {schema?.content_id > 0 && (
          <div
            onClick={() => setShowTable(!showTable)}
            className={
              "mx-2 p-1 px-3 rounded-full mb-6 appbutton " +
              (showTable ? " bg-orange-700 text-white" : "text-black  bg-white")
            }
          >
            <FontAwesomeIcon icon={!showTable ? fa.faList : fa.faTable} />{" "}
            {!showTable ? "Liste" : "Tableau"}
          </div>
        )}
      </div>
      <div className="w-full  flex flex-wrap   rounded-xl ">
        {showTable && schema?.content_id && (
          <div className="text-sm w-full relative">
            <table style={{ minWidth: "100%" }}>
              <thead>
                <tr className="bg-orange-500 text-white p-2  sticky top-0">
                  <th className="p-2">
                    <div className="inline-flex ml-2 items-center">
                      Titre
                      <FontAwesomeIcon
                        icon={fa.faArrowUp}
                        className={
                          "opacity-50 hover:opacity-100 cursor-pointer mx-1 p-1 " +
                          (sorts.title === "asc"
                            ? "bg-white text-black rounded-full "
                            : "")
                        }
                        onClick={() => {
                          const newSorts = { ...sorts };
                          if (newSorts.title === "asc") {
                            delete newSorts.title;
                          } else {
                            newSorts.title = "asc";
                          }
                          _setSorts(newSorts);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={fa.faArrowDown}
                        className={
                          "opacity-50 hover:opacity-100 cursor-pointer mx-1 p-1 " +
                          (sorts.title === "desc"
                            ? "bg-white text-black rounded-full "
                            : "")
                        }
                        onClick={() => {
                          const newSorts = { ...sorts };
                          if (newSorts.title === "desc") {
                            delete newSorts.title;
                          } else {
                            newSorts.title = "desc";
                          }
                          _setSorts(newSorts);
                        }}
                      />
                      <FontAwesomeIcon
                        icon={fa.faFilter}
                        className={
                          "opacity-50 hover:opacity-100 cursor-pointer mx-1 p-1 " +
                          (filters.title
                            ? "bg-white text-black rounded-full "
                            : "")
                        }
                        onClick={() => {
                          const newFilters = { ...filters };
                          if (newFilters.title) {
                            delete newFilters.title;
                          } else {
                            newFilters.title = { cmp:  comparators.filter(c=> c.content.Schema.find(s=>s.content_id+""==="42") )[0].content_id  };
                          }
                          _setFilters(newFilters);
                        }}
                      />
                    </div>
                    {filters.title && (
                      <FilterComponent field={"title"} schema_id={42} />
                    )}
                  </th>
                  {propSchema
                    ?.filter(
                      (p) =>
                        schema.content_id === p.porp_schema_id &&
                        !p.is_list &&
                        (p.schema?.content?.is_value ||
                          p.schema?.content?.display_template_id) &&
                        p.is_prop &&
                        !p.compute_id
                    )
                    .map((p) => (
                      <th className="p-2  items-start " key={p.name}>
                        <div className="inline-flex ml-2 items-center">
                          {p.name}
                          <FontAwesomeIcon
                            icon={fa.faArrowUp}
                            className={
                              "opacity-50 hover:opacity-100 cursor-pointer mx-1 p-1 " +
                              (sorts[p.name] === "asc"
                                ? "bg-white text-black rounded-full "
                                : "")
                            }
                            onClick={() => {
                              const newSorts = { ...sorts };
                              if (newSorts[p.name] === "asc") {
                                delete newSorts[p.name];
                              } else {
                                newSorts[p.name] = "asc";
                              }
                              _setSorts(newSorts);
                            }}
                          />
                          <FontAwesomeIcon
                            icon={fa.faArrowDown}
                            className={
                              "opacity-50 hover:opacity-100 cursor-pointer mx-1 p-1 " +
                              (sorts[p.name] === "desc"
                                ? "bg-white text-black rounded-full "
                                : "")
                            }
                            onClick={() => {
                              const newSorts = { ...sorts };
                              if (newSorts[p.name] === "desc") {
                                delete newSorts[p.name];
                              } else {
                                newSorts[p.name] = "desc";
                              }
                              _setSorts(newSorts);
                            }}
                          />
                          {p.schema && 
                          (!p.schema.content.is_value 
                            ||  comparators.filter(c=> c.content.Schema.find(s=>s.content_id+""===""+p.schema.content_id) ).length>0
                          ) && (
                            <FontAwesomeIcon
                              icon={fa.faFilter}
                              className={
                                "opacity-50 hover:opacity-100 cursor-pointer mx-1 p-1 " +
                                (filters[p.name]
                                  ? "bg-white text-black rounded-full "
                                  : "")
                              }
                              onClick={() => {
                                const newFilters = { ...filters };
                                if (newFilters[p.name]) {
                                  delete newFilters[p.name];
                                } else {
                                  newFilters[p.name] = { 
                                    
                                    cmp: 
                                    !p.schema.content.is_value ? "content_id" : comparators.filter(c=> c.content.Schema.find(s=>s.content_id+""===""+p.schema.content_id) )[0].content_id }
                                }
                                _setFilters(newFilters);
                              }}
                            />
                          )}
                        </div>
                        {filters[p.name] && (
                          <FilterComponent field={p.name} schema={p.schema} />
                        )}
                      </th>
                    ))}
                  {}
                  <th className="p-2 sticky right-0 bg-orange-500">Actions</th>
                </tr>
              </thead>
              <tbody>
                {filteredContent.map((content, i) => (
                  <tr
                    className="bg-white text-black p-2 border-t border-zinc-300"
                    key={content.content_id}
                  >
                    <td>{content.title}</td>
                    {propSchema
                      ?.filter(
                        (p) =>
                          schema.content_id === p.porp_schema_id &&
                          !p.is_list &&
                          (p.schema?.content.is_value ||
                            p.schema?.content.display_template_id) &&
                          p.is_prop &&
                          !p.compute_id
                      )
                      .map((p) => (
                        <td>
                          {p.schema.content?.display_template_id &&
                          content.content ? (
                            <ContentTemplate
                              content={content.content[p.name]}
                              schema={p.schema}
                              template_id={
                                p.schema.content?.display_template_id
                              }
                            />
                          ) : content.content ? (
                            content.content[p.name]?.toString()
                          ) : (
                            "null"
                          )}
                        </td>
                      ))}
                    <td className="p-2 sticky right-0 text-white bg-orange-500">
                      <Link to={`/content/${content.content_id}`}>
                        <FontAwesomeIcon
                          className="cursor-pointer mx-2"
                          icon={fa.faEdit}
                        />
                      </Link>
                      <FontAwesomeIcon
                        className="cursor-pointer mx-2"
                        icon={fa.faTrash}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {(!showTable || !schema?.content_id) &&
          contents
            .filter(
              (c) =>
                (!schema?.content_id || c.schema_id === schema.content_id) &&
                (!schema?.template_id || c.template_id) &&
                (!searchValue ||
                  searchValue === "" ||
                  c.title?.toLowerCase().indexOf(searchValue.toLowerCase()) >
                    -1)
            )
            .map((content, i) => (
              <div
                className="w-full md:w-1/2 lg:w-1/3  xl:w-1/4 p-2 "
                key={content.content_id}
              >
                <Content
                  className={"bg-white w-full text-black  border-none "}
                  content={content}
                  templateContent={content}
                  deleteContent={deleteContent}
                  duplicateContent={duplicateContent}
                  isEditable={true}
                  schema={schemas.find(
                    (s) => s.content_id === content.schema_id
                  )}
                  displayTemplate_id={  schemas.find(
                    (s) => s.content_id === content.schema_id
                  )?.content.display_template_id   }
                />
              </div>
            ))}
      </div>

      {publicContents && (
        <div className="w-full my-2 flex flex-wrap py-2  rounded-xl ">
          {publicContents
            .filter(
              (c) =>
                !searchValue ||
                searchValue === "" ||
                c.title?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1
            )
            .map((content, i) => (
              <div
                className="w-full md:w-1/2 lg:w-1/3  xl:w-1/4 p-2 "
                key={content.content_id}
              >
                <Content
                  className={"bg-white w-full text-black  border-none "}
                  content={content}
                  duplicateContent={createFromContent}
                  isEditable={false}
                  schema={schema}
                />
              </div>
            ))}
        </div>
      )}
    </div>
  );
}

export default AppContents;
