import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { MfctContext } from "../../context";
import InputSelect from "./InputSelect";
import { data } from "../../service/data";
import VarContent from "../VarContent";
import { optionsArgDispo } from "../../tools/tpl";
import SelectDropDown from "./SelectDropDown";



const InputLink = ({id,label,onChange,value,className,domain,context}) => {

const [options,setOptions]=useState([])


useEffect(()=>{
const init= async()=>{
    console.log("init Pages " +domain)
    var app = await data.getAppByDomain(domain ?? context?.app_id)
    console.log("init Pages " ,app )
    var _pages =await   data.getPages( (page)=>parseInt(page.app_id)===parseInt( app.app_id) ) 
   
console.log("Pages ",  _pages)
var links= []
_pages.forEach(_page => {
 var slug ="/" +(app.profile.homepage_id+""===""+ _page.page_id ?"":_page.slug  )
    links.push({label: _page.title  + `(${slug})`,value:slug , page:_page})
     
    _page.content?.body?.forEach(c=>    {
      if(c.label ) {
        links.push({label: _page.title +" #" + c.label,value:slug +"#" + c.label ,page:_page})
      }
    })
  
});

setOptions(links)
}
if(domain || context?.app_id) init()
},[domain,context?.app_id])
   



const valueLink = useMemo(()=>{

if(!value) return;
return options.find(o=>{ 
  
  return  value.replace(/{[^}]+}/g, "_").replace(/:\w+/g, "_")=== o.value.replace(/:\w+/g, "_");


})?.value

},[value,options])

const argValues = useMemo(()=>{

  if(!value) return {};

if(!valueLink) return {}
var parameterizedLink=value
  const genericParts = valueLink.split("/");
  const parameterizedParts = parameterizedLink.split("/");
  const params = {};

  // Parcourir chaque segment des liens
  genericParts.forEach((part, index) => {
    if (part.startsWith(":")) {
      // Si c'est un paramètre dynamique (ex : ":id")
      const paramName = part.substring(1); // Retirer le ":"
      params[paramName] = parameterizedParts[index]?.replace(/[{}]/g, ""); // Supprimer les accolades si présentes
    }
  });

  return params;

  
  },[value,valueLink])

const setArgValue=(Argument,argv)=>{
console.log({Argument,argv})
var params = argValues;
if(params[Argument.key.substring(1)] === argv.value)
  delete params[Argument.key.substring(1)]
  else
params[Argument.key.substring(1)] = argv.value
console.log({params ,argv})
console.log(argv)
console.log("OOOOOOOOOOUU")
 var changeValue = valueLink.replace(/:([a-zA-Z_]\w*)/g, (_, key) => {
      if (params[key] !== undefined) {
        return `{${params[key] }}`; // Remplace le paramètre par sa valeur entourée d'accolades
      }
     return ":" +key
    });
  if(changeValue) onChange(changeValue)
}



const optionsArgDispo=(Argument)=>{

  var options = []
  if(context?.template){
  
  if(context?.template.schema_id && context.schemas)
  {
    if(Argument.schema_id+""===42) options.push({value:"Titre"})
    if(Argument.schema_id+""===""+context?.template.schema_id) options.push({value:"self"})
  
       var schema = context.schemas.find(s=>s.content_id+""===""+ context?.template.schema_id)
       if(schema)
      {
    
  schema.content.schema?.filter(p=>p.schema_id+""===""+Argument.schema_id  && !Argument.type_nombre===!p.type_nombre   ).forEach(p=>  options.push({value:p.name }) )
      }
  
  }
  
   context?.template?.props.filter( p=>p.from!=="schema_suid" && p.schema_id+""===""+Argument.schema_id && !Argument.type_nombre===!p.type_nombre  )
   .forEach(p=>  options.push({value:p.name }) )
  
  }
  return options
  }



const params =useMemo(()=>{
if(valueLink && options)
{
 var oparams=  options.find(o=> o.value===valueLink)?.page?.content.params ?? {}

return Object.keys(oparams).map(k=>({ key:k,...oparams[k], schema_id: oparams[k].schema.content_id  }))

}
return 
},[
  valueLink,options
])


//optionsArgDispo({name:param.name },context,[])


return (
    <>{value}
    <InputSelect id={id} label={label} onChange={onChange} value={valueLink}  className={className} options={options} placeholder={"- Choisir un lien-"} />


{  params  &&   params.map(arg=> 
 <SelectDropDown 
             id={arg.name + "_" + id}
             key={arg.name}
             label={arg.name  }
             value={ argValues?  argValues[arg.key.substring(1) ]  :null  }
             options= {
              optionsArgDispo( arg)

             }
             fieldId={"value"}
             fieldSearch="value"
             onChange={(argValue) => setArgValue(arg,argValue) 
            

            }
            placeholder={"... "+ arg.name}

            />
   )  }

</> 
)
}
export default InputLink



