function Map() {
  return (
    <>
      <div
        className={
          " flex flex-row items-center justify-center fixed top-0 left-0 z-30 bg-black w-screen h-screen transition-all  duration-500  bg-opacity-50 "
        }
      >
   Map
      </div>
    </>
  );

}

export default Map;
