import React, { useMemo, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";

const ContextToolSpacing = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app}) => {
  
  const categories = [
   
    {
      name: "Padding",
      pref: "p-",
      classes: [
        "p-0", "p-1", "p-2", "p-3", "p-4", "p-5", "p-6", "p-7", "p-8", "p-9", "p-10",
        "p-12", "p-16", "p-20", "p-24", "p-32", "p-40", "p-48", "p-56", "p-64",
        "p-px",
      ],
    },
    {
      name: "Padding X",
      subname:"X",
      pref: "px-",
      spref: "p-",
      classes: [
        "px-0", "px-1", "px-2", "px-3", "px-4", "px-5", "px-6", "px-7", "px-8", "px-9", "px-10",
        "px-12", "px-16", "px-20", "px-24", "px-32", "px-40", "px-48", "px-56", "px-64",
        "px-px",
      ],
    },
    {
      name: "Padding Y",
      subname:"Y",
      pref: "py-",
      spref: "p-",
      classes: [
        "py-0", "py-1", "py-2", "py-3", "py-4", "py-5", "py-6", "py-7", "py-8", "py-9", "py-10",
        "py-12", "py-16", "py-20", "py-24", "py-32", "py-40", "py-48", "py-56", "py-64",
        "py-px",
      ],
    },
    {
      name: "Padding Top",
      subname:"T",
      pref: "pt-",
      spref: "p-",
      classes: [
        "pt-0", "pt-1", "pt-2", "pt-3", "pt-4", "pt-5", "pt-6", "pt-7", "pt-8", "pt-9", "pt-10",
        "pt-12", "pt-16", "pt-20", "pt-24", "pt-32", "pt-40", "pt-48", "pt-56", "pt-64",
        "pt-px",
      ],
    },
    {
      name: "Padding Right",
      subname:"R",
      pref: "pr-",
      spref: "p-",
      classes: [
        "pr-0", "pr-1", "pr-2", "pr-3", "pr-4", "pr-5", "pr-6", "pr-7", "pr-8", "pr-9", "pr-10",
        "pr-12", "pr-16", "pr-20", "pr-24", "pr-32", "pr-40", "pr-48", "pr-56", "pr-64",
        "pr-px",
      ],
    },
    {
      name: "Padding Bottom",
      subname:"B",
      pref: "pb-",
      spref: "p-",
      classes: [
        "pb-0", "pb-1", "pb-2", "pb-3", "pb-4", "pb-5", "pb-6", "pb-7", "pb-8", "pb-9", "pb-10",
        "pb-12", "pb-16", "pb-20", "pb-24", "pb-32", "pb-40", "pb-48", "pb-56", "pb-64",
        "pb-px",
      ],
    },
    {
      name: "Padding Left",
      subname:"L",
      pref: "pl-",
      spref: "p-",
      classes: [
        "pl-0", "pl-1", "pl-2", "pl-3", "pl-4", "pl-5", "pl-6", "pl-7", "pl-8", "pl-9", "pl-10",
        "pl-12", "pl-16", "pl-20", "pl-24", "pl-32", "pl-40", "pl-48", "pl-56", "pl-64",
        "pl-px",
      ],
    },
    {
      name: "Margin",
      pref: "m-",
      classes: [
        "m-0", "m-1", "m-2", "m-3", "m-4", "m-5", "m-6", "m-7", "m-8", "m-9", "m-10",
        "m-12", "m-16", "m-20", "m-24", "m-32", "m-40", "m-48", "m-56", "m-64",
        "m-px", "m-auto", "-m-1", "-m-2", "-m-3", "-m-4", "-m-5", "-m-6", "-m-7",
        "-m-8", "-m-9", "-m-10",
      ],
    },
    {
      name: "Margin X",
      subname:"X",
      pref: "mx-",
      spref: "m-",
      classes: [
        "mx-0", "mx-1", "mx-2", "mx-3", "mx-4", "mx-5", "mx-6", "mx-7", "mx-8", "mx-9", "mx-10",
        "mx-12", "mx-16", "mx-20", "mx-24", "mx-32", "mx-40", "mx-48", "mx-56", "mx-64",
        "mx-px", "mx-auto", "-mx-1", "-mx-2", "-mx-3", "-mx-4", "-mx-5", "-mx-6", "-mx-7",
        "-mx-8", "-mx-9", "-mx-10",
      ],
    },
    {
      name: "Margin Y",
      subname:"Y",
      pref: "my-",
      spref: "m-",
      classes: [
        "my-0", "my-1", "my-2", "my-3", "my-4", "my-5", "my-6", "my-7", "my-8", "my-9", "my-10",
        "my-12", "my-16", "my-20", "my-24", "my-32", "my-40", "my-48", "my-56", "my-64",
        "my-px", "my-auto", "-my-1", "-my-2", "-my-3", "-my-4", "-my-5", "-my-6", "-my-7",
        "-my-8", "-my-9", "-my-10",
      ],
    },
    {
      name: "Margin Top",
      subname:"T",
      pref: "mt-",
      spref: "m-",
      classes: [
        "mt-0", "mt-1", "mt-2", "mt-3", "mt-4", "mt-5", "mt-6", "mt-7", "mt-8", "mt-9", "mt-10",
        "mt-12", "mt-16", "mt-20", "mt-24", "mt-32", "mt-40", "mt-48", "mt-56", "mt-64",
        "mt-px", "-mt-1", "-mt-2", "-mt-3", "-mt-4", "-mt-5", "-mt-6", "-mt-7", "-mt-8", "-mt-9", "-mt-10",
      ],
    },
    {
      name: "Margin Right",
      subname:"R",
      pref: "mr-",
      spref: "m-",
      classes: [
        "mr-0", "mr-1", "mr-2", "mr-3", "mr-4", "mr-5", "mr-6", "mr-7", "mr-8", "mr-9", "mr-10",
        "mr-12", "mr-16", "mr-20", "mr-24", "mr-32", "mr-40", "mr-48", "mr-56", "mr-64",
        "mr-px", "-mr-1", "-mr-2", "-mr-3", "-mr-4", "-mr-5", "-mr-6", "-mr-7", "-mr-8", "-mr-9", "-mr-10",
      ],
    },
    {
      name: "Margin Bottom",
      subname:"B",
      pref: "mb-",
      spref: "m-",
      classes: [
        "mb-0", "mb-1", "mb-2", "mb-3", "mb-4", "mb-5", "mb-6", "mb-7", "mb-8", "mb-9", "mb-10",
        "mb-12", "mb-16", "mb-20", "mb-24", "mb-32", "mb-40", "mb-48", "mb-56", "mb-64",
        "mb-px", "-mb-1", "-mb-2", "-mb-3", "-mb-4", "-mb-5", "-mb-6", "-mb-7", "-mb-8", "-mb-9", "-mb-10",
      ],
    },
    {
      name: "Margin Left",
      subname:"L",
      pref: "ml-",
      spref: "m-",
      classes: [
        "ml-0", "ml-1", "ml-2", "ml-3", "ml-4", "ml-5", "ml-6", "ml-7", "ml-8", "ml-9", "ml-10",
        "ml-12", "ml-16", "ml-20", "ml-24", "ml-32", "ml-40", "ml-48", "ml-56", "ml-64",
        "ml-px", "-ml-1", "-ml-2", "-ml-3", "-ml-4", "-ml-5", "-ml-6", "-ml-7", "-ml-8", "-ml-9", "-ml-10",
      ],
    },
    {
      name: "Rounded",
      pref: "rounded-",
      classes: [
        "rounded-none", "rounded-sm", "rounded", "rounded-md", "rounded-lg", "rounded-xl", "rounded-2xl", "rounded-3xl", "rounded-full"
      ]},
        {
          name: "Top Left",
          subname: "TL",
          pref: "rounded-tl-",
          classes: [
            "rounded-tl-none", "rounded-tl-sm", "rounded-tl", "rounded-tl-md", "rounded-tl-lg", "rounded-tl-xl", "rounded-tl-2xl", "rounded-tl-3xl", "rounded-tl-full"
          ],
        },
        {
          name: "Top Right",
          subname: "TR",
          pref: "rounded-tr-",
          classes: [
            "rounded-tr-none", "rounded-tr-sm", "rounded-tr", "rounded-tr-md", "rounded-tr-lg", "rounded-tr-xl", "rounded-tr-2xl", "rounded-tr-3xl", "rounded-tr-full"
          ],
        },
        {
          name: "Bottom Left",
          subname: "BL",
          pref: "rounded-bl-",
          classes: [
            "rounded-bl-none", "rounded-bl-sm", "rounded-bl", "rounded-bl-md", "rounded-bl-lg", "rounded-bl-xl", "rounded-bl-2xl", "rounded-bl-3xl", "rounded-bl-full"
          ],
        },
        {
          name: "Bottom Right",
          subname: "BR",
          pref: "rounded-br-",
          classes: [
            "rounded-br-none", "rounded-br-sm", "rounded-br", "rounded-br-md", "rounded-br-lg", "rounded-br-xl", "rounded-br-2xl", "rounded-br-3xl", "rounded-br-full"
          ],
        },
        {
          name: "Top",
          subname: "T",
          pref: "rounded-t-",
          classes: [
            "rounded-t-none", "rounded-t-sm", "rounded-t", "rounded-t-md", "rounded-t-lg", "rounded-t-xl", "rounded-t-2xl", "rounded-t-3xl", "rounded-t-full"
          ],
        },
        {
          name: "Right",
          subname: "R",
          pref: "rounded-r-",
          classes: [
            "rounded-r-none", "rounded-r-sm", "rounded-r", "rounded-r-md", "rounded-r-lg", "rounded-r-xl", "rounded-r-2xl", "rounded-r-3xl", "rounded-r-full"
          ],
        },
        {
          name: "Bottom",
          subname: "B",
          pref: "rounded-b-",
          classes: [
            "rounded-b-none", "rounded-b-sm", "rounded-b", "rounded-b-md", "rounded-b-lg", "rounded-b-xl", "rounded-b-2xl", "rounded-b-3xl", "rounded-b-full"
          ],
        },
        {
          name: "Left",
          subname: "L",
          pref: "rounded-l-",
          classes: [
            "rounded-l-none", "rounded-l-sm", "rounded-l", "rounded-l-md", "rounded-l-lg", "rounded-l-xl", "rounded-l-2xl", "rounded-l-3xl", "rounded-l-full"
          ],
        }
      
    
  ];
  


 
    const toggleClasse =(pref,classe)=>{
      var className=""
      var parts = (structure.className+"").split(" ")
    
    
    if( parts.includes(classe ) )
    {
       parts = parts.filter(c=>c!==  classe  )
    }
    else 
    {

if((!categories.find(cat=>cat.name===pref).notExlusive))         parts =   parts.filter(c=>  !categories.find(cat=>cat.name===pref).classes.find(_c=> c=== _c)  )
    parts.push( classe)
    
    }
    className =parts.join(" ")
    updateTemplateStructure( {...structure,className},selected)
    }
  
        const [activeCategory, setActiveCategory] = useState(categories[0].name);

const prefActive = useMemo(()=>{
  var cat =categories.find(catsub=> catsub.name===activeCategory)
  return cat.spref ?? cat.pref

},[activeCategory,categories])

      if (!selected) return <></>;
      return (
        <div
          id={"ShowTemplateStructure"}
          className="bg-zinc-900 text-white p-2 rounded-xl m-2 flex-inline flex-col items-center justify-center  border-white shadow text-xs  overflow-y-auto"
        >
           {/* Onglets */}
           <div className="flex gap-2 mb-4">
        {categories.filter(c=>!c.subname ||   (c.spref ?? c.pref).startsWith(
          categories.find(cat=>(!cat.subname && cat.name===activeCategory)
    || (  prefActive.startsWith( cat.pref)  )
    || (  prefActive===cat.pref)  

        )?.pref)  ).map((category) => (
          <div
            key={category.name}
            onClick={() => setActiveCategory(category.name)}
            className={(category.subname ? 'px-2 ': 'ml-4 px-4 '     )+ `  cursor-pointer  py-2 rounded ${
              activeCategory === category.name
                ? "bg-green-700 text-white "
                : (category.subname ? "bg-zinc-800 text-gray-300":"bg-zinc-700 text-gray-300" )
            }`}
          >
            {category.subname??  category.name}
          </div>
        ))}
      </div>
    {  categories.filter((category) => category.name === activeCategory).map( pref=><div  key={pref.name}
    className="w-full flex-row flex p-1 border-t border-zinc-700 gap-1 items-center"
    >
    
    {pref.classes.map(classe=><div key={classe}   onClick={()=>toggleClasse(pref.name,classe)}  className={"rounded-full  h-8 p-1 px-2 flex items-center " + (  (structure.className+"").split(" ").includes(classe )  ?"bg-green-700 text-white  border border-white":"bg-zinc-600 text-black")} > {   pref.pref ? classe.replace(pref.pref,""): classe} </div>)}
    

    
    
    </div>)  }
          
    <div className="w-full flex flex-wrap p-2 bg-zinc-800 rounded-lg text-xs mb-2">

  {categories.flatMap(category => category.classes.filter(cls => (structure.className || "").split(" ").includes(cls)).map(cls=>({classe:cls,pref:category.name})) )
    .map(activeClass => (
      <span 
      onClick={()=>toggleClasse(activeClass.pref, activeClass.classe)} 
        key={activeClass.classe}
        className="bg-green-700 text-white rounded-full px-2 py-1 mr-2 mb-1"
      >
        {activeClass.classe}
      </span>
    ))}
</div>
        </div>
      );
    };


export default ContextToolSpacing;
