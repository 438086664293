const cleanClassesName = (className) => {
    // Catégories de classes Tailwind généralisées par préfixe ou règle
    const classCategories = {
        display: ['hidden', 'block', 'inline-block', 'flex', 'inline-flex', 'grid', 'inline-grid', 'table', 'inline-table'],
        width: /^w-/,
        height: /^h-/,
        textColor: /^text-(?!xs|sm|base|lg|xl|2xl|3xl|4xl|5xl|6xl)/, // Exclut les tailles
        textSize: /^text-(xs|sm|base|lg|xl|2xl|3xl|4xl|5xl|6xl)/, // Captures uniquement les tailles
        backgroundColor: /^bg-/,
        borderColor: /^border-(?![trbl]-)/, // Capture `border-*` sauf `border-t-`, `border-r-`, etc.
        borderWidth: /^border(-\d+)?$/, // Capture `border`, `border-2`, etc.
        borderSpecific: /^border-[trbl]-/, // Pour `border-t-*`, `border-b-*`, etc.
        position: ['static', 'relative', 'absolute', 'fixed', 'sticky'],
        visibility: ['visible', 'invisible'],
        alignItems: ['items-start', 'items-center', 'items-end', 'items-baseline', 'items-stretch'],
        justifyContent: ['justify-start', 'justify-center', 'justify-end', 'justify-between', 'justify-around', 'justify-evenly'],
        flexWrap: ['flex-wrap', 'flex-nowrap', 'flex-wrap-reverse'],
        flexDirection: ['flex-row', 'flex-row-reverse', 'flex-col', 'flex-col-reverse'],
        mediaQueries: /^(sm:|md:|lg:|xl:|2xl:)/, // Capture les préfixes media queries
        gap: /^gap-/,
        padding: /^p-/,
        margin: /^m-/,
        rounded: /^rounded(-[trbl]?)?/, // Capture `rounded`, `rounded-t`, etc.
        shadow: /^shadow(-[a-z]+)?/, // Capture `shadow`, `shadow-lg`, etc.
        zIndex: /^z-/,
        opacity: /^opacity-/,
        overflow: /^overflow-/,
        aspectRatio: /^aspect-/, // Ajouté pour les classes `aspect-*`
        gridTemplate: /^grid-cols-/,
        // Ajoutez d'autres catégories au besoin
    };

    // Fonction pour extraire les classes d'une catégorie
    const extractClasses = (classes, rule) => {
        if (Array.isArray(rule)) {
            return classes.filter(cls => rule.includes(cls));
        } else if (rule instanceof RegExp) {
            return classes.filter(cls => rule.test(cls));
        }
        return [];
    };

    // Gérer les media queries séparément
    const processMediaQueries = (classes) => {
        const mediaQueryClasses = {};

        classes.forEach(cls => {
            const match = cls.match(/^(sm:|md:|lg:|xl:|2xl:)/);
            if (match) {
                const prefix = match[0];
                const baseClass = cls.replace(prefix, '');

                // Traiter les classes à l'intérieur de chaque media query
                if (!mediaQueryClasses[prefix]) {
                    mediaQueryClasses[prefix] = [];
                }
                mediaQueryClasses[prefix].push(baseClass);
            }
        });

        // Nettoyer les classes dans chaque media query
        for (const prefix in mediaQueryClasses) {
            mediaQueryClasses[prefix] = cleanClassesName(mediaQueryClasses[prefix].join(' ')).split(' ');
        }

        // Reconstruire les classes avec les prefixes media queries
        return Object.entries(mediaQueryClasses)
            .flatMap(([prefix, baseClasses]) => baseClasses.map(cls => `${prefix}${cls}`));
    };

    // Diviser la chaîne en tableau
    const classArray = className.split(' ');

    // Séparer les media query classes et les classes normales
    const mediaQueryClasses = classArray.filter(cls => classCategories.mediaQueries.test(cls));
    const normalClasses = classArray.filter(cls => !classCategories.mediaQueries.test(cls));

    // Traiter les classes normales
    const uniqueClasses = new Set();

    for (const category in classCategories) {
        if (category === 'mediaQueries') continue; // On traite les media queries séparément

        const rule = classCategories[category];
        const classesInCategory = extractClasses(normalClasses, rule);

        if (classesInCategory.length > 0) {
            uniqueClasses.add(classesInCategory[classesInCategory.length - 1]); // Garder la dernière classe valide
        }
    }

    // Ajouter les classes non catégorisées
    normalClasses.forEach(cls => {
        const isInCategory = Object.values(classCategories).some(rule =>
            Array.isArray(rule) ? rule.includes(cls) : rule instanceof RegExp && rule.test(cls)
        );
        if (!isInCategory) {
            uniqueClasses.add(cls);
        }
    });

    // Traiter les media query classes et les ajouter au résultat
    const cleanedMediaQueryClasses = processMediaQueries(mediaQueryClasses);

    // Combiner les classes normales et media queries, et retourner
    return Array.from(uniqueClasses).concat(cleanedMediaQueryClasses).join(' ');
};

export {
   cleanClassesName
}
