import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";

const ContextToolLayout = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app}) => {
  

  const categories = [
    // Display
    {
      name: "Display",
      pref: "display-",
      classes: [
        "block",
        "inline-block",
        "inline",
        "flex",
        "inline-flex",
        "grid",
        "inline-grid",
        "table",
        "inline-table",
        "table-caption",
        "table-cell",
        "table-column",
        "table-column-group",
        "table-footer-group",
        "table-header-group",
        "table-row-group",
        "table-row",
        "hidden",
      ],
    },
  
    // Flexbox
    {
      name: "Flex Direction",
      pref: "flex-",
      classes: ["flex-row", "flex-row-reverse", "flex-col", "flex-col-reverse"],
    },
    {
      name: "Flex Wrap",
      pref: "flex-",
      classes: ["flex-wrap", "flex-wrap-reverse", "flex-nowrap"],
    },
    {
      name: "Flex",
      pref: "flex-",
      classes: ["flex-1", "flex-auto", "flex-initial", "flex-none"],
    },
    {
      name: "Justify Content",
      pref: "justify-",
      classes: [
        "justify-start",
        "justify-end",
        "justify-center",
        "justify-between",
        "justify-around",
        "justify-evenly",
      ],
    },
    {
      name: "Align Items",
      pref: "items-",
      classes: [
        "items-start",
        "items-end",
        "items-center",
        "items-baseline",
        "items-stretch",
      ],
    },
    {
      name: "Align Content",
      pref: "content-",
      classes: [
        "content-start",
        "content-end",
        "content-center",
        "content-between",
        "content-around",
        "content-evenly",
      ],
    },
    {
      name: "Align Self",
      pref: "self-",
      classes: [
        "self-auto",
        "self-start",
        "self-end",
        "self-center",
        "self-stretch",
      ],
    },
    {
      name: "Order",
      pref: "order-",
      classes: [
        "order-first",
        "order-last",
        "order-none",
        "order-1",
        "order-2",
        "order-3",
        "order-4",
        "order-5",
        "order-6",
        "order-7",
        "order-8",
        "order-9",
        "order-10",
        "order-11",
        "order-12",
      ],
    },
  
    // Grid
    {
      name: "Grid Template Columns",
      pref: "grid-cols-",
      classes: [
        "grid-cols-1",
        "grid-cols-2",
        "grid-cols-3",
        "grid-cols-4",
        "grid-cols-5",
        "grid-cols-6",
        "grid-cols-7",
        "grid-cols-8",
        "grid-cols-9",
        "grid-cols-10",
        "grid-cols-11",
        "grid-cols-12",
        "grid-cols-none",
      ],
    },
    {
      name: "Grid Template Rows",
      pref: "grid-rows-",
      classes: [
        "grid-rows-1",
        "grid-rows-2",
        "grid-rows-3",
        "grid-rows-4",
        "grid-rows-5",
        "grid-rows-6",
        "grid-rows-none",
      ],
    },
    {
      name: "Gap",
      pref: "gap-",
      classes: [
        "gap-0",
        "gap-1",
        "gap-2",
        "gap-3",
        "gap-4",
        "gap-5",
        "gap-6",
        "gap-7",
        "gap-8",
        "gap-9",
        "gap-10",
        "gap-12",
        "gap-16",
        "gap-20",
        "gap-24",
        "gap-32",
        "gap-40",
        "gap-48",
        "gap-56",
        "gap-64",
        "gap-px",
      ],
    },
    {
      name: "Grid Auto Flow",
      pref: "grid-flow-",
      classes: [
        "grid-flow-row",
        "grid-flow-col",
        "grid-flow-row-dense",
        "grid-flow-col-dense",
      ],
    },
    {
      name: "Grid Auto Columns",
      pref: "auto-cols-",
      classes: [
        "auto-cols-auto",
        "auto-cols-min",
        "auto-cols-max",
        "auto-cols-fr",
      ],
    },
    {
      name: "Grid Auto Rows",
      pref: "auto-rows-",
      classes: ["auto-rows-auto", "auto-rows-min", "auto-rows-max", "auto-rows-fr"],
    },
  
    // Position
   
  ];
  

 
  const toggleClasse =(pref,classe)=>{
    var className=""
    var parts = (structure.className+"").split(" ")
  
  
  if( parts.includes(classe ) )
  {
     parts = parts.filter(c=>c!==  classe  )
  }
  else 
  {

if((!categories.find(cat=>cat.name===pref).notExlusive))         parts =   parts.filter(c=>  !categories.find(cat=>cat.name===pref).classes.find(_c=> c=== _c)  )
  parts.push( classe)
  
  }
  className =parts.join(" ")
  updateTemplateStructure( {...structure,className},selected)
  }

      const [activeCategory, setActiveCategory] = useState(categories[0].name);
    if (!selected) return <></>;
    return (
      <div
        id={"ShowTemplateStructure"}
        className="bg-zinc-900 text-white p-2 rounded-xl m-2 flex-inline flex-col items-center justify-center  border-white shadow text-xs  overflow-x-auto  relative"
        style={{ maxWidth:"50vw"  }}
      >
         {/* Onglets */}
         <div className="flex gap-2 mb-4">
      {categories.map((category) => (
        <div
          key={category.name}
          onClick={() => setActiveCategory(category.name)}
          className={(category.subname ? 'px-2 ': 'ml-4 px-4 '     )+ `  cursor-pointer  py-2 rounded ${
            activeCategory === category.name
              ? "bg-green-700 text-white "
              : (category.subname ? "bg-zinc-800 text-gray-300":"bg-zinc-700 text-gray-300" )
          }`}
        >
          {category.subname??  category.name}
        </div>
      ))}
    </div>
  {  categories.filter((category) => category.name === activeCategory).map( pref=><div  key={pref.name}
  className="flex p-1 border-t border-zinc-700 gap-1 items-center"
  >
  
  {pref.classes.map(classe=><div key={classe}   onClick={()=>toggleClasse(pref.name,classe)}  className={"rounded-full  h-8 p-1 px-2 flex items-center " + (  (structure.className+"").split(" ").includes(classe )  ?"bg-green-700 text-white  border border-white":"bg-zinc-600 text-black")} > {   pref.pref ? classe.replace(pref.pref,""): classe} </div>)}
  

  
  
  </div>)  }
        
  <div className="flex flex-wrap p-2 bg-zinc-800 rounded-lg text-xs mb-2 sticky left-0">

{categories.flatMap(category => category.classes.filter(cls => (structure.className || "").split(" ").includes(cls)).map(cls=>({classe:cls,pref:category.name})) )
  .map(activeClass => (
    <span 
    onClick={()=>toggleClasse(activeClass.pref, activeClass.classe)} 
      key={activeClass.classe}
      className="bg-green-700 text-white rounded-full px-2 py-1 mr-2 mb-1"
    >
      {activeClass.classe}
    </span>
  ))}
</div>
      </div>
  );
};

export default ContextToolLayout;
