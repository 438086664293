import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";

const ContextToolData = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app}) => {
  




  if (!selected) return <></>;
  return (
    <div
      id={"ShowTemplateStructure"}
      className="bg-zinc-900 p-2 text-white rounded-xl  m-2 flex flex-col items-center justify-center  border-white shadow"
    >
{  JSON.stringify(template)  }
      <div className="p-2  w-full flex flex-col">
        {" "}

      </div>
     
    </div>
  );
};

export default ContextToolData;
