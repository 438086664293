import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { data } from "../../service/data";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputText from "./InputText";
import { getAvailableActions } from "../../tools/actions";
import { api } from "../../service/mftc-api";
import { showModalOK } from "../modal";
import InputData from "./InputData";
import { getRecord } from "../../service/localdata";
import VarContent from "../VarContent";
import SelectDropDown from "./SelectDropDown";


var _schemas = {};

const InputAction = ({
  label,
  onChange,
  value,
  className,
  placeholder,
  domain,
  filter,
  id,
  context,
}) => {
  const [actions, setActions] = useState();
const [schemas,setSchemas]=useState(_schemas);
const optionsArgDispo=(Argument)=>{

var options = []
if(context?.template){

if(context?.template.schema_id && schemas)
{
  if(Argument.schema_id+""===42) options.push({value:"Titre"})
  if(Argument.schema_id+""===""+context?.template.schema_id) options.push({value:"self"})

     var schema = context.schemas.find(s=>s.content_id+""===""+ context?.template.schema_id)
     if(schema)
    {
  
schema.content.schema?.filter(p=>p.schema_id+""===""+Argument.schema_id  && !Argument.type_nombre===!p.type_nombre   ).forEach(p=>  options.push({value:p.name }) )
    }

}

 context?.template?.props.filter( p=>p.from!=="schema_suid" && p.schema_id+""===""+Argument.schema_id && !Argument.type_nombre===!p.type_nombre  )
 .forEach(p=>  options.push({value:p.name }) )

}
return options
}
  const openAction = (action) => {
    window.open("/content/" + action.action_id, "_blank");
    // showModal( "Action ", {  Content:<ContentEdition   content_id={action.content_id} domain={domain  }  app_id={action.app_id}   />  }    )
  };

  const updateAction = async (action) => {
    await api("/update_action/:action_id", {
      args: { action_id: action.action_id },
    });

    var _action = actions.find((p) => p.content_id === action.action_id);

    var Arguments = {};
    var asynchrone;
    var Imports = [];
    if (_action) {
      action.Arguments = action.Arguments ?? {};

      _action.content.Arguments?.forEach((element) => {
        Arguments[element.name] = {
          ...(action.Arguments[element.name] ?? {}),
          ...element,
        };
      });
      asynchrone = _action.content.asynchrone;
      _action.content.Code.forEach((c) => {
        if (!c.back && c.import) Imports = [...Imports, ...c.import];
      });
    }
    action = {
      ...action,
      title: _action.title,
      Arguments,
      asynchrone,
      Imports,
    };
    window.alert(JSON.stringify(action));
    onChange(action);
    showModalOK();
  };

  useEffect(() => {
    const loadSchemas = async (schemas_to_load) => {
      for (let schema_id of schemas_to_load) {

        var _schema = await getRecord(parseInt(schema_id), "content");
      
        _schemas[schema_id] = _schema;
      }
      console.log({load:"errrr",_schemas})
     setSchemas({..._schemas}) 
    };

    if (value && value.Arguments) {
      var schemas_to_load = Object.keys(value.Arguments)
        ?.filter(
          (arg) =>
            value.Arguments[arg].is_noset_label &&
            !schemas[value.Arguments[arg].schema_id]
        )
        .map((arg) => value.Arguments[arg].schema_id);

      if (schemas_to_load.length > 0) loadSchemas(schemas_to_load);
    }
  }, [value]);

  useEffect(() => {
    const getActions = async () => {
      const _actions = await getAvailableActions(domain, filter);
      setActions(_actions);
    };

    getActions();
  }, [domain, filter]);

  if (!actions) return <></>;

  return (
    <>
      <div className="w-full flex gap-1 py-1 items-center">
        {" "}
      
        <InputSelect
          label={label}
          className={className + " w-full"}
          placeholder={placeholder}
          value={value?.action_id}
          onChange={async (action_id) => {
            var action;
            if (action_id === "new") {
              action = await data.save_content({
                title: "Action " + Date.now(),
                schema_id: 428,
                domain,
                content: { title: "Action " + Date.now() },
              });
              if (action) action_id = action.content_id;
              action.action_id = action.content_id;
              openAction(action);
            } else {
              if (action_id) action_id = parseInt(action_id);
              action =
                action_id && actions.find((p) => p.content_id === action_id);
            }
            var Args = {};
            var Arguments = {};
            var asynchrone;
            var Imports = [];
            if (action) {
              action.content.Args?.forEach((element) => {
                Args[element] = null;
              });
              action.content.Arguments?.forEach((element) => {
                Arguments[element.name] = element;
              });
              asynchrone = action.content.asynchrone;
              action.content.Code.forEach((c) => {
                if (!c.back && c.import) Imports = [...Imports, ...c.import];
              });
            }
            onChange(
              action_id
                ? {
                    action_id,
                    title: action.title,
                    auth:action.content.auth,
                    Args,
                    Arguments,
                    asynchrone,
                    Imports,
                  }
                : null
            );
          }}
          options={[
            { label: "--", value: null },
            ...actions.map((p) => {
              return { label: p.title, value: p.content_id };
            }),
            { label: "Créer Nouvelle Action", value: "new" },
          ]}
        />
        {value?.action_id && (
          <>
            <FontAwesomeIcon
              icon={faEdit}
              className="text-lg text-black"
              onClick={() => openAction(value)}
            />
            <FontAwesomeIcon
              icon={fa.faRotateRight}
              className="text-lg text-green-500"
              onClick={() => updateAction(value)}
            />
          </>
        )}
      </div>
      {value &&
        value.Arguments &&
        Object.keys(value.Arguments)
          ?.filter((arg) => value.Arguments[arg].is_noset_label && schemas[parseInt(value.Arguments[arg].schema_id)])
          .map((arg) => (

           <>

   
            
            <InputData
              id={arg + "_" + id}
              key={arg}
              prop={value.Arguments[arg]}
              schema={schemas[parseInt(value.Arguments[arg].schema_id)]}
              label={arg + "*"}
              value={value.Arguments[arg].value}
              onChange={(argValue) =>
                onChange({
                  ...value,
                  Arguments: {
                    ...value.Arguments,
                    [arg]: { ...value.Arguments[arg], value: argValue },
                  },
                })
              }
              domain={domain}
            /></>
          ))}
              
      {value &&
        value.Arguments &&
        Object.keys(value.Arguments)
          ?.filter((arg) =>   !value.Arguments[arg].is_noset_label)
          .map((arg) => (
            
           <> <SelectDropDown 
             id={arg + "_" + id}
             key={arg}
             label={arg + (value.Arguments[arg].type_put==="out"? "/ (Out only) ":"") }
             value={value.Arguments[arg].value}
             options= {
              optionsArgDispo( value.Arguments[arg])

             }
             fieldId={"value"}
             fieldSearch="value"
             onChange={(argValue) =>
              onChange({
                ...value,
                Arguments: {
                  ...value.Arguments,
                  [arg]: { ...value.Arguments[arg], value: argValue?.value },
                },
              })

            }
            placeholder={arg}

            /></>
          ))}
      
    </>
  );
};
export default InputAction;
