import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";

const ContextToolSize = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app}) => {
  

  const categories = [
    // Largeur (Width)
    {
      name: "Width",
      pref: "w-",
      classes: [
        "w-full",
        "w-0", "w-1", "w-2", "w-3", "w-4", "w-5", "w-6", "w-7", "w-8", "w-9", "w-10",
        "w-12", "w-16", "w-20", "w-24", "w-32", "w-40", "w-48", "w-56", "w-64", "w-72", 
        "w-80", "w-96", "w-auto", "w-screen", "w-min", "w-max",
        "w-1/2", "w-1/3", "w-2/3", "w-1/4", "w-3/4", "w-1/5", "w-2/5", "w-3/5", "w-4/5", 
        "w-1/6", "w-5/6", "w-1/12", "w-11/12"
      ],
    },
  
    // Hauteur (Height)
    {
      name: "Height",
      pref: "h-",
      classes: ["h-full",
        "h-auto", "h-0", "h-1", "h-2", "h-3", "h-4", "h-5", "h-6", "h-7", "h-8", "h-9", "h-10",
        "h-12", "h-16", "h-20", "h-24", "h-32", "h-40", "h-48", "h-56", "h-64", "h-72", "h-80", 
        "h-96",  "h-screen", "h-min", "h-max",
        "h-1/2", "h-1/3", "h-2/3", "h-1/4", "h-3/4", "h-1/5", "h-2/5", "h-3/5", "h-4/5", 
        "h-1/6", "h-5/6", "h-1/12", "h-11/12"
      ],
    },
    {
      name: "Position",
      pref: "",
      classes: ["static", "relative", "absolute", "fixed", "sticky"],
    },
    // Top
    {
      name: "Top",
      pref: "top-",
      classes: [
        "top-0", "top-1", "top-2", "top-3", "top-4", "top-5", "top-6", "top-7", "top-8", "top-9", 
        "top-10", "top-12", "top-16", "top-20", "top-24", "top-32", "top-40", "top-48", "top-56", 
        "top-64", "top-px", "-top-1", "-top-2", "-top-3", "-top-4", "-top-5", "-top-6", "-top-7", 
        "-top-8", "-top-9", "-top-10", "-top-12",
      ],
    },
  
    // Right
    {
      name: "Right",
      pref: "right-",
      classes: [
        "right-0", "right-1", "right-2", "right-3", "right-4", "right-5", "right-6", "right-7", 
        "right-8", "right-9", "right-10", "right-12", "right-16", "right-20", "right-24", "right-32", 
        "right-40", "right-48", "right-56", "right-64", "right-px", "-right-1", "-right-2", "-right-3", 
        "-right-4", "-right-5", "-right-6", "-right-7", "-right-8", "-right-9", "-right-10", "-right-12",
      ],
    },
  
    // Bottom
    {
      name: "Bottom",
      pref: "bottom-",
      classes: [
        "bottom-0", "bottom-1", "bottom-2", "bottom-3", "bottom-4", "bottom-5", "bottom-6", "bottom-7", 
        "bottom-8", "bottom-9", "bottom-10", "bottom-12", "bottom-16", "bottom-20", "bottom-24", "bottom-32", 
        "bottom-40", "bottom-48", "bottom-56", "bottom-64", "bottom-px", "-bottom-1", "-bottom-2", "-bottom-3", 
        "-bottom-4", "-bottom-5", "-bottom-6", "-bottom-7", "-bottom-8", "-bottom-9", "-bottom-10", "-bottom-12",
      ],
    },
  
    // Left
    {
      name: "Left",
      pref: "left-",
      classes: [
        "left-0", "left-1", "left-2", "left-3", "left-4", "left-5", "left-6", "left-7", "left-8", 
        "left-9", "left-10", "left-12", "left-16", "left-20", "left-24", "left-32", "left-40", "left-48", 
        "left-56", "left-64", "left-px", "-left-1", "-left-2", "-left-3", "-left-4", "-left-5", "-left-6", 
        "-left-7", "-left-8", "-left-9", "-left-10", "-left-12",
      ],
    },

    
    {
      name: "Z-Index",
      pref: "z-",
      classes: ["z-0", "z-10", "z-20", "z-30", "z-40", "z-50", "z-auto"],
    },
  ];
  


  const toggleClasse =(pref,classe)=>{
    var className=""
    var parts = (structure.className+"").split(" ")
  
  
  if( parts.includes(classe ) )
  {
     parts = parts.filter(c=>c!==  classe  )
  }
  else 
  {

if((!categories.find(cat=>cat.name===pref).notExlusive))         parts =   parts.filter(c=>  !categories.find(cat=>cat.name===pref).classes.find(_c=> c=== _c)  )
  parts.push( classe)
  
  }
  className =parts.join(" ")
  updateTemplateStructure( {...structure,className},selected)
  }

      const [activeCategory, setActiveCategory] = useState(categories[0].name);
    if (!selected) return <></>;
    return (
      <div
        id={"ShowTemplateStructure"}
        className="bg-zinc-900 text-white p-2 rounded-xl m-2 flex-inline flex-col items-center justify-center  border-white shadow text-xs relative  overflow-x-auto  "
        style={{ maxWidth:"50vw"  }}
      >
         {/* Onglets */}
         <div className="flex gap-2 mb-4 sticky left-0">
      {categories.map((category) => (
        <div
          key={category.name}
          onClick={() => setActiveCategory(category.name)}
          className={(category.subname ? 'px-2 ': 'px-4 '     )+ `  cursor-pointer  py-2 rounded ${
            activeCategory === category.name
              ? "bg-green-700 text-white "
              : (category.subname ? "bg-zinc-800 text-gray-300":"bg-zinc-700 text-gray-300" )
          }`}
        >
          {category.subname??  category.name}
        </div>
      ))}
    </div>
  {  categories.filter((category) => category.name === activeCategory).map( pref=><div  key={pref.name}
  className="w-full flex-row flex p-1 border-t border-zinc-700 gap-1 items-center"
  >
  
  {pref.classes.map(classe=><div key={classe}   onClick={()=>toggleClasse(pref.name,classe)}  className={"rounded-full  h-8 p-1 px-2 flex items-center " + (  (structure.className+"").split(" ").includes(classe )  ?"bg-green-700 text-white  border border-white":"bg-zinc-600 text-black")} > {   pref.pref ? classe.replace(pref.pref,""): classe} </div>)}
  

  
  
  </div>)  }
        
  <div className="w-full flex flex-wrap p-2 bg-zinc-800 rounded-lg text-xs mb-2 sticky left-0">

{categories.flatMap(category => category.classes.filter(cls => (structure.className || "").split(" ").includes(cls)).map(cls=>({classe:cls,pref:category.name})) )
  .map(activeClass => (
    <span 
    onClick={()=>toggleClasse(activeClass.pref, activeClass.classe)} 
      key={activeClass.classe}
      className="bg-green-700 text-white rounded-full px-2 py-1 mr-2 mb-1 "
    >
      {activeClass.classe}
    </span>
  ))}
</div>
      </div>
  );
};

export default ContextToolSize;
