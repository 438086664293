import React, { useEffect, useMemo, useState, useContext, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useInView } from 'react-intersection-observer';

import "../../../../App.css";
import { MfctContext } from "../../../../context";
import Content from "../../../../components/Content";

import { data } from "../../../../service/data";
import { forgetRessourceStone, setLoadedStone } from "../../../../service/milestone";

import { retrieveProject } from "../../../../service/mftc-api";
import { deleteRecord, setRecord } from "../../../../service/localdata";

function Milestones({ searchValue }) {
  const navigate = useNavigate();
  const [contents, setContents] = useState([]);
  const { project, getProject, setAuthUser, authUser, getGlobalData } =
    useContext(MfctContext);
  const [schema, setSchema] = useState();
  const [schemas, setSchemas] = useState([]);
  const [contentHeights, setContentHeights] = useState({});
const [templateContents,setTemplateContents]=useState([])
const [currentStone,setCurrentStone]=useState( localStorage.getItem("currentStone"))
  const getContents = async () => {

  };

  const createContent = async () => {
    var _content = await project.api("/set_content", {
      body: {
        content_id: null,
        app_id: 78,
        schema_id: 533,
        is_public: false,
        content: {},
      },
    });
    if (_content) {
      await getContents();
      navigate(`/content/${_content.content_id}`);
    }
  };

  const duplicateContent = async (content) => {
    var _content = await project.api("/duplicate_content/:content_id", {
      args: { content_id: content.content_id },
    });
    if (_content) navigate(`/content/${_content.content_id}`);
  };

  const deleteContent = async (content) => {
    if (
      !window.confirm("Are you sure you want to delete '" + content.name + "'?")
    )
      return;
    await project.api("/delete_content/:content_id", {
      args: { content_id: content.content_id },
    });
    await getContents();
  };


  useEffect(() => {
    

    var _currentStone = templateContents.find((Stone) => {
      if (!Stone || !Stone.WorkingStone) return null;
      var timenow = Date.now();

      var lastAction = Stone.WorkingStone.filter(
        (w) =>
          w.User === "user_" + authUser?.user_id &&
          w.Date > timenow - 1000 * 60 * 30
      ).pop()?.Action;
      return lastAction && lastAction !== "Stop";
    });

    if (_currentStone) {
      localStorage.setItem("currentStone", _currentStone?.content_id);
      setCurrentStone( _currentStone?.content_id)
      setLoadedStone(_currentStone);
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      localStorage.removeItem("currentStone");
      setCurrentStone( )
    }


  }, [templateContents, authUser]);

const setTemplateContent=async (value)=>{
if(!value) return
if(value.deleted){
  await  deleteRecord ( value.content_id,"content")
  setTemplateContents(prev=>prev.filter(c=>c.content_id+""!== value.content_id+""))
}
else{

var content = await data.getContent(value.content_id )
if( content ) {
  content.content= {...content.content,...value}
  await  setRecord(content,"content")
}

  setTemplateContents(prev=>prev.map(c=>c.content_id+""=== value.content_id+"" ? value:c ))
}
}

  const Stones = useMemo(() => {
    var stones = templateContents
    if(!templateContents) return []
    stones = stones.map(s=>{ 
      var updated_at 
     s.WorkingStone?.forEach(w => { if (!updated_at || w.Date>updated_at )updated_at =w.Date });
     return {...s,updated_at} 
      } )

    var _currentStone = stones.find((Stone) => {
      if (!Stone || !Stone.WorkingStone) return null;
      var timenow = Date.now();

      var lastAction = Stone.WorkingStone.filter(
        (w) =>
          w.User === "user_" + authUser?.user_id &&
          w.Date > timenow - 1000 * 60 * 30
      ).pop()?.Action;
      return lastAction && lastAction !== "Stop";
    });


    stones.sort((a, b) => {
      if (a.content_id === _currentStone?.content_id) return -1;

      if (b.content_id === _currentStone?.content_id) return 1;
      return a.updated_at > b.updated_at ? -1 : 1;
    });

    console.log("Refilter stones ", { stones });

    return stones;
  }, [templateContents, authUser]);





  const [currentWorkingStone,setCurrentWorkingStone]=useState()
useEffect( () => {
   
  const getCurrent= async()=>{
    if (!currentStone) {  setCurrentWorkingStone();  return  }

    var obj = await data.getContent( currentStone)
    if(!obj )  {  setCurrentWorkingStone();  return  } 
    var WorkingStone= obj.content.WorkingStone ?? [];

    var w = WorkingStone.filter(
      (w, i) =>
        w.Link &&
        i ===
          WorkingStone.findIndex(
            (w0) => JSON.stringify(w.Ressource) === JSON.stringify(w0.Ressource)
          )
    ).map((_w) => {
      var times = WorkingStone.filter(
        (w0) => JSON.stringify(_w.Ressource) === JSON.stringify(w0.Ressource)
      ).map((w0) => w0.Date);
      times.sort();
      _w.time = times.pop();
      return _w;
    });

    w.sort((a, b) => (a.time > b.time ? -1 : 1));
    setCurrentWorkingStone(w)
  }


  getCurrent()


  }, [ currentStone]);


  useEffect(()=>{

    const getTemplateContents = async()=>{
    
      var result =await  retrieveProject().api("/get_content_template",{
        body:{ template_id :448, where :` TRUE ` }
      })
   
     if(result && Array.isArray(result))  setTemplateContents(result)
    }
    
   getTemplateContents()
    
    
    }
    ,[])

  useEffect(() => {
    return () => {
      //window.alert("finito")
    //  console.log("Close")
    //  data.close("milestone", "1");
    };
  }, [project]);

const ContentView = ({content})=>{
 /* const { ref, inView } = useInView ({
    threshold: 0,
    triggerOnce: false,
    rootMargin: '100px 0px',
  });
*/
var ref=useRef()
var inView = true
  return (
    <div 
      ref={ref}
      className={"w-full p-2 "} 
   
      style={{ height: !inView ? `${contentHeights[content.content_id]}px` : 'auto' }}
    >
      {inView || true ? (

<>
{ templateContents?.find(t=>t.content_id+""=== ""+content.content_id) && 
        <Content
          className={
            "w-full text-black border-none " +
            (currentStone + "" === "" + content.content_id
              ? " bg-green-100"
              : " bg-white")
          }
          setContent={(value)=> setTemplateContent(value)}
          displayTemplate_id={448}
          content={content}
          templateContent={templateContents?.find(t=>t.content_id=== content.content_id)}
          deleteContent={deleteContent}
          duplicateContent={duplicateContent}
          isEditable={true}
          schema={schemas.find((s) => s.content_id +""=== ""+content.schema_id)}
          onLoad={(height) => {
           return 
            setContentHeights(prev => ({
              ...prev,
              [content.content_id]: height
            }));
          }}
        />}</>
      ) : (
        <div className="animate-pulse bg-gray-200 rounded-xl" 
             style={{ height: contentHeights[content.content_id] || '200px' }}
        />
      )}

      {currentStone + "" === "" + content.content_id && currentWorkingStone &&
        currentWorkingStone.map((w, i) => (
          <div
            className="w-full p-2  bg-green-100 rounded-xl text-black my-2  flex flex-col justify-between items-center"
            key={i}
          >
           <div className="w-full flex justify-between "> 
            <span> <span  className="font-bold"  >{  w.Ressource.entity ??  w.Ressource.schema }</span>  - {w.Ressource.title ?? JSON.stringify(w.Ressource)}</span>

            <span onClick={()=>forgetRessourceStone(w.Ressource,setTemplateContent)} className="appbuttton text-xs text-red-800">Oublier</span>
            </div>

            <div className="w-full pt-2 border-t border-black text-black my-2  flex flex-col  md:flex-row justify-start items-center gap-2">
              {content.WorkingStone.filter(
                (w0, i0) =>
                  w0.Link &&
                  JSON.stringify(w.Ressource) ===
                    JSON.stringify(w0.Ressource) &&
                  i0 ===
                    content.WorkingStone.findIndex(
                      (w1) =>
                        w1.Link === w0.Link &&
                        JSON.stringify(w0.Ressource) ===
                          JSON.stringify(w1.Ressource)
                    )
              ).map((Wlink, j) => (
                <Link className="w-1/2 md:w-auto" to={Wlink.Link} key={j}>
                  {" "}
                  <div
                    className={
                      "appbutton p-2 bg-green-500 rounded-full  text-white text-xs w-full "
                    }
                  >
                    {Wlink.Action}
                  </div>
                </Link>
              ))}{" "}
            </div>
          </div>
        ))}
    </div>
  );
}



  return (
    <div className="App w-full">
      <div className="w-full my-2 flex flex-col py-2 px-2 gap-2 rounded-xl ">
        {Stones.filter(
          (c) =>
            (!schema?.content_id || c.schema_id === schema.content_id) &&
            (!searchValue ||
              searchValue === "" ||
              c.title?.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        ).map((content, i) => <ContentView    key={content.content_id} content={content}/>)}
      </div>

      <div
        onClick={createContent}
        className="sticky bottom-4 text-white mx-2 bg-orange-700  p-1 px-3 rounded-full mb-6 appbutton"
      >
        <FontAwesomeIcon icon={fa.faPlusCircle} /> Créer {" Milestone AppisYou"}
      </div>
    </div>
  );
}

export default Milestones;
