import { useEffect, useState } from "react";

import InputSelect from "./InputSelect";
import { data } from "../../service/data";
import VarContent from "../VarContent";
import InputText from "./InputText";
import ModalSelect from "./ModalSelect";
import TemplateDisplay from "../templates/TemplateDisplay";



const TemplateContent = ({value})=><TemplateDisplay
template={value}
isEditable={false}
safeDisplay={true}
showLayout={false}
onClick={()=>{}}
/>


const InputTemplate = ({
  label,
  onChange,
  value,
  className,
  placeholder,
  domain,
  filter,
  context
}) => {
  const [templates, setTemplates] = useState();


  const [showTab, setShowTab] = useState()

  useEffect(() => {
    const getTemplates = async () => {
      const _templates = await data.getTemplates(filter,true,true);
      setTemplates(_templates);
    };

    getTemplates();
  }, [domain,filter]);

  if (!templates) return <></>;

  return (
    <>
      <InputText 

        label={label}
        className={className}
        placeholder={placeholder}
     value={ value ? templates.find(t=>t.template_id+""=== ""+( context?.onlyId ? value:value.template_id) )?.title  :null }

         onFocus={()=>setShowTab(true)}

      />
   {showTab && <><ModalSelect


value={context?.onlyId ?  templates.find(t=>t.template_id+""=== ""+value )  : value}
           onChange={(template) => {
          if(!template) {    onChange();return;}

         
           if(context?.onlyId){
             onChange(template.template_id)
             return
           }  
        
           onChange({ template_id:template.template_id, content_id: null, typecontent: "template" });
         }}

className={" p-4 m-2 w-full md:w-60 "}
fieldId={"template_id"}
fieldSearch={"title"}
label={"Template"}
options={   templates  }
   OptionRender={ TemplateContent   }
   callClose={()=>setShowTab()}

   /></>}
    </>
  );
};
export default InputTemplate;
