import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useEffect, useRef, useState } from "react"
import * as fa from "@fortawesome/free-solid-svg-icons";
import { icon } from "@fortawesome/fontawesome-svg-core";
import useDynamicTextColor from "../../tools/useDynamicTextColor";
import Icon from "../Icon";
import { retrieveProject } from "../../service/mftc-api";
import { useNavigate } from "react-router-dom";
import ReactDOM from 'react-dom';

var SVG_cache 

const InputIcon = ({ label, onChange, value, className, options, placeholder }) => {



    const navigate= useNavigate()
    const elementRef = useRef(null);
    const {textColor,backgroundColor } = useDynamicTextColor(elementRef);
    const [showTab, setShowTab] = useState()
    const [searchText, setSearchText] = useState()

    const textRef = useRef()
    const [svgIcons,setSvgIcons]=useState()
useEffect(()=>{

const getSVG = async()=>{

     var result = await retrieveProject().api("/get_content_where",{body:{
        schema_id:63
     }})
   
   

     var resultA = await retrieveProject().api("/get_content_where",{body:{
        schema_id:992
     }})
     SVG_cache=[...result,...resultA];
     console.log("Result trouvés",SVG_cache )
     setSvgIcons(SVG_cache)



}
if( showTab)
{
    if(SVG_cache) { 
    setSvgIcons(SVG_cache);
 
} else getSVG()
}
},[showTab])



    return (
        <>
            <div   className={"relative flex gap-2  " + className} >

                <div id={"input_" + label} className="flex px-2.5 pb-2.5 pt-4 grow text-sm  bg-transparent rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" >

                    <Icon  onClick={() => setShowTab(true)}  icon={value && (value.startsWith("svg:") || value.startsWith("svga:")) ? value :  fa[value] ?? fa.faQuestion} className={"appbutton h-8 grow " + ( fa[value] ? "":"text-orange-500")}/>
                    <span className="text-xs">{value}</span>
               
                    { value ? <FontAwesomeIcon className=" text-red-600 p-2" icon={fa.faClose}  onClick={()=>onChange(null)   }  /> :
 <FontAwesomeIcon className="opacity-0 p-2" icon={fa.faClose}     />    }
  </div>
                { (value && (value.startsWith("svg:") || value.startsWith("svga:")))   && <FontAwesomeIcon  className="h-6 cursor-pointer" icon={fa.faDrawPolygon }  onClick={  ()=>  window.open(`${window.location.origin}/content/${value.split(":")[1]}`, "_blank", "noopener,noreferrer") }   />}
                <label htmlFor={"input_" + label} className="absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1">{label}</label>
            </div>
            {showTab &&
         
         
            ReactDOM.createPortal(   
         
         
         <>
                    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex flex-col justify-center items-center  " onClick={() => { setShowTab(false) }} >
                    </div>
                    <div className="fixed top-0 left-0 w-screen h-screen bg-black bg-opacity-40 flex flex-col justify-center items-center z-50 " >

                        <FontAwesomeIcon icon={fa.faClose} className="-ml-8 z-10 text-2xl text-white absolute right-5 top-5" onClick={() => { setShowTab() }} />

                        <div   className="flex flex-row w-9/12 p-2 items-center justify-center">
                            <FontAwesomeIcon icon={fa.faSearch} className="-mr-8 z-10  " />
                            <input type='text' ref={textRef} onChange={(e) => setSearchText(e.target.value)} className="w-9/12 p-2 m-2 rounded-xl pl-10" placeholder="Chercher l'icone"
                            />
                            <FontAwesomeIcon icon={fa.faClose} className="-ml-8 z-10 " onClick={() => { textRef.current.value = ''; setSearchText() }} />

                        </div>
                        <div ref={elementRef}  style={{ color: textColor } } className="w-9/12 h-1/2 bg-white rounded-xl p-2 inline overflow-y-auto " >
                        {svgIcons?.filter(svg => {
                                // console.log(icon, fa[icon], typeof fa[icon])
                                return svg.title &&  (!searchText ||  ( svg.title && svg.title.toUpperCase().includes(searchText.toUpperCase())))
                            }).map((svg, i) => <Icon    icon={svg } key={"svg" +svg.content_id} className=" p-4 w-6 h-6" onClick={() => { setShowTab(false); onChange( ( svg.schema_id===992 ?"svga:":  "svg:")+svg.content_id); }} />)}
                         
                            {Object.keys(fa).filter(icon => {
                                // console.log(icon, fa[icon], typeof fa[icon])
                                return fa[icon].prefix && (!searchText || icon.toUpperCase().includes(searchText.toUpperCase()))
                            }).map((icon, i) => <FontAwesomeIcon icon={fa[icon]} key={i} className=" p-4 w-6 h-6" onClick={() => { onChange(icon); setShowTab(false) }} />)}
                           

                        </div>

                    </div> </> , document.getElementById('root')) }

        </>
    )
}
export default InputIcon