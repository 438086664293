import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";


const ContextToolClean = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app,templateContent,updateContent,suidTemplates,propsTemplates}) => {
  

const VarData = ({tkey,value,set,del})=>{
if(value===undefined || tkey==="children"  ) return <></>;

if(value===null)
return <div className="text-white flex flex-row w-full py-2 gap-1  bg-zinc-800  ">
<div className=" p-2 font-bold underline">{tkey}:</div>
<div className="grow p-2 flex-wrap text-left">NULL</div>
<div onClick={() => del()} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded">
x
</div>
</div>

  if(Array.isArray(value))
    {
    return <div className="justify-start items-start   text-white ml-1 flex flex-col w-full 
    border-l-2 border-zinc-100">
      <div className="w-full flex justify-between p-2 font-bold underline text-left">
      {tkey && <span> {tkey}{"[]"} : </span>}
         {del && <div onClick={() => del()} 
      className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded">x</div> }</div>
      {value.map((v,i)=><VarData tkey={i}  value={v}
       set={(vx)=>set([...value.slice(0,i),vx,...value.slice(i+1)])}  
       del={()=>set([...value.slice(0,i),...value.slice(i+1)])}
           />)}
    </div>
  }
if(typeof value==="object")
{
 var keys = Object.keys(value);
 return <div className="justify-start items-start text-white ml-1 flex flex-col w-full   border-l-2 border-white">
  <div className="flex justify-between text-white w-full p-2 font-bold underline text-left">
   { tkey && <span> {tkey}{ "{}"} : </span>}
    {del && <div onClick={() => del()} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded">x</div> }</div>
  {keys.map(k=><VarData key={k} tkey={k}  value={value[k]} 
  set={(vx)=>set({...value, [k]:vx})}
  del={()=>set({...value, [k]:undefined})}
           />)}
</div>
}

  return <div className="text-white flex flex-row w-full py-2 gap-1 border-t border-t-zinc-800  ">
    <div className=" p-2  ">{tkey}:</div>
    <div className="grow p-2 flex-wrap text-left">{value}</div>
  <div onClick={() => del()} className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-1 rounded">
    x
  </div>
  </div>
}


if(!structure || !selected) return <></>;




  return (
    <div
      id={"ShowTemplateEditStructure"}
      className="bg-zinc-900 p-2 text-white rounded-xl  m-2 flex flex-col   border-white shadow w-full overflow-y-auto  "
   style={{ maxHeight: "50vh" }}
   >


  <VarData key="" value={{...structure }}  set={(str) => {
    updateTemplateStructure({ ...structure, ...str },selected);
  }} 
/>
    </div>
  );
};

export default ContextToolClean;
