
import { useEffect, useMemo, useRef, useState } from "react"

import useDynamicTextColor from "../../tools/useDynamicTextColor";
import InputText from "./InputText";
import { retrieveProject } from "../../service/mftc-api";
import ContentTemplate from "../display/ContentTemplate";
import Icon from "../Icon";

const DefaultOptionRender = ({value,fieldSearch }) =>{
    const elementRef = useRef(null);
    const {textColor,backgroundColor } = useDynamicTextColor(elementRef);
    return <div className="text-left p-2 text-gray-500 border-gray-500 border-t border-opacity-25 " ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>{ (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} </div>
}
const SelectDropDown = ({ id,mainClassName, label, onChange, value, className,placeholder, options, optionsFilter, filter,excludeValues, optionsPlus,fieldSearch="title",fieldId, widthTab="w-full",callClose,OptionRender=DefaultOptionRender,dir,displayTemplateId,internalClick}) => {
    const [showTab, setShowTab] = useState()

    const [optionsValues, setOptionsValues] = useState(options ?? [])


    const [searchText, setSearchText] = useState()
    const [timer, setTimer] = useState(null);
    const[enabledSearch,SetEnabledSearch]= useState()
    const textRef = useRef()

    const [dropdownPosition, setDropdownPosition] = useState('bottom');
    const dropdownRef = useRef(null);

    useEffect(() => {
        const updatePosition = () => {
            if (dropdownRef.current && showTab) {
                const rect = dropdownRef.current.getBoundingClientRect();
                const spaceBelow = window.innerHeight - rect.bottom;
                const spaceAbove = rect.top;
                const dropdownHeight = 400; // Hauteur maximale du dropdown

                if (spaceBelow < dropdownHeight && spaceAbove > spaceBelow) {
                    setDropdownPosition('top');
                } else {
                    setDropdownPosition('bottom');
                }
            }
        };

        updatePosition();
        window.addEventListener('resize', updatePosition);
        window.addEventListener('scroll', updatePosition);
        return () => { window.removeEventListener('resize', updatePosition);window.removeEventListener('scroll', updatePosition);  }
    }, [showTab]);

const onChangeDelay=(new_value)=>{

    if( timer) clearTimeout(timer);

    setTimer(setTimeout(() => {
      setTimer(null);
      onChange && setSearchText(new_value);
    }, 300));
}
const selectedValue = useMemo(()=>{

return (value &&  value[fieldSearch]) ??  (optionsValues && optionsValues.find(option => option[fieldId]===value) ? optionsValues.find(option => option[fieldId]===value)[fieldSearch]:null )  ??(value)

},[value,fieldSearch,fieldId,optionsValues])

const _setShoWTab=(_showTab)=>{
  setShowTab(_showTab)
  if(_showTab)
  {
    setSearchText()
    setTimeout(()=>{
      textRef.current?.focus()
    },100)
  }
}



useEffect(() => {
    // Fonction pour gérer les clics en dehors du composant
    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            _setShoWTab(false);
        }
    };

    // Ajouter l'écouteur d'événement lorsque le menu est ouvert
    if (showTab) {
        document.addEventListener('mousedown', handleClickOutside);
    }

    // Nettoyer l'écouteur d'événement
    return () => {
        document.removeEventListener('mousedown', handleClickOutside);
    };
}, [showTab]);


useEffect(()=>{

 const getOptions=async ()=>{
    if(showTab && optionsFilter) {
     var where,schema_id

     if(typeof optionsFilter === "string"){
        where = optionsFilter
     }else{
         where = optionsFilter.where?? "";
        schema_id = optionsFilter.schema_id;
     }
if(searchText) { 
where +=  (where?" AND ":"  ")+  ` ${fieldSearch} LIKE '%${searchText}%'`;


    }
    var _options = await retrieveProject().api("/get_content_where",{body : {schema_id, where}})
    if(filter) _options= _options.filter(filter)
        if(optionsPlus) _options =[..._options,...optionsPlus.filter(o=> !_options.find(o2=>o.content_id+""===""+o2.content_id))]
    console.log("Options XXXX ", _options)
    setOptionsValues(_options)
   SetEnabledSearch(prev=> prev ?? ( _options.length>10 || searchText) )
}
else if(options)
SetEnabledSearch(options.length>10)

 }
getOptions();

},[searchText,optionsFilter,showTab,optionsPlus])


useEffect(
()=>{


if(options)    setOptionsValues(options)
}
,[options])
    return (
            <div id={id} className={mainClassName +" flex flex-col relative"} ref={dropdownRef}>  {
                displayTemplateId ?
                <div className="flex flex-row items-center w-full appbutton "> <ContentTemplate
                className={" w-full"}
                template_id={displayTemplateId}
                 schema_id={optionsFilter.schema_id}
                content={typeof value === "object" ?value :null}
                content_id={typeof value === "string" || typeof value === "number" ?value :null}
                onClick={()=>_setShoWTab(!showTab)}
                />
                <Icon icon={  showTab ? "faCaretUp" : "faCaretDown"} className="-ml-6 z-10 "  /></div>
                : 
                  <InputText 
id={"text_"+ id}
        label={label}
        className={"w-full"}
        placeholder={placeholder}
     value={selectedValue}
     autoComplete={ "off"} 
         onFocus={()=>_setShoWTab(true)}

      />}

                   { showTab &&
                    <>
                     <div className={  widthTab +   ` flex flex-col justify-center items-center z-50 absolute ${dropdownPosition === 'top' ? 'bottom-full' : 'top-full'}`}>
            
      
                    
                        <div className={" w-full flex flex-col justify-center items-start z-50  left-0 "  } >
                      { enabledSearch &&
                      
                      <div   className="flex flex-row w-full  items-center justify-center">
                            <Icon icon={"faSearch"} className="-mr-8 z-10  " />
                            <input type='text' ref={textRef} autoComplete={ "off"} onChange={(e) => onChangeDelay(e.target.value)} className="text-gray-500 bg-zinc-100 border border-zinc-800 w-full p-2  rounded-md pl-10" placeholder={"Choisir " +label}
                            />
                            <Icon icon={"faClose"} className="-ml-6 z-10 " onClick={() => { textRef.current.value = ''; setSearchText() ;_setShoWTab()}} />

                        </div>}
                       {JSON.stringify(value)} 
                        <div style={{maxHeight:"400px"}} className="relative w-full h-1/2 bg-zinc-100 border border-zinc-800 shadow rounded-xl p-2 flex flex-col justify-start overflow-y-scroll " >
                            { [ ...optionsValues.filter(option => {
                          return    (value && ((value[fieldId] && value[fieldId]===option[fieldId]) || value+""===""+option[fieldId]) ) }) ,...optionsValues.filter(option => {
                              if(value && ((value[fieldId] && value[fieldId]===option[fieldId]) || value+""===""+option[fieldId]) ) return false
                              if(optionsFilter) return (!excludeValues || !excludeValues.includes(option[fieldId])) 

                                   return  !searchText || option[fieldSearch].toUpperCase().includes(searchText.toUpperCase())
                            })].map((option, i) => <div  className={ className + (value && ((value[fieldId] && value[fieldId]===option[fieldId]) ||selectedValue===option) ? " border border-blue-700 hover:line-through hover:border-red-600 ":" border border-transparent " + (internalClick ? "":" appbutton")     )}  key={i} onClick={ internalClick ? null : () => { 
                               onChange((value && value[fieldId] && value[fieldId]===option[fieldId]) ? null: option); _setShoWTab() }} >
                          { 
                          displayTemplateId ?
                          <><ContentTemplate
                          template_id={displayTemplateId}
                           schema_id={optionsFilter.schema_id}
                           className={ className + (value && (( value[fieldId] && value[fieldId]===option[fieldId] ) || value+""===""+option[fieldId] ) ? " border border-blue-700 text-white hover:-red-600 hover:bg-red-600 ":" border-t appbutton"     )}
                        
                          content={typeof option === "object" ?option :null}
                          content_id={ option &&  option[fieldId ]  ? option[fieldId]:option}//typeof option === "string" || typeof option === "number" ?option :null}
                          onClick={  () => { 
                            onChange((value && value[fieldId] && value[fieldId]===option[fieldId]) ? null: option); _setShoWTab() } }
                       
                          />
                          </>
                          : 
                          <OptionRender value={option} fieldSearch={fieldSearch} onClick={ (value) => { 
                            onChange(value); _setShoWTab() }   }/> 
                          }
                         </div>)}

                        </div>

                    </div></div>
                    </>
                     }</div>
    )
}
export default SelectDropDown
