import React, { useEffect, useMemo, useState, useContext } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import InputText from "../inputs/InputText";
import InputBoolean from "../inputs/InputBoolean";
import InputSelect from "../inputs/InputSelect";

import "../../App.css";
import { MfctContext } from "../../context";
import InputData from "../../components/inputs/InputData";
import InputIcon from "../../components/inputs/InputIcon";
import {
  getProps,
  getPropsFromTemplates,
  getSuidTemplates,
  loadTemplate,
} from "../../tools/tpl";
import { getFilterRecord, getRecord } from "../../service/localdata";
import { data } from "../../service/data";
import VarContent from "../VarContent";
import InputTextArea from "../inputs/InputTextArea";
import InputMultiSelect from "../inputs/InputMultiSelect";
import ContentSelect from "../inputs/ContentSelect";
import SelectDropDown from "../inputs/SelectDropDown";
import SvgAnimator from "../SvgAnimator";
import Icon from "../Icon";
import Svg from "../Svg";
import SvgEditor from "../SvgEditor";
import { useNavigate } from "react-router-dom";
import { runAction } from "../../tools/actions";

function ContentEdition({
  defaultContent,
  defaultApp,
  content_id,
  app_id,
  domain,
  template_id,
  updateLiveContent,
  cancelContent,
  saveContent,
  is_schema,
  dataOnly,
  schema_id,
  data_schema,
  _schemas,
  nosave,
  context,
  invprop,
  prop_referrer,
}) {
  const {
    project,
    getTemplates,
    getContent,
    cache,
    getApps,

    // updateContent,
  } = useContext(MfctContext);
  const templates = getTemplates();
  const navigate = useNavigate();
  const apps = getApps();
  const [progression, setProgression] = useState();
  const [optionValues, setOptionValues] = useState({});
  const [template, setTemplate] = useState();

  const [content, setContent] = useState();

  const [app, setApp] = useState();

  const [schemas, setSchemas] = useState(_schemas);

  const [showSettings, setShowSettings] = useState(false);

  const [templateSchemas, setTemplateSchemas] = useState();

  const [invprops, setInvprops] = useState();

  const [contentProps, setContentProps] = useState({});

  const [tab, setTab] = useState("Main");

  const [showChoice, setShowChoice] = useState(null);
  const [suidTemplates, setSuidTemplates] = useState(null);
  const [templateData_schema, setDataSchema] = useState(null);

  const nbModified = useMemo(() => {
    return Object.values(contentProps).reduce(
      (acc, v) => acc + v.filter((c) => c.modified).length,
      0
    );
  }, [contentProps]);

  useEffect(() => {
    const getContents = async () => {
      var newContents = {};

      for (let prop of invprops) {
        if (
          !prop.is_prop &&
          (prop.content.prop.tabInv === tab || tab === prop.name + "s") &&
          !contentProps[prop.prop_id]
        ) {
          var contents = await project.api("/get_content_prop", {
            body: {
              content_id: content.content_id,
              prop_name: prop.prop_title,
              schema_id: prop.porp_schema_id,
            },
          });
          newContents[prop.prop_id] = contents;
        }
      }
      if (Object.keys(newContents).length > 0)
        setContentProps((prev) => ({ ...prev, ...newContents }));
    };
    if (content?.content_id && tab && invprops) getContents();
  }, [tab, invprops, contentProps, content?.content_id, project]);

  useEffect(() => {
    const getTemplateSchemas = async () => {
      var ts = {};

      var props = Object.values(
        template.props_from_templates[template.template_id].props
      );

      for (let prop of props) {
        ts[prop.schema_id] = await data.getSchema(prop.schema_id);
      }

      setTemplateSchemas(ts);
    };

    if (
      template?.props_from_templates &&
      template.props_from_templates[template.template_id]
    )
      getTemplateSchemas();
  }, [template?.props_from_templates, template?.template_id]);

  const importData = async () => {
    function isDate(text) {
      const timestamp = Date.parse(text);
      return !isNaN(timestamp);
    }
    function isFrenchDate(text) {
      const dateRegex = /^([0-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/\d{4}$/;

      if (!dateRegex.test(text)) {
        return false; // Ne correspond pas au format JJ/MM/AAAA
      }

      // Extraction des valeurs pour créer un objet Date
      const [day, month, year] = text.split("/").map(Number);
      const date = new Date(year, month - 1, day);

      // Vérification que les valeurs correspondent bien à une date valide
      return (
        date.getDate() === day &&
        date.getMonth() === month - 1 &&
        date.getFullYear() === year
      );
    }
    function isTime(text) {
      // Regex pour heure au format HH:MM ou HH:MM:SS
      const timeRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/;
      return timeRegex.test(text);
    }
    function isNumber(text) {
      return !isNaN(text);
    }
    var textData = window.prompt("Saisir les donnees");

    try {
      var _data = JSON.parse(textData);

      var listData = [];
      if (Array.isArray(_data)) listData = _data;
      else listData = [_data];
      var keys = {};

      for (let record of listData)
        for (let key of Object.keys(record)) {
          var prop = keys[key] ?? { name: key };
          var value = record[key];

          if (Array.isArray(value))
            prop.type_nombre = Array.isArray(value) ? "I" : "F";
          var _value = prop.type_nombre === "I" ? value[0] : value;

          var type = typeof _value;
          if (type === "string") {
            prop.schema_id = prop.schema_id ?? (_value.length < 40 ? 42 : 43);
            //object
            //canbenumber
            if (isNumber(_value)) prop.schema_id = 44;
            if (isFrenchDate(_value) || isDate(_value)) prop.schema_id = 49;
            if (isTime(_value)) prop.schema_id = 239;
            //canbedate
          }
          if (type === "number") prop.schema_id = 44;

          keys[key] = prop;
        }
      var old_schema = content.content.schema ?? [];
      var schema = [...old_schema];
      for (let prop of Object.values(keys)) {
        if (old_schema.find((p) => p.name === prop.name)) continue;
        schema.push(prop);
      }

      if (schema.length > old_schema.length)
        _setContent({
          ...content,
          content: { ...content.content, schema },
        });
      console.log("newContents", content.content_id);

      if (content.content_id) {
        var newContents = listData.map((c, i) => ({
          title: c.title ?? content.title + " " + i,
          schema_id: content.content_id,
          app_id: content.app_id,
          content: { ...c, title: c.title ?? content.title + " " + i },
        }));

        console.log("newContents", newContents);
        await data.save_contents(newContents);
      }
    } catch (e) {
      console.log(e);
      window.alert("ERREUR");
    }
  };
  const updateSchema = async (_template) => {
    if (!_template || !templates) return;

    if (true) {
      var _suidTemplates = {};
      var propsFromTemplates = await getPropsFromTemplates(
        _template.template_id,
        cache,
        [_template, ...templates],
        project
      );

      _suidTemplates = await getSuidTemplates(
        null,
        _template.structure,
        _suidTemplates,
        _template.template_id,
        true
      );

      var props = Object.values(propsFromTemplates[_template.template_id].props)
        .filter((p) => p.from !== "self")
        .map((p) => {
          var parts = p.name.split("-");
          parts.pop();
          var tab = parts.length > 0 ? parts.join("-") : null;
          var er = { ...p, tab };

          return er;
        });
      props.forEach((p) => {
        if (p.tab && !_suidTemplates[p.tab])
          _suidTemplates[p.tab] = { title: "Off" };
      });
      setSuidTemplates(_suidTemplates);

      setDataSchema(props);
    } else {
      var _data_schema = await getProps(
        _template.structure,
        cache,
        templates,
        project
      );

      if (
        JSON.stringify(_template.data_schema ?? []) !==
        JSON.stringify(_data_schema)
      ) {
        //   await project.api("/set_template", {   body: { template_id: _template.template_id, data_schema },   });
        _template.data_schema = _data_schema;
      }
    }

    return _template;
  };

  async function initContent() {
    var _template;
    var _app;

    // Chargement du content
    var _content = {};
    if (defaultContent) {
      _content = defaultContent;
    } else if (content_id) {
      if ((content_id + "").split("_").length === 2) {
        var [entity_name, entity_id] = content_id.split("_");

        var Entity_name =
          entity_name.charAt(0).toUpperCase() + entity_name.slice(1);
        var entity = await data["get" + Entity_name](parseInt(entity_id));

        _content = data.entityToContent(entity, entity_name);
      } else {
        _content = await data.getContent(parseInt(content_id));
      }
    }
    if (!_schemas || _schemas.length === 0) {
      if (_content.app_id)
        _schemas = await data.getSchemasByApp(_content.app_id);
      else _schemas = await data.getSchemas();

      setSchemas(_schemas);
    }

    /// Chargement du template
    if (_content.template_id || template_id) {
      _template = await loadTemplate(
        template_id ?? _content.template_id,
        templates,
        project
      );
      _template = await updateSchema(_template);
      setTemplate(_template);
    }

    //Chargement de l'app
    if ((_content && !_content.is_schema) || !is_schema) {
      if (defaultApp) {
        _app = defaultApp;
      } else if (_content.app_id || app_id) {
        _app = await getRecord(parseInt(_content.app_id ?? app_id), "app");
      }
    }

    if (_app) {
      setApp(_app);
    } else {
    }

    if (template_id) {
      schema_id = _schemas.filter((s) => s.content.code === "bloc").content_id;
    }
    // Nouveau content
    if (!_content.content_id) {
      _content = {
        template_id,

        schema_id,
        is_schema: is_schema,
        app_id: _app?.app_id ?? app_id,
        title: _template ? _template.title + " - Data" : "x",
        content: {
          title: _template ? _template.title + " - Data" : "",
        },
      };
    }
    if (!_content.schema_id && !_content.is_schema) setShowSettings(true);
    if (template_id && _content) _content.template_id = template_id;
    if (_app && _content && _content.content)
      _content.content.domain = _app.domain; // à enlever lorsque les contenus auront tous un domaine
    if (_content.content_id)
      cache.set("content_" + _content.content_id, _content);

    if (!dataOnly) data.init("content", _content.content_id);
    setContent(_content);
  }

  const modele = useMemo(() => {
    if (templateData_schema) return templateData_schema;

    if (data_schema) return data_schema;

    if (content && schemas) {
      const schema_id = content.is_schema ? 130 : content.schema_id;
      var schema = schemas.find((c) => c.content_id + "" === "" + schema_id);
      if (schema) return schema.content.schema;
    }
  }, [template, content, schemas, templateData_schema]);
  const schema = useMemo(() => {
    if (content && schemas) {
      const schema_id = content.is_schema ? 130 : content.schema_id;
      var schema = schemas.find((c) => c.content_id + "" === "" + schema_id);
      if (schema) return schema;
    }
  }, [content, schemas]);

  const updateTrigger = async (content, prevContent) => {
    if (!schema) return;

    var Triggers = (schema.content.TriggerEvent ?? []).filter(
      (t) => t.Type?.Action === "Update" && t.Type?.Moment === "front"
    );

    var resultContent = content;
    for (let trg of Triggers) {
      if (
        resultContent.content[trg.Type.Prop] !==
        prevContent.content[trg.Type.Prop]
      ) {
        var Action = trg.Action;

        const setTContent = (scontent) => {
          if (scontent)
            resultContent.content = { ...resultContent.content, ...scontent };
        };

        await runAction(
          null,
          Action,
          resultContent.content,
          setTContent,
          null,
          true
        );
      }
    }

    return resultContent;
  };

  useEffect(() => {
    const getInvProps = async () => {
      var props = await project.api("/get_props/:schema_id", {
        args: { schema_id: content?.schema_id },
      });

      setInvprops(props);
    };

    if (invprop && content?.schema_id) {
      getInvProps(content?.schema_id);
    }
  }, [content?.schema_id, invprop, project]);

  const tabs = useMemo(() => {
    var _tabs = ["Main"];

    if (suidTemplates) {
      _tabs = [
        ..._tabs,
        ...Object.keys(suidTemplates).filter((k) => k !== "___"),
      ];
    }

    if (schema && schema.content?.schema)
      for (let prop of schema.content.schema) {
        if (prop.tab) {
          if (!_tabs.includes(prop.tab)) _tabs.push(prop.tab);
        } else if (prop.define) _tabs.push(prop.name);
      }

    if (invprops)
      for (let prop of invprops) {
        if (!prop.is_prop) {
          var tab = prop.content.prop.tabInv ?? prop.name + "s";
          if (!_tabs.includes(tab)) _tabs.push(tab);
        }
      }
    if (schema?.content?.Composant) {
      _tabs.push(schema?.content?.Composant);
      if (schema?.content?.Composant === "Svg") _tabs.push("SvgEditor");
    }
    return _tabs;
  }, [suidTemplates, invprops, schema]);

  const labelTabs = useMemo(() => {
    var labels = {};

    tabs?.forEach(
      (key) =>
        (labels[key] =
          ((suidTemplates &&
            suidTemplates[key] &&
            suidTemplates[key]?.title + " - ") ??
            "") + key)
    );

    return labels;
  }, [tabs, suidTemplates]);

  useEffect(() => {
    async function getOptionValues() {
      if (!modele || !content || schemas.length === 0) return;

      var update = false;
      var _optionValues = { ...optionValues };

      for (var i = 0; i < modele.length; i++) {
        let _schema_id = modele[i].schema_id;
        console.log(" _sub_schema modele", i, modele, _schema_id);
        if (!_schema_id) continue;
        var _sub_schema = schemas.find(
          (s) => parseInt(s.content_id) === parseInt(_schema_id)
        );
        console.log(" _sub_schema modele", i, modele, _sub_schema);

        if (
          _schema_id &&
          (_optionValues[_schema_id] || _sub_schema?.content.is_value)
        )
          continue;
if(!_schema_id) continue;
        var _values = await data.getContents(
          (c) =>
            (c.is_public ||
              content.app_id === "all" ||
              c.app_id === parseInt(content.app_id)) &&
            parseInt(c.schema_id) === parseInt(_schema_id),
          true
        );
        //      project.api("/get_schema_contents", {
        //     body: { app_id: parseInt(content.app_id), schema_id: _schema_id },
        //   });
        if (_values.length > 0) {
          update = true;
          _optionValues[_schema_id] = _values;
        }
        if (context?.page.content?.params) {
          let plus = [];
          Object.keys(context.page.content.params).forEach((key) => {
            var param = context.page.content.params[key];
            if (param.schema.content_id + "" === "" + _schema_id)
              plus.push({
            value:":"+ param.name,
                content_id: ":"+ param.name,
                title: ":"+ param.name ,
                label :":"+ param.name ,
                origin: "parentSelf",
                prop: "self",
               
                schema_id: _schema_id,
              });

            param.schema.content.schema?.forEach((subprop) => {
              if (subprop.schema_id + "" === "" + _schema_id)
                plus.push({
                  value:":"+ param.name + "." + subprop.name,
                  content_id: ":"+ param.name + "." + subprop.name,
                  label :":"+subprop.name + "-"+ param.name ,
                  title: ":"+ subprop.name  + "-" + param.name ,
                  origin: "parentSelf",
                  prop: "self",
                 
                  schema_id: _schema_id,
                });
            });
          });
if(plus.length>0)   update = true;
          _optionValues[_schema_id] = [
            ...(_optionValues[_schema_id] ?? []),
            ...plus,
          ];
        }

   
      }
      if (update) setOptionValues(_optionValues);
    }

    getOptionValues();
  }, [content, modele, schemas, app_id, optionValues, project]);

  useEffect(() => {
    if (
      content &&
      defaultContent &&
      JSON.stringify(content) !==
        JSON.stringify({
          ...content,
          ...defaultContent,
          content: { ...content.content, ...defaultContent.content },
        })
    )
      setContent(defaultContent);
  }, [defaultContent, content]);
  useEffect(() => {
    initContent();

    if (!dataOnly)
      return () => data.close("content", defaultContent?.content_id);
  }, [data_schema]);

  const _setContent = async (c, blnModified = true) => {
    c.modified = blnModified;
    console.log("setContent ", { content });

    var trgContent = await updateTrigger(c, content);

    console.log("trgContent ", { trgContent });
    setContent(c);
    await data.updateContent(content);
    if (updateLiveContent) updateLiveContent(c);
    // if (c.content_id) updateContent(c);
  };

  const cancel = async () => {
    setContent(null);
    setContentProps({});
    await initContent();
    if (cancelContent) cancelContent();
  };

  const save = async (ctnt) => {
    console.log("Saving content");
    var cleanContent = { ...ctnt.content };
    var keys = Object.keys(cleanContent);
    keys.forEach((k) => {
      console.log(k);
      var value = cleanContent[k];
      if (value && typeof value === "object" && value.content_id)
        cleanContent[k] = { content_id: value.content_id };
      else if (Array.isArray(value)) {
        cleanContent[k] = value.map((v) => {
          if (typeof v === "object" && v.content_id)
            return { content_id: v.content_id };
          return v;
        });
      } else cleanContent[k] = value;
    });

    var c = { ...ctnt, content: cleanContent };
    if (ctnt.content_id) cache.set("content_" + ctnt.content_id, ctnt, true);

    if (schema) c.entity_name = schema.entity;

    data.init("content", c.content_id, null, project);

    var modified = c.modified;
    if (c.modified) {
      var _content = await data.save_content(c);
      c = { ..._content, ...c, modified: undefined }; // recuperer le id et garder les sous-contenus
    }

    if (nbModified) {
      var contents = Object.values(contentProps).reduce(
        (acc, v) => [...acc, ...v.filter((_c) => _c.modified)],
        []
      );
      if (contents.length > 0)
        contents = await data.save_contents(contents, !c.modified);

      setContentProps((cP) => {
        for (let p in cP) {
          cP[p] = cP[p].map((_c) => ({
            ...(contents.find(
              (c2) => c2.content_id + "" === "" + _c.content_id
            ) ?? _c),
            modified: undefined,
          }));
        }
        return cP;
      });
    }
    if (modified) {
      //   updateContent(_content);
      _setContent(c, false);
      if (saveContent) saveContent(c);
    }
    //  await project.api("/set_content", { body: c });

    data.close("content", c.content_id);
  };

  return (
    content && (
      <div className=" w-full text-zinc-600 text-sm mb-2 relative ">
        <div className="z-1 w-full mt-1  p-1">
          {!dataOnly && (
            <>
              {" "}
              <div className="w-full flex items-center">
                {" "}
                <InputText
                  label={"Titre"}
                  className={" my-2 w-full"}
                  value={content.content.title}
                  onChange={(value) => {
                    _setContent({
                      ...content,
                      content: { ...content.content, title: value },
                    });
                  }}
                />
                <FontAwesomeIcon
                  icon={fa.faSliders}
                  className="p-2 text-lg shadow-lg rounded-full w-4 h-4 ml-1 appbutton"
                  onClick={() => setShowSettings(!showSettings)}
                />
              </div>
              {showSettings && (
                <div className="flex flex-col w-full">
                  {!suidTemplates && content.template_id && (
                    <InputSelect
                      className={" my-2 w-full"}
                      label={"Schéma de données Template"}
                      options={schemas
                        .filter((s) => s.content.code === "bloc")
                        .map((t) => {
                          return { value: t.content_id, label: t.title };
                        })}
                      value={content.schema_id}
                      //  trueValue={true}
                      placeholder={"Choisis le schéma de données"}
                      onChange={(value) => {
                        var schema = schemas.find(
                          (s) => s.content_id + "" === value
                        );
                        var schema_tag =
                          schema.schema_tag ?? "*" + schema.content_id + "*";

                        _setContent({
                          ...content,
                          schema_id: schema.content_id,
                          schema_tag,
                        });
                      }}
                    />
                  )}
                  {!content.template_id && (
                    <div className="flex flex-row w-full">
                      <InputBoolean
                        className={"p-0 m-2 flex-col"}
                        labelTrue={"Schéma"}
                        labelFalse={"Contenu"}
                        value={content.is_schema}
                        onChange={(value) =>
                          _setContent({ ...content, is_schema: value })
                        }
                      />
                      {!suidTemplates && !content.is_schema && (
                        <InputSelect
                          className={" my-2 w-full"}
                          label={"Schéma de données"}
                          options={schemas.map((t) => {
                            return { value: t.content_id, label: t.title };
                          })}
                          value={content.schema_id}
                          //  trueValue={true}
                          placeholder={"Choisis le schéma de données"}
                          onChange={(value) => {
                            var schema = schemas.find(
                              (s) => s.content_id + "" === "" + value
                            );
                            var schema_tag =
                              schema.schema_tag ??
                              "*" + schema.content_id + "*";

                            _setContent({
                              ...content,
                              schema_id: schema.content_id,
                              schema_tag,
                            });
                          }}
                        />
                      )}

                      {!content.is_schema && (
                        <InputBoolean
                          className={"p-0 m-2 flex-col"}
                          labelTrue={"Requête"}
                          value={content.is_query}
                          onChange={(value) =>
                            _setContent({ ...content, is_query: value })
                          }
                        />
                      )}
                      <InputBoolean
                        className={"p-0 m-2 flex-col"}
                        labelTrue={"Public"}
                        labelFalse={"Privé"}
                        value={content.is_public}
                        onChange={(is_public) =>
                          _setContent({
                            ...content,
                            is_public,
                          })
                        }
                      />
                      <div className="border rounded-xl m-2 w-20 h-20 flex items-center justify-center appbutton">
                        {" "}
                        <FontAwesomeIcon
                          icon={fa.faDownload}
                          className="h-8 appbutton"
                          onClick={() => {
                            navigator.clipboard
                              .writeText(JSON.stringify(content.content))
                              .then(() => {
                                console.log("JSON copied to clipboard");
                                alert("JSON copied to clipboard");
                                window.alert("Objet copié !");
                              })

                              .catch((err) => {
                                console.error(
                                  "Failed to copy JSON to clipboard: ",
                                  err
                                );
                              });
                          }}
                        />
                      </div>

                      {(content.is_schema === 1 ||
                        content.is_schema === true) && ( /// Modèle de donnée
                        <>
                          <InputIcon
                            className={"p-4 m-2 flex-col"}
                            label={"Icone"}
                            value={content.content.icone}
                            onChange={(value) =>
                              _setContent({
                                ...content,
                                content: { ...content.content, icone: value },
                              })
                            }
                          />
                          <InputBoolean
                            className={"p-0 m-2 flex-col"}
                            labelTrue={"Valeur"}
                            labelFalse={"Object"}
                            value={content.content.is_value}
                            onChange={(value) =>
                              _setContent({
                                ...content,
                                content: {
                                  ...content.content,
                                  is_value: value,
                                },
                              })
                            }
                          />
                          {content.content.is_value && (
                            <InputBoolean
                              className={"p-0 m-2 flex-col"}
                              labelTrue={"Enumération"}
                              labelFalse={"Saisie"}
                              value={content.content.is_enum}
                              onChange={(is_enum) =>
                                _setContent({
                                  ...content,
                                  content: { ...content.content, is_enum },
                                })
                              }
                            />
                          )}
                          <InputBoolean
                            className={"p-0 m-2 flex-col"}
                            label={"Elémentaire"}
                            labelTrue={"Elémentaire"}
                            value={content.content.is_elementary}
                            onChange={(is_elementary) =>
                              _setContent({
                                ...content,
                                content: {
                                  ...content.content,
                                  is_elementary,
                                },
                              })
                            }
                          />
                          {content.content.is_value !== 1 &&
                            content.content.is_value !== true && (
                              <InputBoolean
                                className={"p-0 m-2 flex-col"}
                                labelTrue={"Ajoutable"}
                                labelFalse={"Statique"}
                                value={content.content.is_addable}
                                onChange={(value) =>
                                  _setContent({
                                    ...content,
                                    content: {
                                      ...content.content,
                                      is_addable: value,
                                    },
                                  })
                                }
                              />
                            )}

                          {content.is_schema && (
                            <div
                              onClick={importData}
                              className="button appbutton"
                            >
                              {" "}
                              Importer des données
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {content.is_schema && (
                    <InputMultiSelect
                      label={"Template Size"}
                      value={content.content.sizes}
                      onChange={(sizes) =>
                        _setContent({
                          ...content,
                          content: {
                            ...content.content,
                            sizes,
                          },
                        })
                      }
                      values={[
                        "full",
                        "fit",
                        "screen",
                        "xs",
                        "sm",
                        "lg",
                        "xl",
                        "w-full",
                        "w-fit",
                        "w-screen",
                        "w-xs",
                        "w-sm",
                        "w-lg",
                        "w-xl",
                        "h-full",
                        "h-fit",
                        "h-screen",
                        "h-xs",
                        "h-sm",
                        "h-lg",
                        "h-xl",
                      ].map((size) => ({
                        key: size,
                        label: size,
                      }))}
                    />
                  )}
                  {!suidTemplates && (
                    <InputSelect
                      className={" my-2 w-full"}
                      label={"Application"}
                      options={apps.map((app) => ({
                        value: app.app_id + "",
                        label: app.name,
                      }))}
                      placeholder={"Choisir l'application"}
                      value={apps
                        .map((app) => ({
                          value: app.app_id + "",
                          label: app.name,
                        }))
                        .find((app) => app.value + "" === "" + content.app_id)}
                      //  trueValue={true}

                      onChange={(app_id) => {
                        _setContent({
                          ...content,
                          app_id,
                        });
                      }}
                    />
                  )}
                </div>
              )}
            </>
          )}
{ context?.page  && <VarContent  data={context.page?.content.params} className={"hidden"} />    }
          {tabs.length > 1 && (
            <div className="my-2 border-b w-full flex flex-row ">
              {tabs.map((_tab) => {
                return (
                  <div
                    key={_tab}
                    className={
                      "px-4 border-l border-t p-2 appbutton rounded-tr-xl " +
                      (_tab === tab ? " bg-blue-500 text-white" : "")
                    }
                    onClick={() => setTab(_tab)}
                  >
                    {labelTabs[_tab]}
                  </div>
                );
              })}
            </div>
          )}
          {tab === "Main" && content.is_schema && (
            <>
              {!content.content.is_enum && (
                <InputSelect
                  value={content.content.display_template_id}
                  onChange={(display_template_id) => {
                    _setContent({
                      ...content,
                      content: { ...content.content, display_template_id },
                    });
                  }}
                  options={templates
                    .filter((t) => t.schema_id + "" === "" + content.content_id)
                    .map((template) => ({
                      label: template.title,
                      value: template.template_id,
                    }))}
                  label={"Template d'affichage"}
                  placeholder={"Template d'affichage"}
                />
              )}
              {!content.content.is_enum && (
                <div className="flex flex-col md:flex-row w-full gap-1">
                  <InputText
                    label={"Code"}
                    className={" my-2 w-full"}
                    value={content.content.code}
                    onChange={(code) => {
                      _setContent({
                        ...content,
                        content: { ...content.content, code },
                      });
                    }}
                  />
                  <InputText
                    label={"Entité"}
                    className={" my-2 w-full"}
                    value={content.content.entity}
                    onChange={(entity) => {
                      _setContent({
                        ...content,
                        content: { ...content.content, entity },
                      });
                    }}
                  />
                  {content.content.entity && (
                    <InputText
                      label={"JSON"}
                      className={" my-2 w-full"}
                      value={content.content.jContent}
                      onChange={(jContent) => {
                        _setContent({
                          ...content,
                          content: { ...content.content, jContent },
                        });
                      }}
                    />
                  )}
                  <InputText
                    label={"Display Name"}
                    className={" my-2 w-full"}
                    value={content.content.displayName}
                    onChange={(displayName) => {
                      _setContent({
                        ...content,
                        content: { ...content.content, displayName },
                      });
                    }}
                  />
                  {
                    <InputBoolean
                      className={"p-0 m-2 flex-col"}
                      labelTrue={"Context"}
                      value={content.content.in_context}
                      onChange={(in_context) =>
                        _setContent({
                          ...content,
                          content: { ...content.content, in_context },
                        })
                      }
                    />
                  }
                  {content.content.is_value && (
                    <InputSelect
                      label={"DB Format"}
                      className={"p-0 m-2 flex-col w-full"}
                      value={content.content.formatdb}
                      onChange={(formatdb) =>
                        _setContent({
                          ...content,
                          content: { ...content.content, formatdb },
                        })
                      }
                      options={[
                        "-",
                        "VARCHAR",
                        "TEXT",
                        "NUMERIC",
                        "BOOLEAN",
                        "JSON",
                        "FLOAT",
                        "DATE",
                        "GEOHASH",
                        "OBJECT",
                      ]}
                    />
                  )}
                </div>
              )}

              {!content.content.is_enum && (
                <InputData
                  className={"w-full"}
                  prop={{
                    name: "Définition",
                    schema_id: 124,
                    code: "definition",
                    type_nombre: "I",
                  }}
                  value={content.content.schema ?? []}
                  schema={schemas.find((s) => s.content.code === "definition")}
                  onChange={(schema) =>
                    _setContent({
                      ...content,
                      content: { ...content.content, schema },
                    })
                  }
                />
              )}

              {content.content.is_enum && (
                <InputData
                  className={"w-full"}
                  prop={{
                    name: "Valeur",
                    schema_id: 42,
                    code: "text",
                    type_nombre: "I",
                  }}
                  value={content.content.values ?? []}
                  schema={schemas.find((s) => s.content.code === "text")}
                  onChange={(values) =>
                    _setContent({
                      ...content,
                      content: { ...content.content, values },
                    })
                  }
                />
              )}
              <InputTextArea
                className={" my-2 w-full"}
                label={"Description"}
                placeholder={"Saisir la description du schéma"}
                value={content.content.description}
                onChange={(description) => {
                  _setContent({
                    ...content,
                    content: { ...content.content, description },
                  });
                }}
              />
            </>
          )}
          {modele && ( //// Contenu de Modele
            <>
              {content.is_query && (
                <InputText
                  label={"Query_Id"}
                  className={" my-2 w-full"}
                  value={content.content.query_id}
                  onChange={(query_id) => {
                    _setContent({
                      ...content,
                      content: { ...content.content, query_id },
                    });
                  }}
                />
              )}
              {tab === "Main" &&
                template &&
                template.default_content_id + "" === "" + content_id && (
                  <div className="appbutton p-2 flex justify-start items-center  ">
                    {" "}
                    Original Default Content Template{" "}
                  </div>
                )}
              {tab === "Main" &&
                template &&
                template.template_id + "" === "" + content.template_id && (
                  <div className="appbutton p-2 flex justify-start items-center  ">
                    {" "}
                    Content - Template Ok{" "}
                  </div>
                )}
              {tab === "Main" &&
                template &&
                template.template_id + "" !== "" + content.template_id && (
                  <div
                    className="appbutton p-2 flex justify-start items-center underline "
                    onClick={() =>
                      window.open(
                        `/contentTemplate/${content_id}/${content.template_id}`,
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Content - Template Référent Different {
                      content.template_id
                    }{" "}
                    <FontAwesomeIcon
                      icon={fa.faTable}
                      className={
                        "appbutton text-gray-600  bg-black bg-opacity-30  border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-4 w-4  m-1 mx-2 "
                      }
                      style={{ zIndex: 10000000 }}
                    />
                  </div>
                )}

              {tab === "Main" &&
                template &&
                template.default_content_id &&
                template.default_content_id + "" !== "" + content_id && (
                  <div
                    className="appbutton p-2 flex justify-start items-center underline "
                    onClick={() =>
                      window.open(
                        `/contentTemplate/${template.default_content_id}/${template.template_id}`,
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Default Content Template {template.default_content_id}{" "}
                    <FontAwesomeIcon
                      icon={fa.faTable}
                      className={
                        "appbutton text-gray-600  bg-black bg-opacity-30  border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-4 w-4  m-1 mx-2 "
                      }
                      style={{ zIndex: 10000000 }}
                    />
                  </div>
                )}
              {suidTemplates &&
                suidTemplates[tab] &&
                suidTemplates[tab].default_content_id && (
                  <div
                    className="appbutton p-2 flex justify-start items-center underline "
                    onClick={() =>
                      window.open(
                        `/contentTemplate/${suidTemplates[tab].default_content_id}/${suidTemplates[tab].template_id}`,
                        "_blank"
                      )
                    }
                  >
                    {" "}
                    Default Content {tab} Template{" "}
                    {suidTemplates[tab].default_content_id}{" "}
                    <FontAwesomeIcon
                      icon={fa.faTable}
                      className={
                        "appbutton text-gray-600  bg-black bg-opacity-30  border border-white border-opacity-50 transition-all duration-200   rounded-full  p-2 h-4 w-4  m-1 mx-2 "
                      }
                      style={{ zIndex: 10000000 }}
                    />
                  </div>
                )}

              {
                /*    schema.content.code ?
              <InputData 
              updateContent={updateLiveContent}
              id={  "-"}
              prop={{  schema_id:  schema.content_id  }}
              schema={
               schema
              }
              className={"w-full my-2"}
              defaultValue={content.content}
              value={content.content}
              onChange={async (value) => {

                _setContent({ ...content, content: value });
              }}
          
         
              domain={app?.domain ?? domain}
              context={{ codeSchema : schema?.content?.code }}
              
              />
              
              
              : */ modele
                  .filter(
                    (s) =>
                      !content.content["_data_" + s.name] &&
                      prop_referrer !== s.name &&
                      ((tab === "Main" && !s.tab && !s.define) ||
                        (s.define && tab === s.name) ||
                        s.tab === tab)
                  )
                  .map((s, i) => (
                    <div className="w-full flex flex-col " key={s.name + i}>
                      <div className="flex w-full gap-1 items-center justify-start">
                        {(!s.is_optional ||
                          content.content["option_" + s.name]) && (
                          <div className="w-full flex flex-col">
                            <InputData
                              updateContent={updateLiveContent}
                              id={s.name + "-"}
                              prop={s}
                              schema={
                                schemas.find(
                                  (c) => c.content_id + "" === "" + s.schema_id
                                ) ?? {
                                  content: { code: "long text" },
                                }
                              }
                              className={"w-full my-2"}
                              defaultValue={s.defaultValue}
                              value={content.content[s.name]}
                              onChange={async (value) => {

                                var _content = content.content;
                                let schema = schemas.find(
                                  (c) => c.content_id + "" === "" + s.schema_id
                                );
                                
                              
                                if (
                                  value &&
                                  typeof value === "object" &&
                                  value.content_id  &&
                                  typeof  value.content_id === "string" &&
                                   value.content_id.startsWith(":") 
                                ) {



                                } else if (
                                  value &&
                                  typeof value === "object" &&
                                  value.content_id
                                ) {
                                  console.log("a charger !!!", value);
                                  // Charge le contenu de l'object par son id
                                  value = await getContent(value.content_id);
                                } else if (Array.isArray(value)) {
                                  for (var j = 0; j < value.length; j++) {
                                    var _v = value[j];

                                    if (
                                      _v &&
                                      typeof _v === "object" &&
                                      _v.content_id &&
                                      Object.keys(_v).length === 1
                                    ) {
                                      if (schema.content.entity) value[j] = _v;
                                      else
                                        value[j] = await getContent(
                                          _v.content_id
                                        );
                                    }
                                  }
                                }

                                console.log("a mettre a jour !!!", {
                                  _content,
                                  content,
                                  value
                                });
                                _setContent({
                                  ...content,
                                  content: { ..._content, [s.name]: value },
                                });
                              }}
                              options={optionValues[s.schema_id]}
                              placeholder={
                                s.defaultValue ?? "Choisir " + s.name
                              }
                              domain={app?.domain ?? domain}
                              context={{
                                ...context,
                                codeSchema: schema?.content?.code,
                                ...(schema?.content?.in_context
                                  ? { [schema.title]: content }
                                  : {}),
                                currentContent: content,
                              }}
                            />
                             {suidTemplates &&
                              Object.keys(suidTemplates)
                                .filter((k) => k === "___" || tab.startsWith(k))
                                .map((k) => (
                                  <div className="text-xs w-full italic py-1 px-2 border-b flex justify-end">
                                    {suidTemplates[k].default_content_id}{" "}
                                    {JSON.stringify(
                                      suidTemplates[k]?.content &&
                                        suidTemplates[k]?.content[
                                          k === "___"
                                            ? s.name
                                            : s.name.split(k + "-").pop()
                                        ]
                                    )}{" "}
                                  </div>
                                ))}
                          </div>
                        )}
                        {s.is_optional && (
                          <InputBoolean
                            value={content.content["option_" + s.name]}
                            labelTrue={
                              content.content["option_" + s.name] ? "" : s.name
                            }
                            onChange={(value) =>
                              _setContent({
                                ...content,
                                content: {
                                  ...content.content,
                                  ["option_" + s.name]:
                                    !content.content["option_" + s.name],
                                },
                              })
                            }
                          />
                        )}
                      </div>
                      {s.defaultValue && (
                        <div className="text-xs italic -mt-2  mx-2 p-2 border border-t-0  border-dashed rounded-b-xl border-zinc-400 text-zinc-500">
                          {s.defaultValue}
                        </div>
                      )}
                    </div>
                  ))
              }
              {tab === schema?.content?.Composant &&
                schema?.content?.Composant === "SvgAnimator" && (
                  <SvgAnimator
                    content_id={content_id ?? content.content_id}
                    className={"w-96 h-96"}
                  />
                )}
              {tab === schema?.content?.Composant &&
                schema?.content?.Composant === "Svg" && (
                  <Svg
                    content_id={content_id ?? content.content_id}
                    className={"w-96 h-96"}
                  />
                )}
              {tab === "SvgEditor" && schema?.content?.Composant === "Svg" && (
                <SvgEditor setContent={_setContent} content={content} />
              )}
              {false && (
                <InputBoolean
                  labelTrue={"Visible pour tous"}
                  labelFalse={"Visible avec authentification"}
                  value={content.is_public}
                  onChange={(value) => {
                    _setContent({ ...content, is_public: value });
                  }}
                />
              )}
            </>
          )}
        </div>
        {invprops && invprops.length > 0 && (
          <div className="p-2 bg-white w-full flex flex-col">
            {invprops
              .filter(
                (prop) =>
                  !prop.is_prop &&
                  (prop.content.prop.tabInv === tab || tab === prop.name + "s")
              )
              .map((prop) => (
                <div
                  className="w-full border-t border-black flex flex-col items-start"
                  key={prop.prop_id}
                >
                  <div className="flex flex-row items-center justify-between hidden">
                    {Object.keys(prop).map((v) => (
                      <div className="border-l p-2 text-xs font-bold">
                        {v} : {JSON.stringify(prop[v])}{" "}
                      </div>
                    ))}
                  </div>
                  <div className=" w-full flex flex-row items-center justify-between">
                    <div className="p-2 font-bold">
                      {prop.name}s ({prop.prop_title})
                    </div>
                    <div>{contentProps[prop.prop_id]?.length ?? 0}</div>
                  </div>
                  {
                    <>
                      {contentProps &&
                        contentProps[prop.prop_id] &&
                        contentProps[prop.prop_id]
                          .filter(
                            (c) =>
                              c.content[prop.prop_title]?.content_id + "" ===
                              "" + content.content_id
                          )
                          .map((c, i) => (
                            <div
                              key={c.content_id}
                              className="w-full flex flex-col items-start"
                            >
                              <div className="flex w-full justify-between items-center">
                                <div
                                  className="appbutton underline"
                                  onClick={() => {
                                    setContentProps((prev) => ({
                                      ...prev,
                                      [prop.prop_id]: [
                                        ...prev[prop.prop_id].slice(0, i),
                                        { ...c, showTools: !c.showTools },
                                        ...prev[prop.prop_id].slice(i + 1),
                                      ],
                                    }));
                                  }}
                                >
                                  {c.title ?? `(${prop.name} ${c.content_id})`}
                                </div>

                                <div className="flex flex-row gap-1">
                                  {c.showTools && (
                                    <>
                                      <div
                                        className=" bg-white appbutton border-green-600 text-green-600 text-xs border-2  p-1 rounded-full flex items-center justify-end"
                                        onClick={async () => {
                                          window.open(
                                            "/content/" + c.content_id,
                                            "content_" + c.content_id
                                          );
                                        }}
                                      >
                                        {" "}
                                        Ouvrir
                                        <FontAwesomeIcon
                                          className="   bg-green-600 p-1 text-white rounded-full ml-1"
                                          icon={fa.faLink}
                                        />
                                      </div>
                                      {!prop.content.prop.is_owner && (
                                        <div
                                          className=" bg-white appbutton border-red-600 text-red-600 text-xs border-2  p-1 rounded-full flex items-center justify-end"
                                          onClick={async () => {
                                            if (
                                              !window.confirm(
                                                "Voulez vous vraiment enlever ce " +
                                                  prop.name +
                                                  " de la liste ?"
                                              )
                                            )
                                              return;

                                            c.content[prop.prop_title] = null;
                                            setContentProps((prev) => ({
                                              ...prev,
                                              [prop.prop_id]: [
                                                ...prev[prop.prop_id].slice(
                                                  0,
                                                  i
                                                ),
                                                { ...c, modified: true },
                                                ...prev[prop.prop_id].slice(
                                                  i + 1
                                                ),
                                              ],
                                            }));
                                          }}
                                        >
                                          {" "}
                                          Enlever
                                          <FontAwesomeIcon
                                            className="   bg-red-600 p-1 text-white rounded-full ml-1"
                                            icon={fa.faMinus}
                                          />
                                        </div>
                                      )}

                                      <div
                                        className=" bg-white appbutton border-red-600 text-red-600 text-xs border-2  p-1 rounded-full flex items-center justify-end"
                                        onClick={async () => {
                                          if (
                                            !window.confirm(
                                              "Voulez vous vraiment supprimer définitivement ce " +
                                                prop.name +
                                                " ?"
                                            )
                                          )
                                            return;

                                          c.content[prop.prop_title] = null;
                                          c.toDelete = true;
                                          setContentProps((prev) => ({
                                            ...prev,
                                            [prop.prop_id]: [
                                              ...prev[prop.prop_id].slice(0, i),
                                              { ...c, modified: true },
                                              ...prev[prop.prop_id].slice(
                                                i + 1
                                              ),
                                            ],
                                          }));
                                          //     var values =   (value && Array.isArray(value)  ? value :null) ?? []
                                          //    var  newvalues =[...values,""];
                                          //    onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                                        }}
                                      >
                                        {" "}
                                        Supprimer
                                        <FontAwesomeIcon
                                          className="   bg-red-600 p-1 text-white rounded-full ml-1"
                                          icon={fa.faTrash}
                                        />
                                      </div>
                                    </>
                                  )}
                                </div>
                              </div>
                              <ContentEdition
                                defaultContent={c}
                                nosave={true}
                                dataOnly={true}
                                prop_referrer={prop.prop_title}
                                updateLiveContent={(c) =>
                                  setContentProps((prev) => ({
                                    ...prev,
                                    [prop.prop_id]: [
                                      ...prev[prop.prop_id].slice(0, i),
                                      { ...c, modified: true },
                                      ...prev[prop.prop_id].slice(i + 1),
                                    ],
                                  }))
                                }
                              />
                            </div>
                          ))}
                      <div className="justify-end w-full flex flex-row text-xs">
                        {!prop.content.prop.is_owner && (
                          <div className="flex flex-col gap-1 flex-grow">
                            {contentProps[prop.prop_id] && (
                              <SelectDropDown
                                mainClassName="flex-grow"
                                className={"w-full"}
                                label={"Ajouter " + prop.name}
                                optionsFilter={` schema_id = ${prop.porp_schema_id} AND JSON_VALID(content) AND 
( 
 JSON_EXTRACT(content, "$.${prop.prop_title}.content_id") IS NULL  )`}
                                /* 
 JSON_EXTRACT(content, "$.${prop.prop_title}") IS NULL 
 OR 
 OR JSON_EXTRACT(content, "$.${prop.prop_title}.content_id") <> ${content.content_id} */
                                value={null}
                                excludeValues={contentProps[prop.prop_id]?.map(
                                  (c) => c.content_id
                                )}
                                onChange={async (_content) => {
                                  setShowChoice();
                                  _content.content[prop.prop_title] = {
                                    content_id: content.content_id,
                                  };
                                  _content.modified = true;
                                  setContentProps((prev) => ({
                                    ...prev,
                                    [prop.prop_id]: [
                                      ...prev[prop.prop_id],
                                      _content,
                                    ],
                                  }));
                                }}
                                fieldId={"content_id"}
                                fieldSearch={"title"}
                                callClose={() => setShowChoice()}
                                placeholder={"Choisir " + prop.name}
                                OptionRender={(c) => <div>{c.value.title}</div>}
                                dir={"top"}
                              />
                            )}
                          </div>
                        )}

                        <div
                          className=" bg-white appbutton border-blue-600 text-blue-600 border-2  p-1 m-1 rounded-full flex items-center justify-end"
                          onClick={async () => {
                            var _content = await data.save_content(
                              {
                                app_id: content.app_id,
                                schema_id: prop.porp_schema_id,
                                title:
                                  prop.name +
                                  " " +
                                  (contentProps[prop.prop_id]?.length + 1),
                                content: {
                                  title:
                                    prop.name +
                                    " " +
                                    (contentProps[prop.prop_id]?.length + 1),
                                  [prop.prop_title]: {
                                    content_id: content.content_id,
                                  },
                                },
                              },
                              true
                            );
                            setContentProps((prev) => ({
                              ...prev,
                              [prop.prop_id]: [...prev[prop.prop_id], _content],
                            }));

                            //     var values =   (value && Array.isArray(value)  ? value :null) ?? []
                            //    var  newvalues =[...values,""];
                            //    onChange(context?.json ? JSON.stringify(newvalues) :newvalues);
                          }}
                        >
                          {" "}
                          Créer {prop.name}
                          <FontAwesomeIcon
                            className="   bg-blue-600 p-1 text-white rounded-full ml-2"
                            icon={fa.faPlus}
                          />
                        </div>
                      </div>
                    </>
                  }
                </div>
              ))}
          </div>
        )}
        {template && (
          <div className="p-2 bg-white w-full flex flex-col">
            {template.props_from_templates &&
              template.props_from_templates[template.template_id]?.props &&
              templateSchemas &&
              Object.values(
                template.props_from_templates[template.template_id].props
              )
                .filter(
                  (prop) => !templateSchemas[prop.schema_id].content.is_value
                )
                .map((prop) => (
                  <div
                    className="w-full border-t border-black flex flex-col items-start"
                    key={prop.name}
                  >
                    <InputBoolean
                      value={!!content.content["_data_" + prop.name]}
                      labelFalse={
                        prop.name +
                        " -No DataSource " +
                        templateSchemas[prop.schema_id].title
                      }
                      labelTrue={prop.name + " -DataSource"}
                      onChange={(value) =>
                        _setContent({
                          ...content,
                          content: {
                            ...content,
                            ["_data_" + prop.name]: content.content[
                              "_data_" + prop.name
                            ]
                              ? null
                              : {},
                          },
                        })
                      }
                    />
                    {content.content["_data_" + prop.name] && (
                      <ContentSelect
                        className={"w-full"}
                        context={{
                          filter: (c) =>
                            c.content.schema?.content_id + "" ===
                              "" + prop.schema_id &&
                            !!c.content.isList ===
                              (prop.type_nombre === "I" ||
                                prop.type_nombre === "F"),
                          app_id: content.app_id ?? template.app_id,
                          ...context,
                        }}
                        value={content.content["_data_" + prop.name].content_id}
                        placeholder={"-- Sélectionner source --"}
                        onChange={async (dataSource_id) => {
                          _setContent({
                            ...content,
                            content: {
                              ...content,
                              ["_data_" + prop.name]: {
                                content_id: dataSource_id,
                              },
                            },
                          });

                          //    var data = await getDataSource( dataSource_id,content,template.template_id )

                          /*  updateTemplateStructure(
                      {
                        ...template.structure,
                        ["_data_" + prop.name]: value,
                      },
                      "-root"
                    )
                  */
                        }}
                        schema_id={688}
                      />
                    )}
                  </div>
                ))}
          </div>
        )}
        <VarContent data={data_schema} className={"hidden"} />

        {!nosave && !content.test && (content.modified || nbModified > 0) && (
          <div className="p-2 bg-white  w-full flex flex-col rounded-t-xl overflow-hidden   sticky bottom-0  ">
            <div className=" w-full flex justify-end">
              <button
                type="button"
                className="appbutton border border-1 p-2 m-1 first-line:rounded"
                onClick={cancel}
              >
                Annuler
              </button>
              <button
                type="button"
                className="appbutton  border border-1 p-2 m-1 first-line:rounded"
                onClick={() => save(content)}
                id="upload"
              >
                Enregistrer{" "}
                {(content && content.is_schema) || is_schema
                  ? "Schéma"
                  : schema?.title ?? "Contenu"}
                {nbModified > 0 && (
                  <span className="text-xs text-red-500">
                    {" "}
                    (+{nbModified} modifs)
                  </span>
                )}
              </button>
            </div>
          </div>
        )}
      </div>
    )
  );
}

export default ContentEdition;
