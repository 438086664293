import { useEffect, useRef, useState } from "react"
import useDynamicTextColor from "../../tools/useDynamicTextColor";



const InputTextArea = ({ref ,id,label,onChange,value,className,bg="bg-white",infos,disabled}) => {
    
    var originalRef =  useRef(null);

    const elementRef=ref ?? originalRef
    const {textColor,backgroundColor } = useDynamicTextColor(elementRef);

    const [timer,setTimer] =useState()


    const onChangeDelay=(new_value)=>{

        if( timer) clearTimeout(timer);
    
        setTimer( setTimeout(()=>{    setTimer();onChange(new_value)    } ,300    )   )
        
    }

    
//if(value  && (typeof value !=="string" )) value =typeof value


    useEffect(()=>{

        if(elementRef.current && elementRef.current.value!==(value??""))elementRef.current.value=(value??"")
       
       },[value,id,label])




return (
    <>
    <div className={"relative bg-inherit " +className } >
    <textarea ref={elementRef}  style={{ color: textColor } } readOnly={disabled} id={id ?? "input_"+label} onChange={(e)=>onChangeDelay(e.target.value)}  rows={Math.max(1, (value+"")?.split("\n").length+1 ?? 0 )  } defaultValue={ (typeof value ==="string" ? value : ""+value)?? ""}  className="block px-2.5 pb-2.5 pt-4 w-full text-sm text-gray-900 bg-inherit rounded-lg border border-gray-400 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " />
    <label   style={{ backgroundColor: backgroundColor } } htmlFor={ "input_"+label } className={"absolute text-sm text-gray-500  duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0]  px-2 peer-focus:px-2 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1 " +bg }>{label}</label>
    {infos  &&  <div className="italic w-full text-xs p-1 text-left  whitespace-pre-line">{infos}</div>}
    </div>
    
</> 
)
}
export default InputTextArea