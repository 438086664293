import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import ContentEdition from "../../../../components/edition/ContentEdition";
import VarContent from "../../../../components/VarContent";
import { useContext, useEffect, useMemo, useState } from "react";
import { createClass, deleteClass, getProps } from "../../../../tools/tpl";
import { MfctContext } from "../../../../context";
import InputSelect from "../../../../components/inputs/InputSelect";
import { data } from "../../../../service/data";
import { getFilterRecord, setRecord } from "../../../../service/localdata";
import { lightFormatters } from "date-fns";

const ShowPalette = ({
  setShow,
  palette,
  onChangePalette,
  template,
  templates,
  id,
  app,
}) => {
  const colorsPattern = [
    "primary_color",
    "secondary_color",
    "black",
    "white",
    "blue",
    "green",
  ];
  const bgColors = {
    primary_color: "bg-primary_color",
    secondary_color: "bg-secondary_color",
    black: "bg-black",
    white: "bg-white",
    blue: "bg-blue-500",
    green: "bg-green-500",
    red: "bg-red-500",
    orange: "bg-orange-500",
    yellow: "bg-yellow-500",
    amber: "bg-amber-500",
    lime: "bg-lime-500",
    emerald: "bg-emerald-500",
    teal: "bg-teal-500",
    cyan: "bg-cyan-500",
    sky: "bg-sky-500",
    indigo: "bg-indigo-500",
    violet: "bg-violet-500",
    fuchsia: "bg-fuchsia-500",
    pink: "bg-pink-500",
    gray: "bg-gray-500",
    dark: "bg-dark",
    rose: "bg-rose-500",
    purple: "bg-purple-500",
    slate: "bg-slate-500",

    stone: "bg-stone-500",
    neutral: "bg-neutral-500",
    zinc: "bg-zinc-500",
  };
  const [classColors, setClassColors] = useState([]);
  const [uniqueColors, setUniqueColors] = useState([]);
  
  const [Palettes, setPalettes] = useState([]);
const [liteStruct, setLiteStruct] = useState();
  ///       exemple   classColors = ["bg-white" , "text-primary_color","text-white"]
  //exemple   classColors = ["bg-white" , "text-primary_color","text-white"]
  // uniqueColors = ["white" , "primary_color"]
  //  palette 0   =  {  "white":"white","primary_color":"primary_color"}

  // comboColors = ["primary_color","secondary_color", "white"]
  // palette 1 =  {  "white":"primary_color","primary_color":"secondary_color"}
  var objTesting = document.getElementById("bloc_" + id.replace("bloc_", ""));

  var testingPalette;
  var timerPalette;

  const testPalette = (paletteX) => {
    timerPalette = null;
    if (testingPalette) {
      //   if (JSON.stringify(testingPalette) === JSON.stringify(paletteX)) return;
      //abortPalette(testingPalette);
    }
console.log("Testing palette",paletteX)
    var objPalette = paletteX
      ? document.getElementById(JSON.stringify(paletteX))
      : document.getElementById(JSON.stringify(Palettes[0]));
var colors = []
    var iPalette = 0;
    if (objPalette)
      for (let child of objPalette.childNodes) {
    if(child.id === "CmpLiteStruct") continue;
        if (paletteX) {
          var color = window.getComputedStyle(child).backgroundColor;
colors.push(color)
          createClass(
            `bg-palette-${id}-${iPalette}`,
            `background-color: ${color} !important;
     transition : all 0.2s ease-out; `
          );
          createClass(
            `text-palette-${id}-${iPalette}`,
            `color: ${color} !important;
     transition : all 0.2s ease-out;`
          );
          createClass(
            `border-palette-${id}-${iPalette}`,
            `border-color: ${color} !important;
     transition : all 0.2s ease-out;`
          );
        } else {
          //  deleteClass(`bg-palette-${id}-${iPalette}`)
          //  deleteClass(`text-palette-${id}-${iPalette}`)
          //  deleteClass(`border-palette-${id}-${iPalette}` )

          createClass(
            `bg-palette-${id}-${iPalette}`,
            `transition : all 0.2s ease-out;`
          );
          createClass(
            `text-palette-${id}-${iPalette}`,
            `  transition : all 0.2s ease-out;`
          );
          createClass(
            `border-palette-${id}-${iPalette}`,
            ` transition : all 0.5s ease-out;`
          );
        }
        iPalette++;
      }
      console.log("Testing palette",{paletteX,colors})
    testingPalette = paletteX;
  };
  const  sColor = (classe,palette)=>{

    for(let key of Object.keys(palette)){
    
         if(classe.split("-"+key).length > 1) return classe.split("-"+key).join("-" + palette[key]);
    
    
    }
    
    return classe
    }
  useEffect(() => {
    const comboColors = ["primary_color", "secondary_color", "black", "white"];
    const keys = Object.keys(bgColors);
    const jClassColors = (_palette, classColors, uniqueColors) => {
      var jc = {};

      classColors.forEach((c) => {
        var ckey = c;
        var cvalue = c;

        for (let key of uniqueColors) {
          if (c.includes("-" + key)) {
            if (_palette) cvalue = c.replace("-" + key, "-" + _palette[key]);
            break;
          }
        }

        jc[ckey] = cvalue;
      });

      return jc;
    };

    const getLiteStruct = (structure,bgParent) => {
      var struct = { bg: "", border: "", text: [], structs: []} ;

      var cls = [];

      if (structure.className)
        structure.className
          .split(" ")
          .filter(
            (classe) =>
              classe.length > 0 && keys.find((k) => classe.includes("-" + k))
          )
          .forEach((classe) => {
            var type = classe.split("-")[0];
            switch (type) {
              case "bg":
                if(classe!==bgParent) struct.bg = classe;
                break;
              case "border":
                  struct.border = classe;
                break;
              case "text":
                struct.text.push(classe);
                break;
              default:
            }
          });

      if (structure.template_id) {
        var tpl = templates.find(
          (t) => structure.template_id + "" === t.template_id + ""
        );
        if (tpl) {
          var tpl_struct = getLiteStruct(tpl.structure,struct.bg ?? bgParent);
         if( tpl_struct.bg) {
     //     cls = [...cls, ...tpl_cls.filter((c) => cls.indexOf(c) === -1)];
          struct.structs.push(tpl_struct);
         }
         else {
          struct.text = [...struct.text,...tpl_struct.text.filter((c) => struct.text.indexOf(c) === -1) ];
          struct.structs = [...struct.structs,...tpl_struct.structs];
      
         }
         
        }
      }
      if (structure.children)
        for (let child of structure.children) {
    

          var child_struct = getLiteStruct(child,struct.bg ?? bgParent);
          if( child_struct.bg) {
      //     cls = [...cls, ...tpl_cls.filter((c) => cls.indexOf(c) === -1)];
           struct.structs.push(child_struct);
          }
          else {
           struct.text = [...struct.text,...child_struct.text.filter((c) => struct.text.indexOf(c) === -1) ];
           struct.structs = [...struct.structs,...child_struct.structs];
       
          }
        }

      return struct;
    };

    const getClasseNames = (structure) => {
      var cls = [];

      if (structure.className)
        structure.className.split(" ").forEach((classe) => {
          if (classe.length > 0) cls.push(classe);
        });
      if (structure.template_id) {
        var tpl = templates.find(
          (t) => structure.template_id + "" === t.template_id + ""
        );
        if (tpl) {
          var tpl_cls = getClasseNames(tpl.structure);

          cls = [...cls, ...tpl_cls.filter((c) => cls.indexOf(c) === -1)];
        }
      }
      if (structure.children)
        for (let child of structure.children) {
          var child_cls = getClasseNames(child);

          cls = [...cls, ...child_cls.filter((c) => cls.indexOf(c) === -1)];
        }

      return cls;
    };

    var classes = getClasseNames(template.structure);

    classes = classes.filter((classe) =>
      keys.find((k) => classe.includes("-" + k))
    ); // on recupere les classes qui contiennent de la couleur

    var _uniqueColors = classes.map((c) => {
      var parts = c.split("-");
      parts.shift();
      return parts.join("-");
    });
    _uniqueColors = _uniqueColors.filter(
      (c, i) => i === _uniqueColors.findIndex((_c) => _c === c)
    );

    // Extraire les clés de bgColors

    var combColors = [
      ..._uniqueColors,
      ...comboColors.filter((c) => !_uniqueColors.includes(c)),
    ];

    var palettes = [{}];
    /// Creation des palettes possibles    [1,0,2]   [0,1,3]   [0,1,4]   [0,1,5]     [0,1,6] =>  [0,2,1]    --  [5,4,3]

    for (var kcolor of _uniqueColors) {
      var newpalettes = [];
      for (let palette of palettes) {
        for (let comboColor of combColors) {
          if (Object.values(palette).includes(comboColor)) continue;
          var _palette = { ...palette, [kcolor]: comboColor };

          newpalettes.push(_palette);
        }
      }

      palettes = newpalettes;
    }




    var swapClasses = jClassColors(palette, classes, _uniqueColors);

    let actualPalette = palette ?? palettes[0];
    var colorsIndexedPalette = Object.values(actualPalette);
    // a partir des classes qu'on a récupéré on va chercher les objets qui ont ces classes
    classes.forEach((classe) => {
      let _classe = swapClasses[classe];
      let parts = _classe.split("-");
      let what = parts.shift();

      let color = parts.join("-");
      let iPalette = colorsIndexedPalette.indexOf(color);
      var objs = [ ...objTesting.querySelectorAll("." + _classe)];
      if(objTesting.classList.contains(_classe))  objTesting.classList.add(`${what}-palette-${id}-${iPalette}`);
      for (let objToTest of objs) {
        objToTest.classList.add(`${what}-palette-${id}-${iPalette}`);
      }
    });
    const res= getLiteStruct(template.structure)
    console.log({getLiteStruct:res,palettes});
    setLiteStruct (res)
    setPalettes(palettes);
  }, [id, template, objTesting, palette, templates]);

  var paletteValue = palette ?? Palettes[0];

const CmpLiteStruct = ({ struct,palette})=>{

  return <div  id="CmpLiteStruct" className={`w-full p-2  ${ sColor (struct.bg,palette) }  ${sColor(struct.border,palette)} rounded-md border-2 flex flex-col items-center gap-1 justify-center`} >  
  { struct.text.map((t)=><div key={t} className={`w-full ${sColor(t.replace("text-","border-"),palette)} text-xs text-center border-y-2 my-1`}   style={{ paddingTop:"5px"}}  ></div>   )  } 
  { struct.structs.map((s,i)=><CmpLiteStruct key={i}  struct={s}    palette={palette}   />   )  } 
   </div>
}



  return (
    <div className=" flex-none  w-full p-1 bg-white bg-opacity-90 m-2  flex flex-row items-center justify-center border border-white rounded-xl shadow">
      <div className=" w-full overflow-x-auto flex  gap-4 py-2">
        {Palettes.map((palettex, i) => (
          <div
            onClick={() => {
              if (JSON.stringify(palettex) === JSON.stringify(Palettes[0]))
                palette = null;
              onChangePalette(palettex);
            }}
            onMouseEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (timerPalette !== null) clearTimeout(timerPalette);
              setTimeout(() => testPalette(palettex), 200);
            }}
            onMouseLeave={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (timerPalette !== null) clearTimeout(timerPalette);
              setTimeout(() => testPalette(), 200);
            }}
            className={
              "appbutton flex flex-wrap shadow  w-full border  my-1  " +
              (JSON.stringify(paletteValue) === JSON.stringify(palettex)
                ? " border-black  bg-white bg-opacity-30"
                : " border-slate-100  opacity-50  ")
            }
            key={JSON.stringify(palettex)}
            id={JSON.stringify(palettex)}
          > 
            { Object.values(palettex).map((color) => (
              <div
                key={color}
                className={"hidden  w-3 h-3  bg-" + color}
              ></div>
            ))}
            <CmpLiteStruct  struct={liteStruct} palette={palettex}   />
          </div>
        ))}
      </div>
      <VarContent className={"hidden"} data={classColors} />
    </div>
  );
};

export default ShowPalette;
