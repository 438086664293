import { useEffect, useState } from "react"
import { data } from "../service/data"

import React from "react";
import { extractPathsFromSVG } from "./SvgTools/pathUtils";


const SvgAnimator =({ content_id,className,style,id ,t,onClick} )=> {
const [isReady,setIsReady]=useState()

const [svgPaths,setSvgPaths]= useState()
 
const [transitionPaths,setTransitionPaths]= useState()

const [animations,setAnimations] = useState()
const [loop,setLoop]=useState()
const [viewBox,setViewBox]=useState("0 0 512 512")
var currentTransition
//var t
function easeInOutWithBounce(t) {

  var tressort = 1 //0.9
  const amplitude =0//0.05
  const tp = 0.4
  if (t < tressort) {
    var t0 = t/tressort
    // Utilise une courbe d'ease-in-out classique jusqu'à 90% de la transition
    return (t0 < 0.5 ? 2 * t0 * t0 : 1 - 2*( 1- t0)*( 1- t0 ));
  } else {
    // Applique un effet de rebond pour les 10% restants de la transition


var xt = Math.pow((t-tressort)/0.1,tp)

    return 1 + amplitude * xt*(1-xt)/0.25; // Rebond de faible amplitude
  }
}

const  createTransition=(svg1,svg2)=>{
svg2 = svg2 ?? svg1
var c1 = extractPathsFromSVG(`<svg>${svg1.content.path}</svg>`,svg1.content.viewBox)

var c2 = extractPathsFromSVG(`<svg>${svg2.content.path}</svg>`,svg2.content.viewBox)



var nPath= Math.max( c1.length , c2.length  ) 
var invPath = c1.length <= c2.length ? -1:1
var tPaths =c1.length > c2.length  ?c1:c2
var oPaths =c1.length <= c2.length  ?c1:c2
 var transitionPaths =
tPaths.map((path,i)=>{


 var oPath = oPaths[ Math.min(  i,oPaths.length-1 )   ]



var refPath = path.length > oPath.length ? path : oPath
var offPath = path.length <= oPath.length ? path : oPath
var invPoint = path.length <= oPath.length ?-1 :1

return  refPath.map((point,j)=>{  
   var oPoint = offPath[  Math.min(j, offPath.length-1 )   ]

   return  { ...point,      point0: invPath*invPoint===1 ? point: oPoint,  point1: invPath*invPoint===1 ? oPoint: point      }

  } )
})




 
return transitionPaths

} 

const  setTransition=(transitionPaths,t)=>{

var paths=  transitionPaths.map((path,i)=>{


//  console.log({pts: path[0], a: Math.round( path[0].point0.fillr*(1-t)+ path[0].point1.fillr*t)})
 return `<path 
 fill="rgba(${
 Math.round( path[0].point0.fill.r*(1-t)+ path[0].point1.fill.r*t)},${
  Math.round(  path[0].point0.fill.g*(1-t)+ path[0].point1.fill.g*t)},${
    Math.round(    path[0].point0.fill.b*(1-t)+ path[0].point1.fill.b*t)},${
      Math.round(    path[0].point0.fill.a*(1-t)+ path[0].point1.fill.a*t)} )"
       stroke="rgba(${
        Math.round(  path[0].point0.stroke.r*(1-t)+ path[0].point1.stroke.r*t)},${
          Math.round(  path[0].point0.stroke.g*(1-t)+ path[0].point1.stroke.g*t)},${
            Math.round(    path[0].point0.stroke.b*(1-t)+ path[0].point1.stroke.b*t)},${
              Math.round(    path[0].point0.stroke.a*(1-t)+ path[0].point1.stroke.a*t)} )"
stroke-width="${
  path[0].point0.strokeWidth*(1-t)+ path[0].point1.strokeWidth*t}"
 d="${path.map((point,j)=>{
    if(point.type==="Z") return ""
  return `${point.type ?? ""}${(point.point0.x*(1-t)+point.point1.x*t ) },${(point.point0.y*(1-t)+point.point1.y*t )} `
}   ).join("")}Z"/>`   }).join("");


  setSvgPaths(paths)
} 

useEffect(()=>{

        const initSvg= async ()=>{
          
            var obj = await data.getContent(content_id,true)
console.log(obj)
            var svgs = []
          
            var startSVG =await data.getContent(obj.content.Start.content_id,true) 
            svgs.push(startSVG);
setViewBox(startSVG.content.viewBox)
            var alist = JSON.parse( JSON.stringify( obj.content.Animation));
            console.log({alist})
            for(let i in alist){
              
              var animation = alist[i]
if(!animation) continue

              animation.paths =  createTransition(svgs[svgs.length-1])
              if(  animation.SVG  ) {
                var svgObject = await data.getContent( animation.SVG.content_id,true) 
           if(svgObject){
                alist[i].transitionPaths = createTransition(svgs[svgs.length-1],  svgObject ) 

                svgs.push(svgObject);
           }
              }

            }
console.log({alist})
            setTransitionPaths(alist)
           
            setSvgPaths(startSVG.content.path); 
         setIsReady(true)
         setLoop(obj.content.Loop)
        }


  initSvg()


},[content_id])
const transformPoint = (point,transform,t)=>{

  const centerX = 256;
  const centerY = 256;
  const angleRadians =     (transform?.angleDegrees?? 372)*t * (Math.PI / 180); // Conversion de l'angle en radians

  // Calcul des nouvelles coordonnées
  const rotatedX =
    centerX + (point.x - centerX) * Math.cos(angleRadians) - (point.y - centerY) * Math.sin(angleRadians);
  const rotatedY =
    centerY + (point.x - centerX) * Math.sin(angleRadians) + (point.y - centerY) * Math.cos(angleRadians);

  return {...point, x: rotatedX, y: rotatedY };
}

const setTransformation= (  transitionPaths,t)=>{

    var paths=  transitionPaths.paths.map((path,i)=>{
    
    
    //  console.log({pts: path[0], a: Math.round( path[0].point0.fillr*(1-t)+ path[0].point1.fillr*t)})
     return `<path 
     fill="rgba(${
     Math.round( path[0].fill.r)},${
      Math.round(  path[0].fill.g)},${
        Math.round(    path[0].fill.b)},${
          Math.round(    path[0].fill.a)} )"
           stroke="rgba(${
            Math.round(  path[0].stroke.r)},${
              Math.round(  path[0].stroke.g)},${
                Math.round(    path[0].stroke.b)},${
                  Math.round(    path[0].stroke.a)} )"
    stroke-width="${
      path[0].strokeWidth}"
     d="${path.map((point,j)=>{
         point = transformPoint(point, transitionPaths.transform,  t)
         if(point.type==="Z") return ""
      return `${point.type ?? ""}${(point.x ) },${(point.y )} `
    }   ).join("")}Z"/>`   }).join("");
    
    
      setSvgPaths(paths)
     
}
var animate_timer 
const animate= (start,t)=>{

  if(!transitionPaths || transitionPaths.length===0) return
 // console.log("Animate",{t,currentTransition})
     if(start && typeof currentTransition==="number") return 
if(animate_timer) clearTimeout(animate_timer)
     if( typeof currentTransition!=="number")
     {  
      currentTransition=0
      t=0

     }
    if(transitionPaths[currentTransition].transitionPaths)  setTransition(transitionPaths[currentTransition].transitionPaths,  easeInOutWithBounce (t))
   
   else  setTransformation(transitionPaths[currentTransition],  easeInOutWithBounce (t))
      const step =10.0
    t+= step/(1000.0* transitionPaths[currentTransition].duration)

    if(t>=1){
      currentTransition++
      t=0
    }
    if(currentTransition=== transitionPaths.length) {
    if (loop) {
      currentTransition =null
      setLoop(loop-1) ;
      animate_timer= setTimeout(()=>animate(true),step);
      
    } return 
    }
    animate_timer=    setTimeout(() => animate(null,t), step);

}

useEffect( ()=>{
  if(isReady) setTimeout(()=>animate(true),1000);
},[isReady])

if(!svgPaths) 
    return <></>
 if (svgPaths) {


    return (
      <svg
    
        id={id}
        className={" svg-inline--fa  inline-block " +className}
        style={style}
        onClick={  onClick ? onClick :   ()=>animate(true,0)}
          fill="none"
        aria-hidden="true"
        focusable="false"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={viewBox}
        dangerouslySetInnerHTML={{ __html: svgPaths }}
        
      />
    )
  }

return null

}

export default SvgAnimator