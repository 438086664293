
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import ContentEdition from "../../../../components/edition/ContentEdition";
import VarContent from "../../../../components/VarContent";
import { useContext, useEffect, useMemo, useState } from "react";
import { getProps } from "../../../../tools/tpl";
import { MfctContext } from "../../../../context";
import InputSelect from "../../../../components/inputs/InputSelect";
import { data } from "../../../../service/data";
import { getFilterRecord, setRecord } from "../../../../service/localdata";




const ShowContent = ({setShow,schemas,content,onChangeContent,page,template,templates,app_id,allPropsFromTemplates}) => {
  
  const {
    project,



    cache,

  } = useContext(MfctContext);

const [propsTemplates,setPropsTemplates] =useState()
const [contents,setContents] =useState([])

const addTemplateContent =async ()=>{


  var _content =  await project.api("/set_content", { body: {
    title : "Data Bloc Template - " + template.title,
    template_id: template.template_id , app_id,content:{  title : "Data Bloc Template - " + template.title,}
  } });
  await setRecord(  _content,"content")
  await data.updateContent(_content)
  onChangeContent(_content.content_id);
  
  }

  
useEffect(() => {



  const computeProps = async () => {

    if (!template ) return

    var _propsTemplates =  template.props_from_templates? template.props_from_templates[template.template_id]?.props:null
    if(_propsTemplates) _propsTemplates = Object.values( _propsTemplates).filter(p=>p.from==="schema_label" 
      || p.from==="schema_link"
      || p.from==="schema_url"
      
      || p.from==="schema_suid"
      
      
    )
    if(!_propsTemplates)
  _propsTemplates= await getProps(template.structure, cache, templates, project, template.template_id);

    console.log("Recalculating props...",_propsTemplates)
    if( template.schema_id)
    {
    
   //  _propsTemplates.push({ name: schemas.find(s=>s.content_id+""===""+ template.schema_id )?.title?? "S " +template.schema_id  ,  self:true, schema_id : template.schema_id   })

    }
    
    console.log("_propsTemplates",schemas, _propsTemplates)
 var _contents = await getFilterRecord((c)=>c.app_id+""===app_id+"" && ( c.template_id+""===""+template.template_id || (content && c.content_id===content.content_id)) ,"content")

    setContents(_contents)
    setPropsTemplates(_propsTemplates)

  }

  computeProps()


}, [template,schemas])

if(!propsTemplates) return <></>
    return    <div className=" flex-none  w-full  bg-white m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
      <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
      <FontAwesomeIcon icon={fa.faDatabase}></FontAwesomeIcon>
        Données 
        <FontAwesomeIcon icon={fa.faClose}  onClick={ ()=> setShow((show) => ({ ...show, Data: !show.Data })) }  className="appbutton"  ></FontAwesomeIcon>
        </div>

        <div className="flex flex-col ">
        { propsTemplates.length>0 ?  <div className="flex flex-row ">
                                <InputSelect
                                  className={"  mb-2  md:my-2     w-full"}
                                  label={"Data "}
                                  options={contents.map((c) => {
                                    return {
                                      value: c.content_id,
                                      label: c.title  ,
                                    };
                                  })}
                                  placeholder={"Choisis les données"}
                                  value={content ? content?.content_id:null}


                                  
                                  onChange={onChangeContent}
                                />

                                {content?.content_id && (
                                 
                                    <FontAwesomeIcon  onClick={() => {onChangeContent()
                                    }}  className="p-2 text-xl" icon={fa.faClose} />
                                 
                                )}
                                {!content?.content_id && (

                                    <FontAwesomeIcon
                                    onClick={ addTemplateContent  }  
                                      icon={fa.faPlus}
                                      className="p-2 rounded-full text-white bg-orange-500 "
                                    />
                           
                                )}
                              </div>
:     <>Aucune donnée necessaire</>
}
      { content &&  <ContentEdition
        key={"setContent"}
        defaultContent={ content }
        _schemas={schemas}
        app_id={content.app_id}
        template_id={template.template_id}
        dataOnly={true}
       nosave={true}
    updateLiveContent={(content=>{  data.updateContent(content)  })}
          data_schema={propsTemplates}
        context = { {  AvailablesParams:propsTemplates,page}}
        />}


    </div>

        </div>
      };
  


      export default ShowContent