import html2canvas from "html2canvas";
const color = [Math.round(360 * Math.random()), Math.round(0 * Math.random() + 80), Math.round(30 + 12 * Math.random())]
var setBackgroundActive =false; 
var timerBackground

const timingBackground = ()=>{
if( timerBackground) clearTimeout(timerBackground);
timerBackground = setTimeout( ()=>setBackground(),100);
}

export const setBackground = (c) => {

      document.body.style.backgroundColor ="#353068"
      return 
   timerBackground=null;
   if(setBackgroundActive) return 
   setBackgroundActive=true;

   
   if(    c ){
      document.body.style.backgroundColor =c
      var obj = document.getElementById("bg_app");
      if (obj) obj.parentNode.removeChild(obj)   
      setBackgroundActive=false;
      return;
   }
   console.log('setBackground ', Date.now())
   var deg    
   document.body.classList.add('min-h-visible')
   document.body.classList.add('relative')
   var obj = document.getElementById("bg_app");
   if (!obj) {
      deg = Math.random() * 360
      var bg = `linear-gradient(${deg}deg, hsl(${color[0]},${color[1]}%,${color[2]}%)  0% ,  hsl(${color[0]},${color[1]}%,${color[2] * 0.5}%) 100%)`;
    
     
      obj = document.createElement("div");
      obj.classList.add('w-screen')
      obj.classList.add('h-visible')

      obj.style.position = 'fixed';
      obj.id = "bg_app";
      obj.style.background = bg
    
      obj.style.top = "0px"
      obj.style.left = "0px"
      obj.style.overflow = "hidden"
      document.body.insertBefore(obj, document.body.firstChild)
     obj.style.zIndex = -20;

   }
   

   for (var i = 0; i < 10; i++) {
      deg = Math.random() * 360
      var elt = document.getElementById("bg_app_" + i);
      if (!elt) {
         elt = document.createElement("div");
         elt.style.position = 'absolute';
         elt.classList.add("transition")
         elt.classList.add('transition-all')
        elt.style.transitionDuration= "60s";
         elt.id = "bg_app_" + i;
         elt.style.background = bg
         var size = 50 * Math.random() + 'vw'
         elt.style.width = size;
         elt.style.height = size;
         elt.classList.add('transition-all')
         elt.style.transitionDuration= "60s";
         obj.appendChild(elt)
      }
      
      elt.style.left = Math.round(document.body.clientWidth * Math.random()) + 'px';
      elt.style.top = Math.round(document.body.clientHeight * Math.random()) + 'px';
      elt.style.transform = `rotate(${90 * Math.random()}deg)`
      elt.style.zIndex = -10;
      elt.style.borderRadius = 50 * Math.random() + '%';
      var bg = `linear-gradient(${deg}deg, hsla(${color[0]},${color[1] * Math.random()}%,${color[2]}%,0.5)  0% ,  hsl(${color[0]},${color[1] * Math.random()}%,${color[2] * 0.5}%,0.5) 100%)`;
    

   }
         
   window.removeEventListener('resize', timingBackground);
   window.addEventListener('resize', timingBackground);
   setBackgroundActive=false;
}


function dataURItoBlob(dataURI) {
   // Séparer le type de données de la base64
   var byteString = atob(dataURI.split(',')[1]);

   // Séparer le type de données du reste de la base64
   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

   // Convertir la base64 en tableau d'octets
   var ab = new ArrayBuffer(byteString.length);
   var ia = new Uint8Array(ab);
   for (var i = 0; i < byteString.length; i++) {
       ia[i] = byteString.charCodeAt(i);
   }

   // Créer un Blob avec les données et le type MIME
   var blob = new Blob([ab], { type: mimeString });
   return blob;
}

export const  captureContent=(id,path,filename, project)=>{

   var content = document.getElementById(id);



try{

     html2canvas(content  , { letterRendering: 1,
      allowTaint: true,
    
      useCORS: true,
  //    proxy: 'votre_proxy_url',
      imageTimeout: 15000
       , onrendered     : function (canvas) {                 } } ).then(canvas=>{
// Créer un canvas avec les dimensions de l'élément à capturer
//var canvas = document.createElement('canvas');
//canvas.width = content.offsetWidth;
//canvas.height = content.offsetHeight;
// Dessiner le contenu de l'élément sur le canvas

// Redimensionner l'image
var resizedCanvas = document.createElement('canvas');
var resizedCtx = resizedCanvas.getContext('2d');
var maxWidth = 1024; // Largeur maximale souhaitée
var maxHeight = 1024; // Hauteur maximale souhaitée
var ratio = Math.min(maxWidth / canvas.width, maxHeight / canvas.height);

resizedCanvas.width = canvas.width * ratio;
resizedCanvas.height = canvas.height * ratio;
resizedCtx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, resizedCanvas.width, resizedCanvas.height);

// Convertir le canvas redimensionné en une image base64
var dataURL = resizedCanvas.toDataURL('image/png');


var file = new File( [dataURItoBlob(dataURL)]   , filename)
project.uploadFile( [file] , ()=>{}, path,true).then( results=>{

   if( results && results.length===1){
     console.log( results[0]);
 
   }
  })
     })

}
catch(err){

   console.log(err)
}

}

