import React, { useEffect, useMemo, useState, useContext } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import InputText from '../../../../components/inputs/InputText';
import InputImage from '../../../../components/inputs/InputImage';
import InputColor from '../../../../components/inputs/InputColor';
import InputSelect from '../../../../components/inputs/InputSelect';
import InputTextArea from '../../../../components/inputs/InputTextArea';
import InputButton from '../../../../components/inputs/InputButton';


import '../../../../App.css';
import { MfctContext } from "../../../../context";
import { data } from '../../../../service/data';
import VarContent from '../../../../components/VarContent';
import InputData from '../../../../components/inputs/InputData';
import LoaderSmall from '../../../../components/LoaderSmall';
import { retrieveProject } from '../../../../service/mftc-api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faEye, faPlay, faStop, faSync, faTrash } from '@fortawesome/free-solid-svg-icons';

function AppEnv({ app, getApps  ,loading,setLoading   }) {


  const { project, getProject, setAuthUser, authUser } = useContext(MfctContext);
  const [mods, setMods] = useState(app);

  const [isInstalled,setIsInstalled]=useState()
  const [urlEnv,setUrlEnv]=useState()
  const [urlBuild,setUrlBuild]=useState()
  const [roles,setRoles]= useState([])


const realUrl = (process)=>{
  if(!process) return null
  if(  window.location.href.split(":30").length > 1  ) 
    {
      // localhost
      return process.url
    }
 // www.appisyou.com
return process.urlWWW
}

const realUrlBuild = (process)=>{
  if(!process) return null
  if(  window.location.href.split(":30").length > 1  ) 
    {
      // localhost
      return process.urlBuild
    }
 // www.appisyou.com
return process.urlBuildWWW
}
useEffect(()=>{
  const statusApp = async ()=>{

    var result = await retrieveProject().api("/gen_env_status",{body:{environment:"app_" + app.app_id}})
  
  setUrlEnv( realUrl(   result))
  setUrlBuild(realUrlBuild(result))
  setIsInstalled(result?.isInstalled)
  }

  if(!loading) statusApp()

},[loading,app.app_id])



const startApp = async ()=>{
setLoading(true)
  var starting = await project.api("/gen_env_start",{body:{environment:"app_" + app.app_id}})
setLoading(false)
if(starting?.url){
   setUrlEnv( realUrl(   starting))

window.open(  realUrl(   starting) , "app_"+app.app_id   )
}

}

const updateApp = async()=>{
  setLoading(true)
  var starting = await project.api("/gen_env_update",{body:{environment:"app_" + app.app_id}})
setLoading(false)
if(starting?.url){
   setUrlEnv( realUrl(   starting))
window.open( realUrl(   starting) , "app_"+app.app_id   )
}

}


const buildApp = async ()=>{
  setLoading(true)
  var building = await project.api("/gen_env_build_create",{body:{environment:"app_" + app.app_id}})
  setLoading(false)

   setUrlBuild(building?.urlBuild)
//window.open( building?.url   )


}

const unInstallApp = async ()=>{
  setLoading(true)
  var result = await project.api("/gen_env_uninstall",{body:{environment:"app_" + app.app_id}})
  setLoading(false)

  setUrlEnv( realUrl(   result))
  setUrlBuild(result?.urlBuild)
  setIsInstalled(result?.isInstalled)
}

const deletebuildApp = async ()=>{
  setLoading(true)
  var building = await project.api("/gen_env_build_delete",{body:{environment:"app_" + app.app_id}})
  setLoading(false)

   setUrlBuild(building?.urlBuild)
//window.open( building?.url   )
}






const stopApp = async ()=>{
  setLoading(true)
var result = await project.api("/gen_env_stop",{body:{environment:"app_" + app.app_id}})
setLoading(false)
setUrlEnv( realUrl(   result))
setUrlBuild(result?.urlBuild)
setIsInstalled(result?.isInstalled)
}




  return (

    <div className=' bg-white z-50 shadow-inner border   sticky top-20 my-2 w-full flex flex-row gap-1 text-xs bg-white text-black px-4 rounded-xl justify-between' >
 
  {  loading ?   <div className='w-full flex justify-center'><LoaderSmall/></div> 

  :
  <>
 <div className=' flex flex-col md:flex-row gap-1 py-4 bg-white text-black px-1 rounded-xl items-center' >
  <div >
 Exécution & débogage
</div>
<div className='flex flex-row gap-1' >
{  !urlEnv && <div   className='bg-blue-700 text-white   p-2  '     onClick={startApp} > <FontAwesomeIcon icon={faPlay} /> </div>  }
{  isInstalled && <div   className='bg-green-700 text-white   p-2  '     onClick={updateApp} > <FontAwesomeIcon icon={faSync} /> </div>  }
{  urlEnv && <a  target={ "app_"+app.app_id } rel="noreferrer" className='bg-blue-800 text-white   p-2  '  href={ urlEnv   }  ><FontAwesomeIcon icon={faEye} /></a>  }
{  urlEnv && <div   className='bg-red-700 text-white   p-2  '     onClick={stopApp} > <FontAwesomeIcon icon={faStop} /></div>  }
{  !urlEnv  && isInstalled   && <div   className='bg-red-700 text-white   p-2  '     onClick={unInstallApp} > <FontAwesomeIcon icon={faTrash} /></div>  }
</div>
</div>
<div className='flex flex-col md:flex-row   gap-1 py-4 bg-white text-black px-1 rounded-xl items-center' >
<div >
  Build 
</div>
<div className='flex flex-row gap-1' >
{   <div   className='bg-orange-700 text-white   p-2  '     onClick={buildApp} > <FontAwesomeIcon icon={faBuilding} /></div>  }
{  urlBuild &&  <a  target='_blank' rel="noreferrer" className='bg-green-700 text-white   p-2  '  href={     urlBuild  }  ><FontAwesomeIcon icon={faEye} /></a>  }
{  urlBuild && <div   className='bg-red-700 text-white   p-2  '     onClick={deletebuildApp} > <FontAwesomeIcon icon={faTrash} /></div>  }
</div> </div>
    </>} 
      
     
    </div>
  );
}

export default AppEnv;
