import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";

import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";
import VarContent from "../../../../../components/VarContent";
import InputData from "../../../../../components/inputs/InputData";



const ShowTemplateEventActions =( { setShow ,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,key_schemas,context,strActions,selectTpl} )=>{

//const  [strActions,setStrActions]=useState([])

const setAction = (id,index,ea)=>{


var structure = getStructure( template.structure, id  )

structure.actions[index]=ea

updateTemplateStructure({ actions:structure.actions }, id)

}

    if(!selected ) return <></>  

    return  <div id={"ShowTemplateEventActions"} className=" flex-none w-80  text-black bg-white m-2 flex flex-col items-center justify-center border border-white rounded-xl shadow">
  
    <div className="flex justify-between items-center text-xs w-full p-2 text-white bg-black rounded-xl mb-1 ">
    <FontAwesomeIcon icon={fa.faPlay}></FontAwesomeIcon>
    Toutes les Actions 
      <FontAwesomeIcon icon={fa.faClose}   onClick={ ()=> setShow((show) => ({ ...show, EventAction: !show.EventAction })) }  className="appbutton"  ></FontAwesomeIcon>
      </div>
   {strActions.map((a,i)=><div key={i}>

<div className="w-full  flex flex-col border-b my-2 items-start ">
 <div className="p-2 font-bold appbutton w-full bg-zinc-200"  onClick={()=>selectTpl(a.id)} > { a.id} / { a.index }</div>
<InputData    key={i}
                                id={i + "_event_"}
                  className={"w-full"}
                  prop={{ name: "Event Action", define: true,define_open:true  }}
                  schema={{ content_id: 440 }}
                  value={a.ea}
                  onChange={(value) => setAction( a.id, a.index,value )}
                  context={{template,schemas}} />
   </div>
   </div>)}
   
   </div>
  }

export default ShowTemplateEventActions


