import {  useNavigate } from "react-router-dom";

import { useContext } from "react";
import { MfctContext } from "../../context";

import { data } from '../../service/data';
import UserThumb from '../thumbs/UserThumb';
import AppThumb from '../thumbs/AppThumb';


function TemplateDisplay({ template,onClick,className,app_id}) {

const { authUser } = useContext(MfctContext);
const navigate = useNavigate()





    return <div className='relative w-full p-0 flex flex-col items-center justify-between h-full group' onClick={   onClick ? onClick: ()=> data.isGranted( authUser, template,"template","edit")  && navigate(`/template/${template.template_id}`)  } >

      <div  className='w-full flex items-center justify-center grow   appbutton relative' style={{maxHeight:"300px"}} >
         <img 
            src={process.env.REACT_APP_MFTC_HOST_FILES + "users/" + template.owner_id + "/template_" + template.template_id + "_thumb.png"}
            className={className ?? 'object-contain w-full h-full max-w-[300px] max-h-[300px]   '}
            style={{ width: 'auto', height: 'auto' }}
            alt='thumb'
         />
    </div>
    <div  className='absolute top-2 left-2 ml-4 p-2 items-center  text-xs  flex w gap-2 bg-black bg-opacity-0 group-hover:bg-opacity-60 transition-opacity duration-300 rounded-xl'>
        
        { app_id !==  template.app_id &&
  template.app_id && <AppThumb app_id={template.app_id} className={" inline shadow-sm text-xs"} size={ "w-5 h-5"} />
  
  } {template.title} ({   template.layout})</div>

<UserThumb
                  key={template.owner_id }
                  className={"text-xs  absolute bottom-2 right-2 shadow rounded-full"}
                  user_id={template.owner_id}
                  noname={true}
                />
                </div>



}

export default TemplateDisplay;
