import React, { useEffect, useMemo, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import {  getStructure } from "../../../../../tools/tpl";
import ButtonTool from "../../../../../components/ButtonTool";
import SelectDropDown from "../../../../../components/inputs/SelectDropDown";
import { retrieveProject } from "../../../../../service/mftc-api";
import useDynamicTextColor from "../../../../../tools/useDynamicTextColor";
import Icon from "../../../../../components/Icon";
import TemplateDisplay from "../../../../../components/templates/TemplateDisplay";
import ContextToolSize from "./ContextToolSize";
import ContextToolText from "./ContextToolText";
import ContextToolSpacing from "./ContextToolSpacing";
import ContextToolColor from "./ContextToolColor";
import ContextToolData from "./ContextToolData";
import { getAvailableActions } from "../../../../../tools/actions";
import { getClassColorDefined } from "../../../../../tools/colors";
import ContextToolAdd from "./ContextToolAdd";
import ContextToolInput from "./ContextToolInput";
import ContextToolLayout from "./ContextToolLayout";
import ContextToolActions from "./ContextToolActions";
import VarContent from "../../../../../components/VarContent";
import ContextToolClean from "./ContextToolClean";

const ContextTools = {
  
  "Edit": { component :ContextToolInput, titre :"Edition", icon : fa.faPen    , default:true,condition:(str)=>(str.schema_id || str.template_id)&&  !str.schema_prop && !str.schema_label },
 "Size": { component :ContextToolSize, titre :"Dimensions", icon : fa.faMaximize,media:true },
  "Texte": { component :ContextToolText, titre :"Texte", icon : fa.faFont ,media:true},
  "Color": { component :ContextToolColor, titre :"Couleurs", icon : fa.faPalette,media:true },
"Color-text": {  component :ContextToolColor, titre :"Couleurs" ,props:{ prefixe_filter:"text"  },media:true},
"Color-bg": { component :ContextToolColor, titre :"Couleurs"  ,props:{ prefixe_filter:"bg"  } ,media:true},
"Color-border": { component :ContextToolColor, titre :"Couleurs"  ,props:{ prefixe_filter:"border"  },media:true},
"Layout": { component :ContextToolLayout, titre :"Mise en page", icon : fa.faAlignCenter,media:true , condition:(str)=>!str.schema_id &&  !str.template_id && str.children.length>0},


"Spacing": { component :ContextToolSpacing, titre :"Marges", icon : fa.faBorderAll ,media:true},

//"Etat": { component :ContextToolSize, titre :"États", icon : fa.faQuestion ,media:true},
"Action": { component :ContextToolActions, titre :"Actions", icon : fa.faPlay, nb: (str)=>(str.schema_link?1:0)+(str.schema_url?1:0)+ (str.vars?.length??0)+   (str.computed?.length??0)+  (str.actions?.length??0)},
//"Data": { component :ContextToolData, titre :"Donnée", icon : fa.faDatabase, nb: (str)=>str.actions.length},
//"Attributs": { component :ContextToolData, titre :"Attributs", icon : fa.faHashtag, nb: (str)=>str.actions.length},
"Ajout": { component :ContextToolAdd, titre :"Insérer", icon : fa.faPlus ,default:true,},
"Clean": { component :ContextToolClean, titre :"Nettoyer", icon : fa.faBrush ,default:true,},
//"CSS": { component :ContextToolData, titre :"CSS", icon : fa.faCode, nb: (str)=>str.actions.length}
}


const Context_ToolManager= ((  {mq,setShow ,showStyle ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app,updateApp,templateContent,updateContent,key_schemas,suidTemplates,propsTemplates} )=>{

  const [showContext,setShowContext]=useState()



const ContextToolShow = useMemo(()=>{
  return showContext ?  ContextTools[showContext]?.component:null
},[showContext])


const propsContext = useMemo(()=>{
  return showContext ?  ContextTools[showContext]?.props:{}
},[showContext])

const structure = useMemo(()=>{
if(template?.structure)
return getStructure(template?.structure,selected)

},[template?.structure,selected])

const [options,setOptions]=useState([])

const onChangeStructure=(value)=>{

//  window.alert(JSON.stringify(value?.structure))
  if(value?.structure) {
    if(value.structure.schema_prop && structure.actions) value.structure.actions =structure.actions.filter(ea=>ea.Event!=="onValue" )
      if(value.structure.schema_prop && value.structure.actions) value.structure.actions =value.structure.actions.filter(ea=>ea.Event!=="onValue" )
   else if(value.structure.actions && structure.schema_prop) value.structure.schema_prop=null
    
      updateTemplateStructure(  {...structure,...JSON.parse( JSON.stringify(  value.structure))},selected)
  
  }

}
useEffect(()=>{
  const  load = async()=>{

    if(!template) return 
  var options=[{ title:"Structure HTML",  type:"std" ,id:"struct", structure: { tagName:"div", template_id:null,schema_id:null,schema_prop:null,isList:null  }}]

  if(template.schema_id){
     options=[...options, { title:"self",  type:"prop" ,id:"self", prop:{name:"self", type_nombre:template.is_list?"F":"" ,schema_id: template.schema_id  ,prop_schema_id:template.schema_id ,is_list:template.is_list}, structure: { tagName:"div", template_id:null,schema_id:template.schema_id,schema_prop:"self",isList:template.is_list,children:[]   }}]
  
  if(!template.is_list)
  {
    options=[...options, { title:"Titre",  type:"prop" ,id:"titre", prop:{name:"Titre" ,schema_id: 42  ,prop_schema_id:42,is_list:template.is_list}, structure: { tagName:"div", template_id:null,schema_id:42,schema_prop:"Titre",children:[]   }}]
  
  }
  
    }
                             // liste des propriétés      => template
                             // liste des propriétés du template    => template
                           // liste des schemas value ayant des templates  => template
                           if (globalSchema && !template.is_list) 
                            {
var scheme =schemas.find(s=>s.content_id ===globalSchema.content_id)
if(!scheme.content.is_value)
  {                         var _result = await retrieveProject().api("/get_props/:schema_id", {
                             args: { schema_id: globalSchema.content_id },
                           });

         options = [...options, ..._result.map(p=>({ title:p.prop_title ,type:"prop", id:"prop"+p.prop_id , structure:{ schema_prop : p.prop_title, schema_id :p.prop_schema_id ,children:[]     }, prop:p    })     )  ]
                          }
                          
                          }

                          if(template.props){
                            options = [...options, ...template.props.filter(p=>p.from!=="schema_suid" && p.schema_id ).map(p=>({ title:p.name  ,type:"template_prop", id:"prop"+p.prop_id , structure:{ tagName:"div",schema_prop : p.name, schema_id :p.prop_schema_id ,isList: p.type_nombre==="F" ||p.type_nombre==="I" ,children:[]    }, prop:p    })     )  ]
                           }
            
                           if(schemas){
                            options = [...options, ...schemas.filter(s=>s.content.is_elementary).map(p=>({ title:p.title  ,type:"schema_value", schema:p,id:"schema"+p.content_id , 
                              structure:{tagName:"div", schema_prop :null, schema_id :p.content_id,children:[]   }  })     )  ]
                           }
// Ajout du menu action computes
 options.push({ title:"Données Calculées"  ,type:"schema_computed" ,  
  schema: schemas.find(c=>c.content_id===428),
                              structure:{actions:[...(structure.actions??[]) ]   }  })    

  
                          
                  

            // liste des schemas // application ayant des templates
            if(schemas){
              options = [...options, ...schemas.filter(s=>(s.app_id+""===""+template.app_id || s.is_public)  &&  templates?.find(tpl=>tpl.schema_id ===s.content_id   && tpl.app_id===template.app_id ) ).map(p=>({ title:p.title  ,type:"schema", schema:p,id:"schema"+p.content_id , structure:{tagName:"div", schema_prop :null, template_id:null,schema_id :p.content_id ,children:[]   }  })     )  ]
            
            }

                             // liste des schemas objet ayant des templates

                             // liste des templates in qui n'ont pas de schéma
 if(templates){
              options = [...options, ...templates.filter(s=>s.app_id+""===""+template.app_id && !s.schema_id && s.layout==="in").map(p=>({ title:p.title  ,template:p,type:"template", id:"template"+p.template_id , structure:{ tagName:"div", schema_prop :null, template_id :p.template_id ,schema_id:p.schema_id,children:[] }  })     )  ]
          
              options = [...options, ...templates.filter(s=>s.is_public &&  s.app_id+""!==""+template.app_id && !s.schema_id && s.layout==="in").map(p=>({ title:p.title  ,template:p ,type:"template", id:"template"+p.template_id ,  structure:{tagName:"div", schema_prop :null, template_id :p.template_id ,schema_id:p.schema_id,children:[] } })     )  ]
          
            }



                   setOptions(options)         
                          }

                          load()

},[template?.schema_id,template?.props,globalSchema,templates,schemas,template?.app_id])


const optionsTemplate=useMemo(()=>{ 

return structure && templates?.filter(tpl=> tpl.schema_id+"" ===""+ structure.schema_id &&  template.template_id !==tpl.template_id && ( !tpl.is_list|| !tpl.is_list===!structure.isList    )&& tpl.layout==="in").map(tpl=>
  ({  title:tpl.title  ,type:"template", id:"template"+tpl.template_id ,template:tpl, structure : { ...structure   , template_id:tpl.template_id} }))




},[templates,template,structure])


useEffect(()=>{

 


},[])
//   si template.schema_id   // structure 
                             // liste des propriétés      => template
                             // liste des propriétés du template    => template
                             // liste des schemas value ayant des templates  => template
                             // liste des schemas // application ayant des templates
                             // liste des schemas objet ayant des templates
                             // liste des templates in qui n'ont pas de schéma


const TemplateOptionRender = ({value,fieldSearch, onClick }) =>{


return   <div key={value.id} className="text-left p-2 rounded-lg my-2 text-white bg-black border-gray-500 border-t border-opacity-25 appbutton " onClick={()=>onClick(value  )} > 
  <TemplateDisplay app_id={template.app_id} className=" w-'object-contain w-full h-full  max-h-[300px]  " template={value.template}  onClick={()=>{}}  /> </div>

                             }
const valueText =useMemo(()=>{
if(!structure) return ""
var t= []

  if(structure.schema_id) t.push( schemas?.find(s=>s.content_id+""===""+structure.schema_id )?.title   )
    if(structure.schema_prop) t.push(structure.schema_prop)
      else if(structure.actions && structure.actions.find(ea=>ea.Event==="onValue" && ea.Action))  t.push(structure.actions.find(ea=>ea.Event==="onValue").Action.title+"()")
    if(t.length===0 & structure.template_id) t.push( templates?.find((tpl=> structure.template_id ===tpl.template_id) )?.title   )
return t.length>0 ?   t.join(" / ")  :"Structure HTML"
},[structure,templates,schemas ])

const valueTemplate =useMemo(()=>{
  if(!structure) return ""
  var t= []
      if(structure.template_id) t.push( templates?.find((tpl=> structure.template_id+"" ===""+tpl.template_id) )?.title   )
  return   t.join(" / ") 
  },[structure,templates ])


const DefaultOptionRender = ({value,fieldSearch, onClick }) =>{

  const [showActions,setShowActions]=useState()
useEffect(()=>{

  const getActions=async ()=>{
    var a = await getAvailableActions()
    a = a.filter(a=>   !a.content.asynchrone && a.content.Arguments?.find(arg=>arg.type_put==="out")  ).map(action=>{
      var schema_id= action.content.Arguments?.find(arg=>arg.type_put==="out").schema_id
      var Arguments={}

      
      var asynchrone;
      var Imports = [];
      
        action.Arguments =  {};
  
        action.content.Arguments?.forEach((element) => {
          Arguments[element.name] = {
            ...element,
          };
        });
        asynchrone = action.content.asynchrone;
        action.content.Code.forEach((c) => {
          if (!c.back && c.import) Imports = [...Imports, ...c.import];
        });
      
     var Action = {
      action_id :action.content_id,

      auth:action.content.auth,
        title: action.title ,
         Arguments,
        asynchrone,
        Imports,
      };

      return {
      ...action , params: `(${action.content.Arguments?.filter(arg=>arg.type_put==="in").map(a=>a.name).join(",")})` ,
      
      schema: schemas.find(s=>s.content_id+""===""+schema_id),
    structure : {schema_prop:null,template_id:null, schema_id , actions:[ { Event:"onValue",Action },...(structure.actions??[]).filter(ea=>ea.Event!=="onValue") ]}  
    }}   )
    setComputes(a)
  }
if(showActions)getActions()

},[showActions])
  const [showTpl,setShowTpl]=useState()
  const [computes,setComputes]=useState()
  let schema
  let tpls
  const elementRef = useRef(null);
  const {textColor,backgroundColor } = useDynamicTextColor(elementRef);
  switch(value.type)
  {
   case "schema_computed":
    schema =value.schema
   return <>
   <div className="w-full flex items-center appbutton" onClick={()=>setShowActions(!showActions)} >
     
     <div className="grow w-full text-left p-2  bg-pink-200 font-bold text-gray-500  border-t border-opacity-25 flex gap-2 items-center  "  ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>
     <Icon icon= {value.schema.content.icone} className={"w-6 h-6"}/>
     { (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} 
  </div>
  { <FontAwesomeIcon className="p-2 h-6  bg-pink-200  " icon={showActions ? fa.faChevronCircleUp :fa.faChevronCircleRight}   />}

 </div>
 {   showActions && computes && computes.map(compute=> 
    <div key={compute.content_id} className="w-full flex gap-1 items-center">
     
    <div className="grow w-full text-left p-2  bg-pink-100 text-gray-500  border-t border-opacity-25 flex gap-2 items-center appbutton " onClick={  compute.schema.content.is_value ? ()=>  onClick(compute): null} ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>
    <Icon icon= {compute.schema.content.icone} className={"w-6 h-6"}/>
    <span className="">{ compute.title } <span className="text-xs">{compute.params}</span></span> 
 </div>
 { <FontAwesomeIcon className="p-2 h-6  bg-pink-100 appbutton " icon={showTpl=== compute.content_id? fa.faChevronCircleUp :fa.faChevronCircleDown} onClick={()=>setShowTpl(showTpl=== compute.content_id ? null :compute.content_id)}   />}

</div>
 )   }
 
     </>
case "schema":
  schema =value.schema
  tpls=  templates?.filter(tpl=> tpl.schema_id+"" ===""+ schema.content_id &&  template.template_id !==tpl.template_id && ( !tpl.is_list|| !tpl.is_list===!structure.isList    )&& tpl.layout==="in")
  return <>
  <div className="w-full flex gap-1 items-center">
    
    <div className="grow w-full text-left p-2  bg-green-100 text-gray-500  border-t border-opacity-25 flex gap-2 items-center appbutton " onClick={()=>onClick(value)} ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>
    <Icon icon= {value.schema.content.icone} className={"w-6 h-6"}/>
    { (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} 
 </div>
 { tpls.length>0 && <FontAwesomeIcon className="p-2 h-6  bg-green-100 appbutton " icon={showTpl ? fa.faChevronCircleUp :fa.faChevronCircleDown} onClick={()=>setShowTpl(!showTpl)}   />}
</div>
{   showTpl &&  tpls.map(tpl=> 
  <div key={tpl.template_id} className="text-left p-2 rounded-lg my-2 text-white bg-black border-gray-500 border-t border-opacity-25 appbutton " onClick={()=>onClick({...value,structure:{ ...value.structure, template_id:tpl.template_id , ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})}     ,template:tpl})} stylea={{color:textColor,backgroundColor:backgroundColor}}> 
  <TemplateDisplay app_id={template.app_id} className=" w-'object-contain w-full h-full  max-h-[300px]  " template={tpl}  onClick={()=>{}}  /> </div>
)   }

    </>
case "schema_value":
  schema =value.schema
  tpls=  templates?.filter(tpl=> tpl.schema_id+"" ===""+ schema.content_id &&  template.template_id !==tpl.template_id && ( !tpl.is_list|| !tpl.is_list===!structure.isList    )&& tpl.layout==="in")
  return <>  <div className="w-full flex gap-1 items-center">
    
  <div className={"grow text-left p-2  text-gray-500 flex gap-2 items-center appbutton "+ (  value.schema.app_id+""===""+template.app_id? "bg-green-100":"bg-blue-100" )}  onClick={()=>onClick({...value,structure:{ ...value.structure,  ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})} })}  ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>
    <Icon icon= {value.schema.content.icone} className={"w-6 h-6"} />
  { (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} </div>
  { tpls.length>0 && <FontAwesomeIcon className={"p-2 h-6 appbutton " + (  schema.app_id+""===""+template.app_id? "bg-green-100":"bg-blue-100" )} icon={showTpl ? fa.faChevronCircleUp :fa.faChevronCircleDown} onClick={()=>setShowTpl(!showTpl)}   />}</div>
  
  {   showTpl &&  tpls.map(tpl=> 
  <div key={tpl.template_id} className="text-left p-2 rounded-lg my-2 text-white bg-black border-gray-500 border-t border-opacity-25 appbutton " onClick={()=>onClick({...value,structure:{ ...value.structure, template_id:tpl.template_id, ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})}     ,template:tpl})} stylea={{color:textColor,backgroundColor:backgroundColor}}> 
  <TemplateDisplay app_id={template.app_id} className=" w-'object-contain w-full h-full  max-h-[300px]  " template={tpl}  onClick={()=>{}}  /> </div>
)   }

  
  </>
case  "prop":
   schema = schemas.find(s=>s.content_id+""===""+value.prop.prop_schema_id)
   if(!schema) return  <div className="grow text-left p-2  bg-orange-100 text-gray-500  appbuton " > ERREUR {JSON.stringify(value) } </div>
   tpls=  templates?.filter(tpl=> tpl.schema_id+"" ===""+ schema.content_id &&  template.template_id !==tpl.template_id && ( !tpl.is_list|| !tpl.is_list===!structure.isList    )&& tpl.layout==="in")

  return <>  <div className="w-full flex gap-1 items-center">
    <div className="grow text-left p-2  bg-red-100 text-gray-500  appbuton "  onClick={()=>onClick({...value,structure:{...value.structure, ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})  }  }  )}  ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}> 
    <Icon icon= {schema?.content.icone} className={"w-6 h-6"} /> <span className="font-bold text-xs">({schema?.title}{!!value.prop.is_list ? "s" :""})</span> { (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )}
     </div>
     { tpls.length>0 && <FontAwesomeIcon className={"p-2 h-6 appbutton " + (  schema.app_id+""===""+template.app_id? "bg-red-100":"bg-red-100" )} icon={showTpl ? fa.faChevronCircleUp :fa.faChevronCircleDown} onClick={()=>setShowTpl(!showTpl)}   />}</div>
  
  {   showTpl &&  tpls.map(tpl=> 
  <div key={tpl.template_id} className="text-left p-2 rounded-lg my-2 text-white bg-black  appbutton " onClick={()=>onClick({...value,structure:{ ...value.structure, template_id:tpl.template_id, ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})}     ,template:tpl})} stylea={{color:textColor,backgroundColor:backgroundColor}}> 
  <TemplateDisplay app_id={template.app_id} className=" w-'object-contain w-full h-full  max-h-[300px]  " template={tpl}  onClick={()=>{}}  /> </div>
)   }

  
  </>
case  "template_prop":
   schema = schemas.find(s=>s.content_id+""===""+value.prop.schema_id)
   if(!schema) return  <><div className="grow text-left p-2  bg-orange-100 text-gray-500  appbuton " >template_prop ERREUR {JSON.stringify(value) } </div>
   <div className="grow text-left p-2  bg-orange-200 text-gray-500  appbuton " >Structure ERREUR {JSON.stringify(structure) } </div></>
   tpls=  templates?.filter(tpl=> tpl.schema_id+"" ===""+ schema.content_id &&  template.template_id !==tpl.template_id && ( !tpl.is_list|| !tpl.is_list===!structure.isList    )&& tpl.layout==="in")

  return <>  <div className="w-full flex gap-1 items-center"> 
  <div className="grow text-left p-2  bg-orange-100 text-gray-500  appbuton "  onClick={()=>onClick({...value,structure:{...value.structure, ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})  }  }  )} ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}> 
    <Icon icon= {schema?.content.icone} className={"w-6 h-6"} /> <span className="font-bold text-xs">({schema?.title}{!!value.prop.type_nombre ? "s" :""})</span> { (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} </div>
    { tpls.length>0 && <FontAwesomeIcon className={"p-2 h-6 appbutton " + (  schema.app_id+""===""+template.app_id? "bg-green-100":"bg-blue-100" )} icon={showTpl ? fa.faChevronCircleUp :fa.faChevronCircleDown} onClick={()=>setShowTpl(!showTpl)}   />}</div>
  
  {   showTpl &&  tpls.map(tpl=> 
  <div key={tpl.template_id} className="text-left p-2 rounded-lg my-2 text-white bg-black border-gray-500 border-t border-opacity-25 appbutton " onClick={()=>onClick({...value,structure:{ ...value.structure, template_id:tpl.template_id, ...(schema?.content.HTML? schema?.content.HTML :{tagName:"div",noInnerHTML:null})}     ,template:tpl})} stylea={{color:textColor,backgroundColor:backgroundColor}}> 
  <TemplateDisplay app_id={template.app_id} className=" w-'object-contain w-full h-full  max-h-[300px]  " template={tpl}  onClick={()=>{}}  /> </div>
)   }

  
  </>
case "template":
  return <div className="text-left p-2 rounded-lg my-2 text-white bg-black  appbuton  " onClick={()=>onClick({...value})}  ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}> 
  <TemplateDisplay  app_id={template.app_id} className=" appbutton object-contain w-full h-full  max-h-[300px]  " template={value.template}   onClick={()=>onClick({...value})}   /> </div>

    default : 
    return <div className="text-left p-2 text-gray-500 border-gray-500 border-t border-opacity-25 appbuton "  onClick={()=>onClick({...value})}  ref={elementRef}  stylea={{color:textColor,backgroundColor:backgroundColor}}>  {value.prop?.title} { (typeof value ==="string") ? value : (typeof value[fieldSearch] ==="object" ?  JSON.stringify(value[fieldSearch]) :value[fieldSearch]  )} </div>

  }
 }

const breadcrumbs = useMemo(()=>{

var eselected = []
if(selected)
{
var parts =selected.split(".")

while(parts.length>0)
{
  parts.pop()
  if(parts.length>0)
  {
    eselected.unshift(parts.join("."))
  }
}

}
return eselected
},[selected])


    if(!selected ) return <></>  
 
    return  <div
    className="fixed  flex flex-col items-center justify-center bottom-2 left-1/2 transform -translate-x-1/2   "
    style={{ zIndex: 60 }}
  >
    <div  className=" absolute bottom-0 mx-2  py-4 right-full flex gap-2"  >
{ breadcrumbs.map((parentid,i)=>

<div key={i} onClick={()=>selectTpl(parentid)} className={" cursor-pointer rounded-full h-8 w-8 bg-zinc-900 border border-red-500 text-center  text-xs  flex items-center justify-center text-white"}> {i+1} </div>
)    }
    </div>
 
    { 
      <div className="flex gap-1 w-full justify-end">{
mq.mediaQueries.map( a=><div  onClick={ ()=>mq.changeMediaQuery ( a.prefixe )  } className={"  h-6 rounded-lg flex  items-center justify-center border text-xs text-white px-1 "+ (mq.mediaQuery===a.prefixe ? " bg-orange-600 ":"bg-black bg-opacity-40")}  >{a.name ??  a.prefixe}</div>)
       }</div>           

    }{
      ContextToolShow && <ContextToolShow mq={mq} setShow={setShow}   scrollIntoView={scrollIntoView} setContextToolShow={ setShowContext} template={template} updateTemplateStructure={updateTemplateStructure}
      structure={structure}
    setStructure = {onChangeStructure}
      selected ={selected}
      templates={templates} schemas={schemas} globalSchema={globalSchema} content={content} selectTpl={selectTpl} 
      context={context}   app={app}updateApp={updateApp}   {...propsContext}   templateContent ={templateContent} updateContent={updateContent}
      
      key_schemas={key_schemas} suidTemplates={suidTemplates} propsTemplates={propsTemplates}
      />
    }
   
  <div
    className="bg-zinc-900 text-white  p-2 flex flex-row items-center justify-center  rounded-2xl"
    style={{ zIndex: 60  }}
  >
    
     {isEditable && selected && (
          <>
         <SelectDropDown    
         label={"Display"}
         value={valueText}
         fieldSearch="title"

         onChange={onChangeStructure} 
          options={options}
          widthTab={"w-96"}
          mainClassName={"w-60"}
          OptionRender={DefaultOptionRender}
          internalClick={true}
         />
         {
          structure.template_id &&  ( structure.schema_id || structure.schema_prop) &&
         <SelectDropDown    
         label={"Template"}
         value={valueTemplate}
         fieldSearch="title"

         onChange={onChangeStructure} 
          options={optionsTemplate}
          widthTab={"w-96"}
          mainClassName={"w-60"}
          OptionRender={TemplateOptionRender}
          internalClick={true}
         />

         }{
          structure.template_id &&                    <a
          href={`/template/${structure.template_id}`}
          target="_blank"
          rel="noreferrer"
        >
          {" "}
         { structure.template_id && <FontAwesomeIcon
            icon={fa.faEdit}
        
            className="p-2 text-xl"
          />}
         </a>
         }
       
       <ButtonTool
                  icon={fa.faCode}
                  onMouseOver={() => scrollIntoView("ShowComponentTools")}
                  onClick={() => {
                    setShow((show) => ({ ...show, Code: !show.Code }));
                  }}
                  active={show.Code}
                  nolabelActive={true}
                  label={"Style CSS / Classes "}
                />  

         { ["bg","text","border"].map(pref=> {
var classe= getClassColorDefined(pref,structure.className,app);

   if(!classe) return
   var parts = classe.split("-")

var prefix =  parts.shift()
var bgcolor = "bg-" + parts.join("-")
return {prefix,bgcolor}
    }).filter(c=>c ).map(classColor=><div  
      onClick={() => {
        var key = "Color-"  +classColor.prefix
        setShowContext( show=> show ===key? null:  key);
      }}
        key={classColor.prefix}  className={ "mx-1 p-1 text-xs flex px-2 flex-col items-center" 
+ ( "Color-"  +classColor.prefix ===showContext ?" bg-white rounded-xl text-black ":"")

        } >
<div className={" rounded-full w-6 h-6 border-gray-500 border "   + classColor.bgcolor }></div>
{classColor.prefix }
    </div>    )   }

           {  
            
            Object.keys( ContextTools).filter(key=> (showStyle || ContextTools[key].default || ( ContextTools[key].nb  &&   ContextTools[key].nb(structure)>0  ) ) && ContextTools[key].icon    && (!ContextTools[key].condition || ContextTools[key].condition(structure)) ).map(key =>  
            <div className="flex relative" key={key}><ButtonTool
            
              onMouseOver={() => scrollIntoView(key)}
              icon={ ContextTools[key].icon}
              onClick={() => {
                setShowContext( show=> show ===key? null:  key);
              }}
              active={showContext===key}
              label={  ContextTools[key].titre   }
              side="top"
            />
            { ContextTools[key].nb &&  ContextTools[key].nb(structure)>0  &&<div className="z-20 absolute  right-0 top-0 flex justify-center items-center rounded-full text-xs w-4 h-4 bg-red-500 text-white">
              {  ContextTools[key].nb(structure)}</div>}
            </div>)
            }
              { selected && structure.children?.length===1 && (
              <ButtonTool
                icon={fa.faMinusCircle}
                onClick={() => {
        

                  var _structure =  structure.children[0]
                  if(selected==="-root") delete _structure.suid
                 

                  updateTemplateStructure(_structure, selected, selected);
                }}
                defaultClass=" text-red-300  bg-black border-red-600 "
                inactiveClass=" text-red-300  bg-black border-red-600"
                label={"Désencapsuler l'enfant "}
                    side="top"
              />
            )}
            { selected !== "-root" && (
              <ButtonTool
                icon={fa.faTrash}
                onClick={() => {
                  var parts = selected.split(".");
                  var index = parseInt(parts.pop());
                  var idParent = parts.join(".");

                  var _structure = getStructure(
                    template.structure,
                    idParent
                  );

                  var newid = idParent;
                  _structure.children = _structure.children.filter(
                    (_, i) => i !== index
                  );

                  updateTemplateStructure(_structure, idParent, newid);
                }}
                defaultClass=" text-red-600  bg-black border-red-600 "
                inactiveClass=" text-red-600  bg-black border-red-600"
                label={"Supprimer l'élément sélectionné"}
                    side="top"
              />
            )}

            </>)}
  </div>
  </div>
    })
    
  

    export default Context_ToolManager