import { useContext, useEffect, useState } from "react";
import Image from "../Image";
import { MfctContext } from "../../context";






function    AppThumb ({ className, app_id,onClick,size=" w-8 h-8 "})  {
  const { project} = useContext(MfctContext);
const [app,setApp] = useState({name :"app_"+ app_id,img:null}); 

const getApp =async ()=>{
  var app = null;
var stock = localStorage.getItem("appthumb_" + app_id);
if( stock ) app = JSON.parse(stock)
//await data.getUser(user_id);
if(!app) 
app = await project.api("/get_app_thumb/:app_id",{args:{app_id}})
if(app){
  localStorage.setItem("appthumb_" + app_id,JSON.stringify(app));
  setApp(app)
}
}


useEffect(()=>{


getApp()

},[])
  return   <div className={" appbutton flex " + className} onClick={onClick}> <Image src={ app.img?.startsWith("https:")? app.img: process.env.REACT_APP_MFTC_SERVER_URL_FILES +   app.img} className={size + " rounded-sm shadow "}   /> </div>

} 

export default AppThumb;
