import React, { useMemo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as fa from "@fortawesome/free-solid-svg-icons";
import { data } from "../../../../../service/data";
import TemplateStructure from "../../../../../components/templates/TemplateStructure";
import { getStructure } from "../../../../../tools/tpl";
import InputData from "../../../../../components/inputs/InputData";
import VarContent from "../../../../../components/VarContent";

const ContextToolInput = ( {setShow ,structure,setStructure ,isEditable,scrollIntoView,show,template,updateTemplateStructure,selected,templates,schemas,globalSchema,content,selectTpl,ComponentStructTools,context,app,templateContent,updateContent,suidTemplates,propsTemplates}) => {
  

  const mySchema = useMemo(() => {
    return structure.schema_id
      ? schemas.find((c) => c.content_id === structure.schema_id )
      : null;
  }, [schemas, structure.schema_id]);


  if (!selected || (( !mySchema  || !structure.schema_id) &&   !structure.template_id   )|| !templateContent || !updateContent) return <></>;





  return (
    <div
      id={"ShowTemplateStructure"}
      className="bg-zinc-900 p-2 text-white rounded-xl  m-2 flex flex-col items-center justify-center  border-white shadow w-full"
    >
{!structure.schema_id &&   structure.template_id    && suidTemplates && 
[ ...Object.keys(suidTemplates).map(s=>({suid:s,...suidTemplates[s]  })) ].map(grp=>(<div className={"flex flex-col w-full py-2 gap-1"}>
  <div key={grp.suid} className="w-full p-2 font-bold bg-slate-300">{grp.title} {grp.suid ? "/":"" } {grp.suid}</div>
  {
  propsTemplates?.filter(p=>( p.name.startsWith(structure.suid+"-")  ||   structure.suid===p.name) ).map(p=> 
    <div key={p.name} className="w-full p-2 text-black">{ p.schema &&
<InputData   domain={app?.domain} className={"w-full"} value={ templateContent.content[p.name]    } onChange={(value)=>updateContent( { ...templateContent,content:{...templateContent.content,  [p.name]:value}} )} prop={{...p,name:p.schema.title + " / " + p.name.split("-").pop() }} schema={p.schema} context={{...context, app_id:template?.app_id}}  />}
{ !p.schema &&  <>{ p.name} en charge</>}
    </div>
   )
  }
</div>))



}
  { structure.schema_id  && structure.default_value &&
  <InputData  domain={app?.domain} className={"w-full"} prop={  { name:"", type_nombre:structure.isList? "I":null, schema_id: structure.schema_id }}  value={structure.default_value }  schema={mySchema} onChange={(default_value) => {
    updateTemplateStructure({ ...structure, default_value },selected);
  }} 
  context={{...context, app_id:template?.app_id}}  
  />}
{structure.schema_id  &&
  <InputData   domain={app?.domain} className={"w-full"} prop={  { name:"" , type_nombre:structure.isList? "I":null, schema_id: structure.schema_id }}  value={templateContent.content[structure.suid] }  schema={mySchema} onChange={(default_value) => {
updateContent({...templateContent,content:{...templateContent.content,[structure.suid]:default_value}});

  }} 
  context={{...context, app_id:template?.app_id}}  
   />
}
    </div>
  );
};

export default ContextToolInput;
